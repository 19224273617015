import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import { FormControl, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { marketNamesWithoutPINX as marketNames, sectorNamesExcludeFinancialsAndRealEstate as sectorNames, marketCapValues } from './Constants/constants'
import { StrategyContext } from './Context/StrategyContext';


const QuickStrategyUniverse = () => {
    const { id, market, handleMarketChange, sectors, handleSectorsChange, marketCapMin, handleMarketCapMinChange, marketCapMax, handleMarketCapMaxChange, isPublic } = useContext(StrategyContext);
    console.log('QuickStrategyUniverse id: ', id)
    const isAllSelected = sectors.length === sectorNames.length;

    const handleSectorsChangeEvent = (event) => {
        const value = event.target.value;
        let newSectors = value

        if (value[value.length - 1] === "ALL") {
            if (sectors.length === sectorNames.length) {
                newSectors = []
            }
            else {
                newSectors = sectorNames.map(s => s.value);
            }
        }

        newSectors = newSectors.filter(s => s !== "")

        handleSectorsChange(newSectors)
    };

    const getMCapMinValues = () => {
        const mCapMinValues = marketCapValues.filter(mCap => mCap.mCapMin === true && mCap.value < marketCapMax)
        return mCapMinValues
    }

    const getMCapMaxValues = () => {
        const mCapMaxValues = marketCapValues.filter(mCap => mCap.mCapMax === true && mCap.value > marketCapMin)
        return mCapMaxValues
    }

    return (
        <Box>
            <Box>
                <Typography variant='h6' sx={{ mt: 1, mb: 2 }}>Universe</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box sx={{ ml: 0 }}>
                    <FormControl sx={{ m: 0, width: 110 }} size='small' >
                        <InputLabel>Market</InputLabel>
                        <Select
                            value={market}
                            onChange={(event) => handleMarketChange(event.target.value)}
                            input={<OutlinedInput label="Market" />}
                            disabled={isPublic}
                        >
                            {marketNames.map((market) => (
                                <MenuItem key={market.value} value={market.value}>
                                    {market.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ ml: 2 }}>
                    <FormControl sx={{ m: 0, minWidth: 310 }} size='small'>
                        <InputLabel>Sectors</InputLabel>
                        <Select
                            multiple
                            value={sectors}
                            onChange={(event) => handleSectorsChangeEvent(event)}
                            input={<OutlinedInput label="Sectors" />}
                            renderValue={(selected) => { return selected.join(', ') }}
                            disabled={isPublic}
                        >
                            <MenuItem key='all' value={'ALL'} >
                                <Checkbox
                                    checked={isAllSelected}
                                    indeterminate={
                                        sectors.length > 0 && sectors.length < sectorNames.length
                                    }
                                />
                                <ListItemText primary='Select ALL' />
                            </MenuItem>

                            {sectorNames.map((sector) => (
                                <MenuItem key={sector.value} value={sector.value}>
                                    <Checkbox checked={sectors.indexOf(sector.value) > -1} />
                                    <ListItemText primary={sector.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Box>

                <Box sx={{ ml: 2 }}>
                    <FormControl sx={{ m: 0, width: 140 }} size='small'>
                        <InputLabel>Market Cap Min</InputLabel>
                        <Select
                            value={marketCapMin}
                            onChange={(event) => handleMarketCapMinChange(event.target.value)}
                            input={<OutlinedInput label="MarketCap Min" />}
                            disabled={isPublic}
                        >
                            {getMCapMinValues().map((marketCap) => (
                                <MenuItem key={marketCap.label} value={marketCap.value}>
                                    {marketCap.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ ml: 2 }}>
                    <FormControl sx={{ m: 0, width: 140 }} size='small'>
                        <InputLabel>Market Cap Max</InputLabel>
                        <Select
                            value={marketCapMax}
                            onChange={(event) => handleMarketCapMaxChange(event.target.value)}
                            input={<OutlinedInput label="MarketCap Max" />}
                            disabled={isPublic}
                        >
                            {getMCapMaxValues().map((marketCap) => (
                                <MenuItem key={marketCap.label} value={marketCap.value}>
                                    {marketCap.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

            </Box>
        </Box>
    )
}

export default QuickStrategyUniverse
