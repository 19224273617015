import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { StrategyContext } from "./Context/StrategyContext";
import { Paper, Box, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button, Checkbox, ListItemText, Tooltip, IconButton, Typography, TextField, Stack, Menu, ListItemIcon } from "@mui/material";
import { Menu as MenuIcon, Delete as DeleteIcon, ModeEdit as ModeEditIcon, NoteAdd as NoteAddIcon, SaveAs as SaveAsIcon, Save as SaveIcon, FileCopy as FileCopyIcon, TextFields as TextFieldsIcon, FilterList as FilterListIcon, FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { marketNamesWithPINX as marketNames, regionNames, sectorNames, marketCapValues } from "./Constants/constants";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const WEBAPI = process.env.REACT_APP_WEB_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const QuickStrategyResultsToolbar = ({ periodList, period, handlePeriodChange, topNCompanies, handleExport }) => {
  const { topN } = useContext(StrategyContext);
  const [action, setAction] = React.useState("");
  const [newPortfolioName, setNewPortfolioName] = React.useState("");
  const [portfolioID, setPortfolioID] = React.useState("");
  const [portfolioList, setPortfolioList] = React.useState([]);
  const [toolbarIsVisible, setToolbarIsVisible] = React.useState(true);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [newPortfolioText, setNewPortfolioText] = React.useState("");
  const [addToExistingPortfolioText, setAddToExistingPortfolioText] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  useEffect(() => {
    setNewPortfolioText(`Save Top ${topN} Companies as New Portfolio`);
    setAddToExistingPortfolioText(`Add Top ${topN} Companies to Existing Portfolio`);
    getPortfolioList();
  }, []);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const getPortfolioList = () => {
    axios
      .get(`${WEBAPI}/quickportfolio`)
      .then((res) => {
        setPortfolioList(res.data);

        if (res.data.length > 0 && portfolioID === "") {
          setPortfolioID(res.data[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false)
      });
  };

  const onCancel = () => {
    console.log("onCancel");
    setToolbarIsVisible(true);
  };

  const onSavePortfolio = () => {
    console.log("onSavePortfolio");
    console.log("action", action);
    console.log("topNCompanies", topNCompanies);

    setToolbarIsVisible(true);

    if (action === "New") {
      handleSaveAsPortfolio(newPortfolioName);
    } else if (action === "Add to Existing") {
      handleSaveToExistingPortfolio(portfolioID);
    }
  };

  const handleSaveAsPortfolio = (portfolioName) => {
    // console.log("handleSaveAsPortfolio", portfolioName);

    axios
      .post(`${WEBAPI}/quicksector/saveasportfolio`, { portfolioName: portfolioName, products: topNCompanies })
      .then((res) => {
        console.log(res);
        if (res === undefined) {
          setSnackbarMessage("Error saving portfolio");
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage("Portfolio saved successfully");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSnackbarMessage("Portfolio already exists");
        } else {
          setSnackbarMessage("Error saving portfolio");
        }
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  const handleSaveToExistingPortfolio = (portfolioID) => {
    axios
      .post(`${WEBAPI}/quicksector/addtoportfolio`, { portfolioID: portfolioID, products: topNCompanies })
      .then((res) => {
        setSnackbarMessage("Portfolio saved successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setSnackbarMessage("Error saving portfolio");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  const handlePortfolioChange = (event) => {
    console.log("handlePortfolioChange");
    setPortfolioID(event.target.value);
  };

  const handleOpenOptionsMenu = (event) => {
    setAnchorElOptions(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorElOptions(null);
  };

  const handleSaveAsNew = () => {
    setAnchorElOptions(null);

    setAction("New");
    setNewPortfolioName("");
    setToolbarIsVisible(false);
  };

  const handleSaveToExisting = () => {
    setAnchorElOptions(null);

    setAction("Add to Existing");
    setToolbarIsVisible(false);
  };

  const handleExportToExcel = () => {
    setAnchorElOptions(null);
    handleExport();
  }

  return (
    <>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {toolbarIsVisible && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ ml: 0 }}>
            <FormControl sx={{ m: 0, width: 220 }} size="small">
              <InputLabel>Period</InputLabel>
              <Select value={period} onChange={handlePeriodChange} input={<OutlinedInput label="Period" />}>
                {periodList.map((p) => (
                  <MenuItem key={p.value} value={p.value} sx={{ fontFamily: "Courier", fontWeight: "500" }}>
                    {p.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <IconButton onClick={handleOpenOptionsMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="options-menu"
              anchorEl={anchorElOptions}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElOptions)}
              onClose={handleCloseOptionsMenu}
            >
              <MenuItem key="saveNew" onClick={() => handleSaveAsNew()}>
                <ListItemIcon>
                  <SaveIcon />
                </ListItemIcon>
                <Typography textAlign="center">{newPortfolioText}</Typography>
              </MenuItem>
              <MenuItem key="saveTo" onClick={() => handleSaveToExisting()}>
                <ListItemIcon>
                  <SaveAsIcon />
                </ListItemIcon>
                <Typography textAlign="center">{addToExistingPortfolioText}</Typography>
              </MenuItem>
              <MenuItem key="export" onClick={() => handleExportToExcel()}>
                <ListItemIcon>
                  <FileDownloadIcon />
                </ListItemIcon>
                <Typography textAlign="center">Export to Excel</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Stack>
      )}

      {toolbarIsVisible === false && (
        <Box display={"flex"}>
          <Box display={"flex"} sx={{ ml: 2, alignItems: "center" }}>
            <Typography sx={{ mr: 2 }} variant="subtitle1" component="h2">
              {action}
            </Typography>

            {action === "New" ? (
              <Box>
                <FormControl sx={{ minWidth: 300, ml: 1 }}>
                  <TextField autoFocus margin="dense" id="name" label="Portfolio" variant="outlined" size="small" sx={{ width: 300 }} value={newPortfolioName} onChange={(e) => setNewPortfolioName(e.target.value)} disabled={action === "Delete" ? true : false} />
                </FormControl>
              </Box>
            ) : (
              <Box>
                <FormControl sx={{ minWidth: 300, ml: 1 }} size="small">
                  <InputLabel>Portfolio</InputLabel>
                  <Select label="Portfolio" value={portfolioID} sx={{ width: 300 }} onChange={handlePortfolioChange}>
                    {portfolioList.map((portfolio) => (
                      <MenuItem key={portfolio.id} value={portfolio.id} selected={portfolio.id == portfolioID}>
                        {portfolio.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Button sx={{ ml: 1 }} color="error" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="success" disabled={action === "New" ? newPortfolioName.length === 0 : null} onClick={onSavePortfolio}>
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default QuickStrategyResultsToolbar;
