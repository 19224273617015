import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { visuallyHidden } from "@mui/utils";

import { useAtom } from "jotai";
import { emailAtom } from "./Atoms";
import TextField from "@mui/material/TextField";
import axios from "axios";

const WEBAPI = process.env.REACT_APP_WEB_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const indicatorSize = 80;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [{ id: "name", numeric: false, disablePadding: false, label: "File" }];

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Companies' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={"none"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, id, handleRefresh, handleDelete } = props;
  const [file, setFile] = React.useState('');

  const navigate = useNavigate();

  const handleFileUpload = () => {
    if (!file) {
      console.log("No file selected");
      return;
    }

    console.log("handleFileUpload:", file);

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${WEBAPI}/upload/`, formData)
      .then((res) => {
        console.log(res.data);
        // setFile('');
        handleRefresh();
      })
      .catch((err) => {
        console.log("FATAL: ", err);
      });
  };



  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          {numSelected} selected
        </Typography>
      ) : (
        <Box sx={{ mt: 2 }}>
          <TextField type="file" name="file" onChange={(e) => setFile(e.target.files[0])} />
          <Button variant="contained" color="primary" component="span" sx={{ ml: 1, textTransform: "none", mt: 1 }} onClick={handleFileUpload}>
            Upload
          </Button>
        </Box>
      )}

      {numSelected > 0 ? (
        <>
          <IconButton  sx={{ mt: 2 }} title="Delete" color="error" onClick={() => handleDelete(id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Uploads = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(() => {
    const rows = localStorage.getItem("UPLOADS_rowsPerPage");
    if (rows) {
      return parseInt(rows);
    } else {
      return 5;
    }
  });
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const [email] = useAtom(emailAtom);
  const [file, setFile] = React.useState(null);

  const getUploadedFiles = () => {
    axios
      .get(`${WEBAPI}/upload/`)
      .then((res) => {
        console.log(res.data);

        const newRows = res.data.map((item, idx) => {
          return { id: idx, filename: item };
        });

        console.log(newRows);
        setRows(newRows);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUploadedFiles();
  }, []);

  const handleDelete = (id) => {
    const filename = rows[id].filename;
    console.log("Delete :", filename);
    
    setSelected([]);

    axios
      .delete(`${WEBAPI}/upload/${filename}`)
      .then((res) => {
        console.log(res.data);
        getUploadedFiles();
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const handleRefresh = () => {
    getUploadedFiles();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected.push(id);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("UPLOADS_RowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Paper sx={{ m: 2 }}>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>

          <EnhancedTableToolbar numSelected={selected.length} id={selected[0]} handleDelete={handleDelete} handleRefresh={handleRefresh} />
          <TableContainer sx={{ mt: 2, maxHeight: "calc(100vh - 250px)" }}>
            <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {(rowsPerPage > 0 ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(rows, getComparator(order, orderBy))).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover onClick={(event) => handleClick(event, row.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {row.filename}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination rowsPerPageOptions={[5, 10, 15, 20, 25, { label: "All", value: -1 }]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} showFirstButton={true} showLastButton={true} labelRowsPerPage="Files per page:" />
        </Paper>
      )}
    </div>
  );
};

export default Uploads;
