import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Appbar from './components/Appbar';
import Dashboard from './components/Dashboard';
import QuickPortfolio from './components/QuickPortfolio';
import QuickStrategy from './components/QuickStrategy';
import QuickSector from './components/QuickSector';
import QuickVALUATION from './components/QuickValuation';
import Uploads from './components/Uploads';
import ManagedPortfolios from './components/ManagedPortfolios';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import Companies from './components/Companies';
import Company from './components/Company';
import Users from './components/Users';
import User from './components/User';
import axios from 'axios';
import Login from "./components/Auth/Login";
import LoginPasswordNew from './components/Auth/LoginPasswordNew';
import LoginPassword from './components/Auth/LoginPassword';
import LoginPasswordCode from './components/Auth/LoginPasswordCode';
import LoginPasswordChange from './components/Auth/LoginPasswordChange';
import QuickStrategies from './components/QuickStrategies';
import TableOptionsMenu from './components/TableOptionsMenu';
import QuickPortfolios from './components/QuickPortfolios';
import Help from './components/Help';
import TanTable from './components/TanTable';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";


// Request Interceptor for API calls
axios.interceptors.request.use((config) => {
  // config.headers["Content-Type"] = "application/json;charset=utf-8";

  const accessToken = localStorage.getItem("token");
  if (accessToken) config.headers.Authorization = "Bearer " + accessToken;

  return config;
});

// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('token')
      window.location.href = "/login";
    }
  }
);


const router = createBrowserRouter([
  {
    element: (
      <>
        <Appbar />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/quickportfolios",
        element: <QuickPortfolios />,
      },
      {
        path: "/quickportfolio/:id",
        element: <QuickPortfolio />,
      },
      {
        path: "/quicksector",
        element: <QuickSector />,
      },
      {
        path: "/quickstrategies",
        element: <QuickStrategies />,
      },
      {
        path: "/quickstrategy/:id",
        element: <QuickStrategy />,
      },
      {
        path: "/uploads",
        element: <Uploads />,
      },
      {
        path: "/quickvaluation",
        element: <QuickVALUATION />,
      },
      {
        path: "/managedportfolios",
        element: <ManagedPortfolios />,
      },
      {
        path: "/companies",
        element: <Companies />,
      },
      {
        path: "/company/:id?",
        element: <Company />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/user/:id?",
        element: <User />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/loginpasswordnew",
        element: <LoginPasswordNew />,
      },
      {
        path: "/loginpassword",
        element: <LoginPassword />,
      },
      {
        path: "/loginpasswordcode",
        element: <LoginPasswordCode />,
      },
      {
        path: "/passwordchange",
        element: <LoginPasswordChange />,
      },
      {
        path: "/tableoptionsmenu",
        element: <TableOptionsMenu />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/tantable",
        element: <TanTable />,
      },
    ],
  },]);

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#333F4F',
    },
    secondary: {
      main: '#FFC000',
    },

    pink: {
      deep: '#FF1493',
      hot: '#FF69B4',
      medium: '#C71585',
      pale: '#DB7093',
      light: '#FFB6C1',
    },

  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
