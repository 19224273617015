import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import FormHelperText from "@mui/material/FormHelperText";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useAtom } from 'jotai';
import { emailAtom } from '../Atoms';



const WEBAPI = process.env.REACT_APP_WEB_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
});

export default function Login() {
  const [email, setEmail] = useAtom(emailAtom);

  const [registered, setRegistered] = useState(true)
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log(values)

      const userEmail = values.email.toLowerCase()
      setEmail(userEmail)

      axios.post(`${WEBAPI}/auth/login`, { email: userEmail })
        .then((res) => {
          console.log(res.data)

          switch (res.data.status) {
            case "UNREGISTERED":
              // console.log('unregistered')
              setRegistered(false)
              break
            case "REGISTERED":
              // console.log('go to Password page')
              navigate('/loginpassword')
              break
            case "REGISTERED_NEW":
              // console.log('go to NewPassword page')
              navigate('/loginpasswordnew')
              break
            default:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        })

      setSubmitting(false)
    },
  });

  const handleEmailChange = (e) => {
    setRegistered(true)
    formik.setFieldValue("email", e.target.value, false);
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper >
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
        </Snackbar>

        <form noValidate onSubmit={formik.handleSubmit} autoComplete='false' >
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} padding={4}>

            <Grid item xs={4} md={4} align="center">
              <Avatar sx={{ backgroundColor: (theme) => theme.palette.secondary.main }} >
                <LockOutlinedIcon />
              </Avatar>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography component="h1" align="left" variant="h5" noWrap>
                Login
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.email}
                onChange={handleEmailChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <FormHelperText style={{ display: (!registered) ? 'block' : 'none', color: 'red' }} >Contact Matt Kacur to register. mkacur@fsavaluation.com - 416-453-5875</FormHelperText>
            </Grid>

            <Grid item xs={12}>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting || formik.values.email.length === 0}
              // className={classes.submit}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper >
    </Container >

  );
}