import React, { useEffect, useContext, useState } from "react";
import { StrategyContext } from "../Context/StrategyContext";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        filter: function (item, chart) {
          // Logic to remove a particular legend item goes here
          return !item.text.includes("Deciles");
        },
      },
    },
    title: {
      display: true,
      text: "Standard Deviation",
    },
    datalabels: {
      formatter: function(value, context) {
        return value === undefined ? '' : value + "%";
      }
    }
  },
  scales: {
    y: {
      min: 0,
      max: 30,
    },
  },
};

const labels = ["TopN", "Decile 1", "Decile 2", "Decile 3", "Decile 4", "Decile 5", "Decile 6", "Decile 7", "Decile 8", "Decile 9", "Decile 10"];
const borderColors = ["rgba(0, 176, 240, 0.8)", "rgba(55, 117, 41, 0.8)", "rgba(0, 153, 0, 0.8)", "rgba(129, 182, 52, 0.8)", "rgba(172, 185, 49, 0.8)", "rgba(230, 225, 21, 0.8)", "rgba(255, 217, 102, 0.8)", "rgba(246, 176, 112, 0.8)", "rgba(245, 100, 85, 0.8)", "rgba(255, 0, 0, 0.8)", "rgba(190, 0, 0, 0.8)"];
const medianBorderColors = ["rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.8)"];

export const chartData = {
  labels,
  datasets: [
    {
      label: "Deciles",
      data: [],
      backgroundColor: borderColors,
      datalabels: {
        color: "#000000",
        display: true,
        anchor: 'end',
        align: 'top',
        offset: 0,
      },
    },
    {
      label: "Universe",
      data: [],
      backgroundColor: medianBorderColors,
      type: "line",
      datalabels: {
        display: false,
      },
    },
  ],
};

const BackTestStandardDeviationChart = () => {
  const { backTestResults } = useContext(StrategyContext);
  const [backTestReturnsChartData, setBackTestReturnsChartData] = useState(null);

  useEffect(() => {
    console.log("SD Chart: ", backTestResults);
    if (backTestResults.length === 0) return;

    const topN = backTestResults[11].Group;
    labels[0] = topN;

    chartData.datasets[0].data = [];
    chartData.datasets[0].data.push(backTestResults[11].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[0].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[1].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[2].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[3].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[4].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[5].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[6].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[7].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[8].StdDev.toFixed(1));
    chartData.datasets[0].data.push(backTestResults[9].StdDev.toFixed(1));

    const stdDev = backTestResults[10].StdDev;
    for (let i = 0; i < 12; i++) {
      chartData.datasets[1].data.push(stdDev.toFixed(1));
    }

    setBackTestReturnsChartData(chartData);
  }, [backTestResults]);

  return backTestResults.length === 0 || backTestReturnsChartData == null ? null : <Bar options={options} data={backTestReturnsChartData} />;
};

export default BackTestStandardDeviationChart;
