import React, { useContext, useEffect, useState } from "react";
import { StrategyContext } from "./Context/StrategyContext";
import axios from "axios";
import { format, isValid, subMonths, subDays, set } from "date-fns";

import { Typography, Button, Box, IconButton, FormControl, InputLabel, Select, MenuItem, OutlinedInput, TextField, Snackbar, Alert as MuiAlert, FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Stack, Tooltip } from "@mui/material";

import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { metrics } from "./Constants/constants";

import QuickStrategyResultsToolbar from "./QuickStrategyResultsToolbar";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const indicatorSize = 80;
const WEBAPI = process.env.REACT_APP_WEB_API;
const columnHelper = createColumnHelper();

const strategyStaticColumns = [
  columnHelper.accessor("Ranking", {
    header: "Rank",
    id: "ranking",
    cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{info.getValue()}</Typography>,
    size: 40,
  }),
  columnHelper.accessor("Ticker", {
    header: "Ticker",
    id: "ticker",
    cell: (info) => <Typography sx={{ textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
    size: 80,
  }),
  columnHelper.accessor("Company", {
    header: "Company",
    id: "company",
    cell: (info) => <Typography sx={{ textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
    size: 180,
  }),
  columnHelper.accessor("DomicileCountryFlag", {
    header: "Domiciled",
    id: "domicile_country_flag",
    cell: (props) => (
      <Typography sx={{ textAlign: "center" }}>
        <Tooltip title={props.row.original.DomicileCountry} placement="top">
          <img src={`https://flagcdn.com/w20/${props.cell.getValue()?.toLowerCase()}.png`} width="20" alt="flag" />
        </Tooltip>
      </Typography>
    ),
    size: 70,
  }),
  columnHelper.accessor("Sector", {
    header: "Sector",
    id: "sector",
    cell: (info) => <Typography sx={{ textAlign: "center", fontSize: "12px" }}>{info.getValue()}</Typography>,
    size: 50,
  }),
  columnHelper.accessor("MarketCap", {
    header: "Market Cap ($M)",
    id: "marketcap",
    cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{info.getValue().toLocaleString()}</Typography>,
    size: 50,
  }),
];

const QuickStrategyResults = () => {
  const { name, market, sectors, marketCapMin, marketCapMax, factors, topN } = useContext(StrategyContext);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState("");
  const [periodList, setPeriodList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = React.useState([]);
  const [topNCompanies, setTopNCompanies] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    // console.log("strategy name: ", name);

    setLoading(true);
    setPeriod(""); // We need this. If we don't have it the Period UseEffect won't fire. Load a strategy and leave on the first period. Change the Strategy and the table will not change.

    const periodsListTmp = [];
    const startDate = new Date();
    let periodValue = 0;

    for (var x = 0; x < 20; x++) {
      let nextDate = subMonths(startDate, x * 3);
      while (!isValid(nextDate)) {
        subDays(nextDate, 1);
      }

      let helperLabel;
      const year = Math.floor(x / 4);
      const month = (x % 4) * 3;

      if (year === 0 && month === 0) {
        helperLabel = "Today";
      } else if (year === 0) {
        helperLabel = `${month}m`;
      } else if (year === 0 && month === 0) {
        helperLabel = `${month}m`;
      } else if (month === 0) {
        helperLabel = `${year}y`;
      } else {
        helperLabel = `${year}y ${month}m`;
      }

      periodsListTmp.push({ value: String(periodValue).padStart(2, "0"), label: format(nextDate, "MMM dd, yyyy") + ` (${helperLabel})` });

      periodValue++;
    }
    // console.log(periodsListTmp)
    setPeriodList(periodsListTmp);

    setPeriod("00");
  }, []);

  const getColumns = () => {
    // console.log('getColumns', period)
    const newColumns = [...strategyStaticColumns];

    factors.forEach((factor) => {
      const header = metrics.find((metric) => metric.value === factor.metric).label;

      const newColumn = columnHelper.accessor(`${factor.metric}`, {
        header: `${header}`,
        id: `${factor.metric}`,
        cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{(Math.round(info.getValue() * 100) / 100).toLocaleString(undefined, 0)}</Typography>,
        size: 50,
        align: "right",
      });

      newColumns.push(newColumn);
    });

    // add the Percent Columns here. This ensures they're all at the end of the table
    factors.forEach((factor) => {
      let header = metrics.find((metric) => metric.value === factor.metric).label + " Rank %";

      const newColumn = columnHelper.accessor(`${factor.metric}_PctRank`, {
        header: `${header}`,
        id: `${factor.metric}_PctRank`,
        cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{(Math.round(info.getValue() * 10000) / 100).toFixed(2)}</Typography>,
        size: 50,
        align: "right",
      });

      newColumns.push(newColumn);
    });

    const newColumn = columnHelper.accessor(`OverallRank`, {
      header: `Overall Rank %`,
      id: `overall_rank`,
      cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{(Math.round(info.getValue() * 10000) / 100).toFixed(2)}</Typography>,
      size: 50,
      align: "right",
    });

    newColumns.push(newColumn);

    const totalReturn = columnHelper.accessor(`TotalReturn`, {
      header: `Total 3 Month Return %`,
      id: `total_return`,
      cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{info.getValue()}</Typography>,
      size: 50,
      align: "right",
    });

    newColumns.push(totalReturn);

    setColumns(newColumns);
  };

  useEffect(() => {
    if (period === "") return;
    console.log("useEffect period", period);

    setLoading(true);

    getColumns();

    const payload = {
      market: market,
      sectors: sectors.join(","),
      mcap_min: marketCapMin,
      mcap_max: marketCapMax,
      metrics: factors.map((factor) => factor.metric).join(","),
      ranks: factors.map((factor) => factor.rank).join(","),
      weights: factors.map((factor) => factor.weight).join(","),
      period: period,
      tableColumns: factors.map((factor) => factor.tableColumn).join(","),
      minimums: factors.map((factor) => factor.minimum).join(","),
      maximums: factors.map((factor) => factor.maximum).join(","),
    };

    console.log(payload);
    // console.log(factors)

    axios
      .post(`${WEBAPI}/quickstrategy/results`, payload)
      .then((res) => {
        console.log(res.data);
        setData(res.data);

        // create sub array of topN companies
        const topNCompaniesTmp = [];
        for (var x = 0; x < topN; x++) {
          topNCompaniesTmp.push(res.data[x].ProductID);
        }
        setTopNCompanies(topNCompaniesTmp);
      })
      .catch((error) => {
        console.log("handleUniverseChange error: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [period]);

  const handlePeriodChange = (event) => {
    setData([]);
    const newPeriod = event.target.value;
    setPeriod(newPeriod);
  };

  const handleExport = async () => {
    setLoading(true);

    const exportData = [];
    const promises = [];

    periodList.forEach(async (period) => {
      // console.log(`period: ${period.label}`);

      const payload = {
        market: market,
        sectors: sectors.join(","),
        mcap_min: marketCapMin,
        mcap_max: marketCapMax,
        metrics: factors.map((factor) => factor.metric).join(","),
        ranks: factors.map((factor) => factor.rank).join(","),
        weights: factors.map((factor) => factor.weight).join(","),
        period: period.value,
        tableColumns: factors.map((factor) => factor.tableColumn).join(","),
        minimums: factors.map((factor) => factor.minimum).join(","),
        maximums: factors.map((factor) => factor.maximum).join(","),
      };

      promises.push(
        axios
          .post(`${WEBAPI}/quickstrategy/results`, payload)
          .then((res) => {
            // console.log('res.data', res.data)

            res.data.forEach((x) => {

              let newRecord = {
                Period: new Date(period.label.substring(0, 12)),
                Ranking: x.Ranking,
                Ticker: x.Ticker,
                Company: x.Company,
                TotalReturn: x.TotalReturn,
                DomicileCountry: x.DomicileCountry,
                MarketCap: x.MarketCap,
                Sector: x.Sector,
              };

              delete x.ProductID
              delete x.CompanyID
              delete x.ExchangeID
              delete x.Trades
              delete x.Domiciled
              delete x.Market
              delete x.Region
              delete x.Currency
              delete x.TradeCountryCode
              delete x.DomicileCountryFlag
              delete x.PriceChg
              delete x.DividendYield
              delete x.Ranking

              newRecord = { ...newRecord, ...x };

              exportData.push(newRecord);
            });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    });

    await Promise.all(promises);

    const sortedData = exportData.sort(
      (a, b) =>
        b.Period - a.Period ||
        a.Ranking - b.Ranking,
    )
  
    // console.log("exportData", exportData);

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sortedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + fileExtension);

    setLoading(false);
  };

  return loading ? (
    <Stack alignItems="center" sx={{ height: "100vh" }}>
      <CircularProgress color="secondary" size={indicatorSize} sx={{ position: "absolute", top: "50%", left: "50%", marginTop: `${-indicatorSize / 2}px`, marginLeft: `${-indicatorSize / 2}px` }} />
    </Stack>
  ) : (
    <Box>
      <Box>
        <QuickStrategyResultsToolbar period={period} periodList={periodList} handlePeriodChange={handlePeriodChange} topNCompanies={topNCompanies} handleExport={handleExport} />
      </Box>

      <Box>
        <Typography sx={{ mt: 2, fontSize: "14px" }}>{table.getRowModel().rows.length} Companies in Period</Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
        {/* <TableContainer sx={{ mt: 2, maxHeight: "calc(100vh - 250px)", fontSize: '0.75rem' }}> */}
        <div className="strategy_results_container">
          <table id="strategy_results">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="pointer">
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <Typography sx={{ width: header.getSize(), ml: 1, fontSize: "12px" }}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{ asc: " 🔼", desc: " 🔽" }[header.column.getIsSorted()] ?? null}
                          </Typography>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* </TableContainer> */}
      </Box>
    </Box>
  );
};

export default QuickStrategyResults;
