import * as React from 'react';
import { Grid, Typography } from '@mui/material'
import DBQuoteOfTheDay from './DashboardComponents/DBQuoteOfTheDay'
import DBPortfolioHighLevel from './DashboardComponents/DBPortfolioHighLevel'
import DBPortfolioDetailed from './DashboardComponents/DBPortfolioDetailed'
import DBSectorPerformance from './DashboardComponents/DBSectorPerformance';
import DBGainersAndLosers from './DashboardComponents/DBGainersAndLosers';

function Dashboard() {



  return (
    <Grid container spacing={2} sx={{ p: 4 }}>
      <Grid item xs={12}>
        <DBQuoteOfTheDay />
      </Grid>
      <Grid item xs={12} md={6}>
        <DBPortfolioHighLevel />
      </Grid>
      <Grid item xs={12} md={6}>
        <DBPortfolioDetailed />
      </Grid>
      <Grid item xs={12} md={6}>
        <DBSectorPerformance />
      </Grid>
      <Grid item xs={12} md={6}>
        <DBGainersAndLosers />
      </Grid>
    </Grid>);
}

export default Dashboard;
