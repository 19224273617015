import React, { useEffect } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import Link from "@mui/material/Link";
import FileDownLoad from "js-file-download";

// import Link from "react-router-dom";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const WEB = process.env.REACT_APP_WEB;
const WEBAPI = process.env.REACT_APP_WEB_API;

function QuickValuation() {
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    axios
      .get(`${WEBAPI}/upload/`)
      .then((res) => {
        const newRows = res.data.map((item, idx) => {
          return { id: idx, filename: item };
        });

        setRows(newRows);
        // console.log("QuickValuation", newRows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const downloadFile = (e, filename) => {
    e.preventDefault();
    // console.log("Download ", filename);

    axios
      .get(`${WEBAPI}/upload/${filename}`, { responseType: 'blob' })
      .then((res) => {
        FileDownLoad(res.data, filename);
        // console.log("Success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Paper sx={{ m: 2 }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" id="tableTitle" component="div">
          QuickVALUATION
        </Typography>
      </Box>

      <Box sx={{ p: 4 }}>
        {rows.map((row) => (
          <Link target="_blank" component="button" download variant="body2" key={row.id} onClick={(e) => downloadFile(e, row.filename)} sx={{ display: "block", p: 1 }}>
            {row.filename}
          </Link>
        ))}

      </Box>
    </Paper>
  );
}

export default QuickValuation;
