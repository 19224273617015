import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";

import { StrategyContext } from "./Context/StrategyContext";

import { Box, Paper, Tabs, Tab, Alert as MuiAlert, Snackbar, Breadcrumbs, Link, Typography, Stack, stepButtonClasses } from "@mui/material";

import PropTypes from "prop-types";

import QuickStrategyUniverse from "./QuickStrategyUniverse";
import QuickStrategyFactors from "./QuickStrategyFactors";
import QuickStrategyResults from "./QuickStrategyResults";
import QuickStrategyBackTest from "./QuickStrategyBackTest";
import QuickStrategyTopN from "./QuickStrategyTopN";
import BackTestResultsChart from "./Charts/BackTestResultsChart";
import BackTestReturnsChart from "./Charts/BackTestReturnsChart";
import BackTestStandardDeviationChart from "./Charts/BackTestStandardDeviationChart";
import QuickStrategyPerformance from "./QuickStrategyPerformance";
import QuickStrategyPerformance2 from "./QuickStrategyPerformance2";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const WEBAPI = process.env.REACT_APP_WEB_API;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const QuickStrategy = () => {
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [name, setName] = useState("");
  const [market, setMarket] = useState("");
  const [sectors, setSectors] = useState([]);
  const [marketCapMin, setMarketCapMin] = useState(0);
  const [marketCapMax, setMarketCapMax] = useState(10000000);
  const [topN, setTopN] = useState(10);
  const [factors, setFactors] = useState([]);
  const [backTestResults, setBackTestResults] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt(token);
      // console.log("decodedToken: ", decodedToken);

      if (decodedToken.email === "ken@afxsoftware.com" || decodedToken.email === "mkacur@fsavaluation.com") {
        // if (decodedToken.email === "ken@afxsoftware.com") {
        setAdmin(true);
      }
    }

    getStrategy();
  }, [id]);

  const getStrategy = () => {
    axios
      .get(`${WEBAPI}/quickstrategy/${id}`)
      .then((res) => {
        console.log(res.data);

        setName(res.data.name);
        setMarket(res.data.market);
        setSectors(res.data.sectors.split(","));
        setMarketCapMin(res.data.marketcap_min);
        setMarketCapMax(res.data.marketcap_max);
        setTopN(res.data.topN);
        setFactors(res.data.factors);
        setIsPublic(res.data.public);

        // get tab index from local storage
        // const tabIndex = localStorage.getItem('QSTRATEGY_TabIndex');
        // if (tabIndex) {
        //   setTabIndex(parseInt(tabIndex));
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStrategy = (payload) => {
    console.log(payload);

    axios
      .patch(`${WEBAPI}/quickstrategy`, payload)
      .then((res) => {
        console.log("updateStrategy res: ", res);
        setMarket(res.data.market);
        setSectors(res.data.sectors.split(","));
        setMarketCapMin(res.data.marketcap_min);
        setMarketCapMax(res.data.marketcap_max);
        setTopN(res.data.topN);
        // setFactors(res.data.factors);
      })
      .catch((error) => {
        console.log("handleUniverseChange error: ", error);

        setSnackbarMessage(`Opps, something went wrong.`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);

        getStrategy();
      });
  };

  const handleMarketChange = (newMarket) => {
    setMarket(newMarket);

    const payload = {
      id: id,
      market: newMarket,
    };

    updateStrategy(payload);
  };

  const handleSectorsChange = (newSectors) => {
    setSectors(newSectors);

    const payload = {
      id: id,
      sectors: newSectors.join(","),
    };

    updateStrategy(payload);
  };

  const handleMarketCapMinChange = (newMarketCapMin) => {
    setMarketCapMin(newMarketCapMin);

    const payload = {
      id: id,
      marketCapMin: newMarketCapMin,
    };

    updateStrategy(payload);
  };

  const handleMarketCapMaxChange = (newMarketCapMax) => {
    setMarketCapMax(newMarketCapMax);

    const payload = {
      id: id,
      marketCapMax: newMarketCapMax,
    };

    updateStrategy(payload);
  };

  const handleFactorsChange = (newFactors) => {
    // setFactors(newFactors)

    getStrategy();
  };

  const handleBackTestResultsChange = (newBackTestResults) => {
    setBackTestResults(newBackTestResults);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setBackTestResults([]);
    // save tab index to local storage
    // localStorage.setItem('QSTRATEGY_TabIndex', newValue);
  };

  const handleTopNChange = (value) => {
    setTopN(value);

    const payload = {
      id: id,
      topN: value,
    };

    updateStrategy(payload);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Paper sx={{ mx: 1, mt: 2, p: 2, overflow: "hidden" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/quickstrategies">
          All Strategies
        </Link>
        <Typography color="text.primary">{name}</Typography>
      </Breadcrumbs>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <StrategyContext.Provider
        value={{
          id,
          name,
          market,
          handleMarketChange,
          sectors,
          handleSectorsChange,
          marketCapMin,
          handleMarketCapMinChange,
          marketCapMax,
          handleMarketCapMaxChange,
          factors,
          handleFactorsChange,
          backTestResults,
          handleBackTestResultsChange,
          topN,
          handleTopNChange,
          isPublic,
        }}
      >
        <Box sx={{ width: "100%", mt: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Universe and Factors" {...a11yProps(0)} />
              <Tab label="Back Test" {...a11yProps(1)} disabled={factors.length === 0} />
              <Tab label="Rankings" {...a11yProps(2)} disabled={factors.length === 0} />
              <Tab label="Rankings over time" {...a11yProps(3)} disabled={factors.length === 0} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabIndex} index={0}>
            <Box>
              <QuickStrategyUniverse />
            </Box>
            <Box sx={{ mt: 5 }}>
              <QuickStrategyTopN />
            </Box>
            <Box sx={{ mt: 5 }}>
              <QuickStrategyFactors />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={2}>
            <QuickStrategyResults />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              <Box sx={{ position: "relative", width: "60%", minWidth: 600 }}>
                <BackTestResultsChart />
              </Box>

              <Stack direction="column" justifyContent="space-between" spacing={2} sx={{ width: "40%", minWidth: 600 }}>
                <Box sx={{ position: "relative" }}>
                  <BackTestReturnsChart />
                </Box>

                <Box sx={{ position: "relative" }}>
                  <BackTestStandardDeviationChart />
                </Box>
              </Stack>
            </Stack>

            <Box sx={{ mt: 6 }}>
              <QuickStrategyBackTest />
            </Box>

            {/* <QuickStrategyBackTestDebug /> */}
          </CustomTabPanel>

          <CustomTabPanel value={tabIndex} index={3}>
            <QuickStrategyPerformance />
            {/* <QuickStrategyPerformance2 /> */}
          </CustomTabPanel>
        </Box>
      </StrategyContext.Provider>
    </Paper>
  );
};

export default QuickStrategy;
