import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import jwt from 'jwt-decode'
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import { useAtom } from 'jotai';
import { emailAtom, firstNameAtom, lastNameAtom, authenticatedAtom, userIDAtom } from '../Atoms';

const WEBAPI = process.env.REACT_APP_WEB_API;


const validationSchema = yup.object({
  pin: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits'),

  email: yup
    .string('Enter your email')
    .email('Enter a valid email'),

  password: yup
    .string("Enter your new password")
    .min(6, "Password should be of minimum 6 characters"),
  password2: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export default function LoginPasswordCode() {
  const [invalid, setInvalid] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate();
  const [userID, setUserID] = useAtom(userIDAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(authenticatedAtom);


  const formik = useFormik({
    initialValues: {
      pin: "",
      email: "",
      password: "",
      password2: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(formik.values)

      axios.post(`${WEBAPI}/auth/passwordreset`, { email: values.email, pin: values.pin, password: values.password })
        .then((res) => {
          console.log(res)

          switch (res.data.status) {
            case "INVALID_PIN":
              setErrorMessage("Invalid Verification Code")
              setInvalid(true)
              break

            case "PIN_EXPIRED":
              setErrorMessage("Verification Code has expired, please reset again.")
              setInvalid(true)
              break

            case "USER_NOT_FOUND_OR_INACTIVE":
              setErrorMessage("User not found or inactive")
              setInvalid(true)
              break

            case "SUCCESS":
              // console.log('Successfully logged in.', res.data);
              localStorage.setItem("token", res.data.token);
              const decodedToken = jwt(res.data.token)

              setIsAuthenticated(true)
              setUserID(decodedToken.userid)
              setFirstName(decodedToken.fname)
              setLastName(decodedToken.lname)
              setEmail(decodedToken.email)

              navigate("/");
              break

            default:
              break;
          }
          setSubmitting(false)

        })
        .catch((err) => {
          console.log(err);
        })

      setSubmitting(false)
    },
  });

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper sx={{ pb: 5 }}>

        <form noValidate onSubmit={formik.handleSubmit} autoComplete='false' >
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} padding={4}>

            <Grid item xs={4} md={4} align="center">
              <Avatar sx={{ backgroundColor: (theme) => theme.palette.secondary.main }} >
                <LockOutlinedIcon />
              </Avatar>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography component="h1" align="left" variant="h5" noWrap>
                Password
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.pin}
                onChange={formik.handleChange}
                error={formik.touched.pin && Boolean(formik.errors.pin)}
                helperText={formik.touched.pin && formik.errors.pin}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                name="pin"
                label="Verification Code"
                type="text"
                id="pin"
                autoComplete="new-password"
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
              <FormHelperText>Your verification code was sent to you via email.</FormHelperText>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="new-password"
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.password2}
                onChange={formik.handleChange}
                error={formik.touched.password2 && Boolean(formik.errors.password2)}
                helperText={formik.touched.password2 && formik.errors.password2}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirm New Password"
                type="password"
                id="password2"
                autoComplete="new-password"
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </Grid>

            <Grid item xs={12}>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
              >
                Log In
              </Button>

              <FormHelperText style={{ display: (invalid) ? 'block' : 'none', color: 'red' }} >{errorMessage}</FormHelperText>

            </Grid>
          </Grid>


        </form>
      </Paper >
    </Container >
  );
}
