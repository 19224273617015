import React, { useEffect, useState } from "react";
import { Column, ColumnDef, createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable, getGroupedRowModel } from "@tanstack/react-table";
import { OutlinedInput, MenuItem, FormControl, InputLabel, Select, TextField, Typography, Box, CircularProgress, Stack, Tooltip } from "@mui/material";
import { faker } from "@faker-js/faker";

// import "./TanTable.css";

//These are the important styles to make sticky column pinning work!
//Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
//View the index.css file for more needed styles such as border-collapse: separate
const getCommonPinningStyles = (column) => {
  console.log("column", column);

  const isPinned = column.getIsPinned();
  console.log("isPinned", isPinned);

  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
  console.log("isLastLeftPinnedColumn", isLastLeftPinnedColumn);

  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");
  console.log("isFirstRightPinnedColumn", isFirstRightPinnedColumn);

  return {
    boxShadow: isLastLeftPinnedColumn ? "-4px 0 4px -4px gray inset" : isFirstRightPinnedColumn ? "4px 0 4px -4px gray inset" : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 1 : 1,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
};

const indicatorSize = 80;
const WEBAPI = process.env.REACT_APP_WEB_API;
const columnHelper = createColumnHelper();

const staticColumns = [
  columnHelper.group({
    id: "hello",
    header: 'Header Group1',
    columns: [
      columnHelper.accessor("id", {
        header: "ID",
        id: "id",
        cell: (info) => <Typography sx={{ textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 250,
      }),
      columnHelper.accessor("fname", {
        header: "First Name",
        id: "fname",
        cell: (info) => <Typography sx={{ textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 100,
      }),
      columnHelper.accessor("lname", {
        header: "Last Name",
        id: "lname",
        cell: (info) => <Typography sx={{ textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 150,
      }),
    ],
  }),

  columnHelper.group({
    id: "hello",
    header: 'Header Group2',
    columns: [
      columnHelper.accessor("age", {
        header: "Age",
        id: "age",
        cell: (info) => <Typography sx={{ marginLeft: "2px", textAlign: "right", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 50,
      }),
      columnHelper.accessor("btcaddress", {
        header: "Recieve BTC Address",
        id: "btcaddress",
        cell: (info) => <Typography sx={{ marginLeft: "2px", textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 250,
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
        cell: (info) => <Typography sx={{ marginLeft: "2px", textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 10000,
      }),
    ],
  }),
];

function TanTable() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = React.useState([]);
  const [grouping, setGrouping] = React.useState([]);
  const [columnPinning, setColumnPinning] = React.useState({ left: ["id"], right: [] });

  const tanTable = useReactTable({
    data,
    columns,
    state: {
      sorting,
      grouping,
      columnPinning,
    },

    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onColumnPinningChange: setColumnPinning,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),

    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  const makeData = (num) => {
    return Array.from({ length: num }, () => {
      return {
        id: faker.string.uuid(),
        fname: faker.person.firstName(),
        lname: faker.person.lastName(),
        age: faker.finance.amount({ min: 20, max: 80, dec: 0 }),
        btcaddress: faker.finance.bitcoinAddress(),
        description: faker.lorem.sentence({ min: 3, max: 50 }),
      };
    });
  };

  useEffect(() => {
    setLoading(true);

    setColumns([...staticColumns]);

    const contacts = makeData(100);
    setData(contacts);
    console.log("contacts", contacts);

    setLoading(false);
  }, []);

  return loading ? (
    <Stack alignItems="center" sx={{ height: "100vh" }}>
      <CircularProgress color="secondary" size={indicatorSize} sx={{ position: "absolute", top: "50%", left: "50%", marginTop: `${-indicatorSize / 2}px`, marginLeft: `${-indicatorSize / 2}px` }} />
    </Stack>
  ) : (
    <>
      <Box display={"flex"} alignItems={"center"} sx={{ m: 2 }}>
        <div className="tanstack_container">
          <table className="tanstack_table">
            <thead>
              {tanTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const { column } = header;
                    return (
                      <th key={header.id} colSpan={header.colSpan} className="pointer" style={{ ...getCommonPinningStyles(column) }}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <Typography sx={{ width: header.getSize(), ml: 0, fontSize: "12px", height: "100%" }}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{ asc: " 🔼", desc: " 🔽" }[header.column.getIsSorted()] ?? null}
                            </Typography>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody>
              {tanTable.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const { column } = cell;

                    // if (cell.column.id.startsWith("id")) {
                    //   return (
                    //     <td key={cell.id} style={{ ...getCommonPinningStyles(column) , backgroundColor: 'cornflowerblue', color: "white", borderStyle: "hidden" }}>
                    //       {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    //     </td>
                    //   );
                    // } else
                    return (
                      <td key={cell.id} style={{ ...getCommonPinningStyles(column) }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>

      {/* <Box>
      <pre>{JSON.stringify(tanTable.getState().columnPinning, null, 2)}</pre>
    </Box> */}
    </>
  );
}

export default TanTable;
