import * as React from 'react';
import { Box, Autocomplete, TextField } from '@mui/material'
import axios from 'axios';

const WEBAPI = process.env.REACT_APP_WEB_API;

function TickerSearch({handleAddTicker, disabled}) {
    const [label, setLabel] = React.useState('Enter Ticker or Company Name')
    const [ticker, setTicker] = React.useState('')
    const [tickerList, setTickerList] = React.useState([])

    const tickerChange = (event) => {
        if (event.target.value.length > 1)
        {
            axios.get(`${WEBAPI}/ticker/` + event.target.value)
            .then((res) => {
                console.log(res.data)
                setTickerList(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
        }
    }

    const tickerSelected = (value) => {
        console.log('tickerSelected', value)

        setTicker(value?.ProductID)

        // clear the list
        // setTickerList([])
        // setLabel('')
        // setTicker('')

        if (value === null) return

        handleAddTicker(value.ProductID)
    }
  
    return (
        <Box>
            <Autocomplete disablePortal id="combo-box-demo" size="small" sx={{ width: 400 }} 
                disabled={disabled}
                options={tickerList} 
                getOptionLabel={(option) => option["Ticker.Exchange"] + ' - ' + option.StandardName}
                onChange={(event, value) => { tickerSelected(value) }}

                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.Code}.png`}
                        alt="country flag"
                      />
                      {option.label} {option["Ticker.Exchange"]} - {option.StandardName} 
                    </Box>
                )}

                renderInput={(params) => <TextField {...params} label={label} onChange={tickerChange} />}
            />
        </Box>
    );
}

export default TickerSearch;
