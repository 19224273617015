import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from '@mui/material'
import Avatar from "@mui/material/Avatar";
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import jwt from 'jwt-decode'
import FormHelperText from "@mui/material/FormHelperText";
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useAtom } from 'jotai';
import { emailAtom, firstNameAtom, lastNameAtom, authenticatedAtom, userIDAtom } from '../Atoms';


const WEBAPI = process.env.REACT_APP_WEB_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const validationSchema = yup.object({
  password: yup
    .string("Enter your new password")
    .min(6, "Password should be of minimum 6 characters"),
  password2: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export default function LoginPasswordNew() {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [invalid, setInvalid] = useState(false)
  const [userID, setUserID] = useAtom(userIDAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(authenticatedAtom);


  // const closeSnackbar = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpenSnackbar(false);
  // };


  const formik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // setFormSubmitting(true)

      axios.post(`${WEBAPI}/auth/login`, { email: email, password: values.password, newpwd: true })
        .then((res) => {
          console.log(res)

          if (res.data.status === "INVALID_PWD") {
            // setInvalid(true)
          } else {
            console.log('Successfully logged in.', res.data);
            localStorage.setItem("token", res.data.token);
            const decodedToken = jwt(res.data.token)

            setIsAuthenticated(true)
            setUserID(decodedToken.userid)
            setFirstName(decodedToken.fname)
            setLastName(decodedToken.lname)
            setEmail(decodedToken.email)

            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
        })

      setSubmitting(false)
    },
  });

  // const handlePasswordChange = (e) => {
  //   setInvalid(false)
  //   formik.setFieldValue("password", e.target.value, false);
  // }

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper >
        {/* <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
        </Snackbar> */}


        <form noValidate onSubmit={formik.handleSubmit} autoComplete='false' >
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} padding={4}>

            <Grid item xs={4} md={4} align="center">
              <Avatar sx={{ backgroundColor: (theme) => theme.palette.secondary.main }} >
                <LockOutlinedIcon />
              </Avatar>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography component="h1" align="left" variant="h5" noWrap>
                Password
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoFocus
                autoComplete="new-password"
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.password2}
                onChange={formik.handleChange}
                error={formik.touched.password2 && Boolean(formik.errors.password2)}
                helperText={formik.touched.password2 && formik.errors.password2}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirm New Password"
                type="password"
                id="password2"
                autoComplete="new-password"
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
              <FormHelperText style={{ display: (invalid) ? 'block' : 'none', color: 'red' }} >Your password does not match.</FormHelperText>
            </Grid>

            <Grid item xs={12}>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
              >
                Log In
              </Button>
            </Grid>

          </Grid>
        </form>
      </Paper >
    </Container >
  );
}
