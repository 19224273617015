import { Box } from "@mui/material";
import React from "react";

const Help = () => {
  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <iframe title="help" src="/legend.pdf" width="100%" height="5500px" />
    </Box>
  );
};

export default Help;
