import ColorScale from "color-scales";

export const performanceColorCellFromValue = (value) => {
  if (isNaN(value)) return "#aaaaaa";

  if (value < -10) return "#ff0000";
  if (value > 15) return "#00b050";

  if (value <= 0) {
    let colorScale = new ColorScale(-10, 0, ["#ff0000", "#FFFFFF"]);
    let hexStr = colorScale.getColor(value).toHexString();
    return hexStr;
  } else if (value > 0) {
    let colorScale = new ColorScale(0, 15, ["#E6F8EE", "#00b050"]);
    let hexStr = colorScale.getColor(value).toHexString();
    return hexStr;
  }
};
