import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Box,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    Typography,

} from '@mui/material';

import {
    Menu as MenuIcon,
    Delete as DeleteIcon,
    ModeEdit as ModeEditIcon,
    NoteAdd as NoteAddIcon,
    SaveAs as SaveAsIcon
} from '@mui/icons-material'


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function TableOptionsMenu() {
    const [anchorElOptions, setAnchorElOptions] = React.useState(null);
    const [rowId, setRowId] = React.useState(null);

    const handleOpenOptionsMenu = (event) => {
        // console.log(event.currentTarget.value)
        setAnchorElOptions(event.currentTarget);
    };

    const handleCloseOptionsMenu = () => {
        setAnchorElOptions(null);
    };

    const handleRename = (e) => {
        console.log('rename', rowId)
        setAnchorElOptions(null);
        e.stopPropagation();
    }

    const handleMakeCopy = (e) => {
        console.log('make copy', rowId)
        setAnchorElOptions(null);
        e.stopPropagation();
    }

    const handleDelete = (e) => {
        console.log('delete', rowId)
        setAnchorElOptions(null);
        e.stopPropagation();
    }

    const handleRowClick = (name) => {
        console.log(name)
        setRowId(name)
    }

    return (
        <TableContainer component={Paper} sx={{ m: 0 }}>
            <Table sx={{ width: 900 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        <TableCell align="center">Options</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={() => handleRowClick(row.name)}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            <TableCell align="center" >

                                <Box sx={{ textAlign: 'center' }}>
                                    <Tooltip title="Options" >
                                        <IconButton onClick={handleOpenOptionsMenu}  >
                                            <MenuIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="options-menu"
                                        anchorEl={anchorElOptions}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElOptions)}
                                        onClose={handleCloseOptionsMenu}
                                    >
                                        <MenuItem key='rename' onClick={(e) => handleRename(e) }><Typography textAlign="center">Rename</Typography></MenuItem>
                                        <MenuItem key='makecopy' onClick={(e) => handleMakeCopy(e)}><Typography textAlign="center">Make a copy</Typography></MenuItem>
                                        <MenuItem key='delete' onClick={(e) => handleDelete(e)}><Typography textAlign="center">Delete</Typography></MenuItem>
                                        <MenuItem key='open' onClick={(e) => handleDelete(e)}><Typography textAlign="center">Open in a new tab</Typography></MenuItem>
                                    </Menu>
                                </Box>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
