import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { BarChart, Bar, Cell, YAxis } from "recharts";
import QuickSectorSearch from "./QuickSectorSearch";
import { Typography } from "@mui/material";
import { performanceColorCellFromValue } from "./utils/PerformanceColourScale";
import CheckIcon from "@mui/icons-material/Check";

const headCells = [
  { id: "ticker", label: "Ticker", align: "left", minWidth: 10 },
  { id: "company", label: "Company Name", align: "left", minWidth: 200 },
  { id: "domicile_country", label: "Domiciled", align: "left", minWidth: 10 },
  { id: "sector", label: "Sector", align: "left", minWidth: 10 },
  { id: "marketcap", label: "Market Cap ($M)", align: "left", minWidth: 20 },
  { id: "InPortfolio", label: "In Portfolio", align: "left", minWidth: 20 },
  { id: "pricechg3years", label: "3 Year (CAGR)", tooltip: "3 Year (Compound Annual Growth Rate)", align: "left", minWidth: 20 },
  { id: "pricechg1years", label: "1 Year", align: "left", minWidth: 20 },
  { id: "pricechg6months", label: "6 Months", align: "left", minWidth: 20 },
  { id: "pricechg3months", label: "3 Months", align: "left", minWidth: 20 },
  { id: "pricechg1months", label: "1 Month", align: "left", minWidth: 20 },
  { id: "currency", label: "Currency", align: "left", minWidth: 20 },
  { id: "last_price", label: "Last Price", align: "left", minWidth: 20 },
  { id: "lfq", label: "Most Recent Data", align: "left", minWidth: 30 },
  { id: "chart1", label: "CFIRR 24 Q's", align: "left", minWidth: 10 },
  { id: "cfirrex_ttm12", label: "CFIRR 3 Yrs ago", align: "left", minWidth: 20 },
  { id: "cfirrex_ttm04", label: "CFIRR 1 Yr ago", align: "left", minWidth: 20 },
  { id: "cfirrex_ttm02", label: "CFIRR 2Q's ago", align: "left", minWidth: 20 },
  { id: "cfirrex_ttm00", label: "CFIRR (TFQ)", tooltip: "CFIRR (Trailing Four Quarters)", align: "left", minWidth: 20 },
  { id: "cfirrexchg_TTMvPY00", label: "CFIRR Chg vs SQPY", tooltip: "CFIRR Chg vs Same Quarter Previous Year", align: "left", minWidth: 20 },
  { id: "chart2", label: "Revenue (Ann, TFQ)", tooltip: "Revenue (Annualized, Trailing Four Quarters)", align: "left", minWidth: 10, maxWidth: 10 },
  { id: "revenuechg_TTMvPY00", label: "Revenue Grw (TFQ)", tooltip: "Revenue Growth (Trailing Four Quarters)", align: "left", minWidth: 20 },
  { id: "chart3", label: "EBITDA PS Roll TFQ", tooltip: "EBITDA Per Share Rolling Trailing Four Quarters", align: "left", minWidth: 100, maxWidth: 100 },
  { id: "basicebitdachg_TTMvPY00", label: "EBITDA Growth (TFQ)", tooltip: "EBITDA Growth (Trailing Four Quarters)", align: "left", minWidth: 20 },
  { id: "pe_LFY00", label: "P/E (TFQ)", tooltip: "P/E (Trailing Four Quarters)", align: "left", minWidth: 20, maxWidth: 20 },
  { id: "evebitda_TTM00", label: "EV/EBITDA (TFQ)", tooltip: "EV/EBITDA (Trailing Four Quarters)", align: "left", minWidth: 20 },
  { id: "chart4", label: "Div Growth", tooltip: "Dividend Growth", align: "left", minWidth: 100, maxWidth: 100 },
  { id: "dividendyieldchg_TTMvpy00", label: "Div Growth (TFQ)", tooltip: "Dividend Growth (Trailing Four Quarters)", align: "left", minWidth: 20 },
  { id: "dividendyield_TTM00", label: "Div Yield (TFQ)", tooltip: "Dividend Yield (Trailing Four Quarters)", align: "left", minWidth: 20 },
  { id: "dividendpayoutratio_TTM00", label: "Div Payout Ratio", tooltip: "Dividend Payout Ratio", align: "left", minWidth: 20 },
];

const WEBAPI = process.env.REACT_APP_WEB_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const indicatorSize = 80;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell align="center" sx={{ border: 1, borderRight: 1, borderColor: '#E0E0E0' }}>
                </TableCell> */}
        <TableCell key="General" align="center" colSpan={6} sx={{ fontFamily: "ArchivoNarrow-Regular", border: 1, borderLeft: 1, borderRight: 0, borderColor: "#E0E0E0" }}>
          General
        </TableCell>
        <TableCell key="StockMarketPerformance" align="center" colSpan={7} sx={{ fontFamily: "ArchivoNarrow-Regular", border: 1, borderRight: 0, borderColor: "#E0E0E0" }}>
          Stock Market Performance
        </TableCell>
        <TableCell key="Corporate Performance" align="center" colSpan={11} sx={{ fontFamily: "ArchivoNarrow-Regular", border: 1, borderRight: 0, borderColor: "#E0E0E0" }}>
          Corporate Performance
        </TableCell>
        <TableCell key="Valuation" align="center" colSpan={2} sx={{ fontFamily: "ArchivoNarrow-Regular", border: 1, borderRight: 0, borderColor: "#E0E0E0" }}>
          Valuation
        </TableCell>
        <TableCell key="Dividends" align="center" colSpan={4} sx={{ fontFamily: "ArchivoNarrow-Regular", border: 1, borderColor: "#E0E0E0" }}>
          Dividends
        </TableCell>
      </TableRow>

      <TableRow>
        {headCells.map((headCell) => (
          <Tooltip key={headCell.id} title={headCell.tooltip ?? headCell.label}>
            <TableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false} width={headCell.width} sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", writingMode: "vertical-lr", transform: "rotate(180deg)", WebkitTransform: "rotate(0deg)", pl: 0, pr: 0, pt: 1, pb: 0, border: 1, borderColor: "#E0E0E0" }}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? (order == "asc" ? "desc" : "asc") : "asc"} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function QuickSector() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("company");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [payload, setPayload] = React.useState({});
  // const [portfolioID, setPortfolioID] = React.useState([]);
  // const [portfolio, setPortfolio] = React.useState([]);

  useEffect(() => {
    console.log("QuickSector UseEffect1");

    const portfolioRowsPerPage = localStorage.getItem("QS_RowsPerPage") || 25;
    setRowsPerPage(parseInt(portfolioRowsPerPage, 10));

    const localPortfolio = localStorage.getItem("QS_Portfolio") || "NONE";
    const localMarket = localStorage.getItem("QS_Market") || "CAN";
    const localRegions = localStorage.getItem("QS_Regions") || [];
    const localSector = localStorage.getItem("QS_Sector") || "CD";
    const localMarketCapMin = localStorage.getItem("QS_MarketCapMin") || 0;
    const localMarketCapMax = localStorage.getItem("QS_MarketCapMax") || 20000;

    localStorage.setItem("QS_Portfolio", localPortfolio);
    localStorage.setItem("QS_Market", localMarket);
    localStorage.setItem("QS_Regions", localRegions);
    localStorage.setItem("QS_Sector", localSector);
    localStorage.setItem("QS_MarketCapMin", localMarketCapMin);
    localStorage.setItem("QS_MarketCapMax", localMarketCapMax);

    const userPayload = {
      portfolio: localPortfolio,
      market: localMarket,
      regions: localRegions,
      sector: localSector,
      marketCapMin: localMarketCapMin,
      marketCapMax: localMarketCapMax,
    };

    setPayload(userPayload);

    console.log(userPayload);

    // const fetchData = async () => {
    //   setLoading(true);
    //   const searchResults = await getSearchResults(userPayload);
    //   console.log("searchResults", searchResults);

    //   await getPortfolio(searchResults, localPortfolio);
    //   setLoading(false);
    // };

    fetchData(userPayload);
  }, []);

  const getPortfolio = async (searchResults, portfolioID) => {
    console.log("getPortfolio", portfolioID);
    console.log("searchResults", searchResults);

    let results = [];

    if (portfolioID !== "NONE") {
      results = await axios
        .get(`${WEBAPI}/quickportfolio/results/${portfolioID}`)
        .then((res) => {
          console.log("portfolio: ", res.data);
          // setPortfolio(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    }

    return results;
  };

  const highlightPortfolio = async (searchResults, newPortfolio) => {
    console.log("searchResults", searchResults);
    console.log("newPortfolio", newPortfolio);

    if (newPortfolio.length === 0) {
      const updatedData = searchResults.map((x) => {
        x.InPortfolio = false;
        return x;
      });

      setRows(updatedData);
    } else {
      const updatedData = searchResults.map((x) => {
        const found = newPortfolio.find((element) => {
          return element.product_id === x.product_id;
        });

        if (found) {
          x.InPortfolio = true;
        } else {
          x.InPortfolio = false;
        }

        return x;
      });

      setRows(updatedData);
    }
  };

  const handleRequestSort = (event, property) => {
    if (orderBy === property) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("desc");
    }

    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage]);

  const fetchData = async (payloadData) => {
    setLoading(true);
    const searchResults = await getSearchResults(payloadData);
    console.log("searchResults", searchResults);

    const newPortfolio = await getPortfolio(searchResults, payloadData.portfolio);

    highlightPortfolio(searchResults, newPortfolio);
    setLoading(false);
  };

  const handleFetchResults = async (payload) => {
    // setLoading(true);
    // const searchResults = await getSearchResults(payload);
    // console.log("searchResults", searchResults);
    // setRows(searchResults);

    // setLoading(false);

    fetchData(payload);
  };

  // const handlePortfolioChange = async (portfolioID) => {
  //   console.log("handlePortfolioChange", portfolioID);
  //   setPortfolio(portfolioID);

  //   await getPortfolio(rows, portfolioID);
  //   highlightPortfolio();
  // }

  const getSearchResults = async (userPayload) => {
    console.log(userPayload);

    // setSector(payload.sector)

    // setLoading(true);

    const results = await axios
      .post(`${WEBAPI}/quicksector/results`, userPayload)
      .then((res) => {
        //console.log(res.data)

        // chart_cfirrEx
        let tmp = [];
        res.data.forEach((row) => {
          if (row.chart_cfirrEx === null) {
            tmp.push([]);
          } else {
            tmp.push(row.chart_cfirrEx.split(",").map(Number));
          }
        });

        let tmp2 = [];
        tmp.forEach((row) => {
          tmp2.push(
            row.map((item, index) => {
              // if (item<-10) item = -10
              // if (item>30) item = 30
              let colour = item > 0 ? "green" : "red";

              return { y: item, color: colour };
            })
          );
        });

        res.data.forEach((row, index) => {
          row.chart_cfirrEx = tmp2[index];
        });

        // basicEBITDA
        tmp = [];
        res.data.forEach((row) => {
          if (row.chart_basicEBITDA === null) {
            tmp.push([]);
          } else {
            tmp.push(row.chart_basicEBITDA.split(",").map(Number));
          }
        });

        tmp2 = [];
        tmp.forEach((row) => {
          tmp2.push(
            row.map((item, index) => {
              let colour = item > 0 ? "cornflowerblue" : "red";
              return { y: item, color: colour };
            })
          );
        });

        res.data.forEach((row, index) => {
          row.chart_basicEBITDA = tmp2[index];
        });

        // dividendYield
        tmp = [];
        res.data.forEach((row) => {
          if (row.chart_dividendYield === null) {
            tmp.push([]);
          } else {
            tmp.push(row.chart_dividendYield.split(",").map(Number));
          }
        });

        tmp2 = [];
        tmp.forEach((row) => {
          tmp2.push(
            row.map((item, index) => {
              // if (item<50) item = 50
              // if (item>300) item = 300
              let colour = item > 0 ? "cornflowerblue" : "red";

              return { y: item, color: colour };
            })
          );
        });

        res.data.forEach((row, index) => {
          row.chart_dividendYield = tmp2[index];
        });

        // revenue
        tmp = [];
        res.data.forEach((row) => {
          if (row.chart_revenue === null) {
            tmp.push([]);
          } else {
            tmp.push(row.chart_revenue.split(",").map(Number));
          }
        });

        tmp2 = [];
        tmp.forEach((row) => {
          tmp2.push(
            row.map((item, index) => {
              let colour;
              if (index <= 5) {
                colour = item > 0 ? "cornflowerblue" : "red";
              } else {
                colour = item > 0 ? "lightblue" : "lightred";
              }

              return { y: item, color: colour };
            })
          );
        });

        res.data.forEach((row, index) => {
          row.chart_revenue = tmp2[index];
        });

        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });

    console.log(results);
    return results;
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // rowsPerPage = event.target.value;
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("QS_RowsPerPage", event.target.value);
    setPage(0);
  };

  const handleSaveAsPortfolio = (portfolioName) => {
    const productIDs = rows.map((row) => {
      return row.product_id;
    });

    axios
      .post(`${WEBAPI}/quicksector/saveasportfolio`, { portfolioName: portfolioName, products: productIDs })
      .then((res) => {
        console.log(res);

        if (res === undefined) {
          setSnackbarMessage("Error saving portfolio");
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage("Portfolio saved successfully");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSnackbarMessage("Portfolio already exists");
        } else {
          setSnackbarMessage("Error saving portfolio");
        }

        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  const handleSaveToExistingPortfolio = (portfolioID) => {
    const productIDs = rows.map((row) => {
      return row.product_id;
    });

    axios
      .post(`${WEBAPI}/quicksector/addtoportfolio`, { portfolioID: portfolioID, products: productIDs })
      .then((res) => {
        // console.log(res)

        setSnackbarMessage("Portfolio saved successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setSnackbarMessage("Error saving portfolio");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  return loading ? (
    <Stack alignItems="center" sx={{ height: "100vh" }}>
      <CircularProgress color="secondary" size={indicatorSize} sx={{ position: "absolute", top: "50%", left: "50%", marginTop: `${-indicatorSize / 2}px`, marginLeft: `${-indicatorSize / 2}px` }} />
    </Stack>
  ) : (
    <>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <div style={{ width: "100%", marginBottom: 20 }}>
        <QuickSectorSearch handleGetSearchResults={handleFetchResults} rowCount={rows.length} handleSaveAsPortfolio={handleSaveAsPortfolio} handleSaveToExistingPortfolio={handleSaveToExistingPortfolio} />
      </div>

      <Paper sx={{ mx: 1, mt: 1, p: 2 }}>
        <TableContainer sx={{ mt: 0, maxHeight: "calc(100vh - 265px)" }}>
          <Table size="small" stickyHeader sx={{ width: "100%" }}>
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
            <TableBody>
              {(rowsPerPage > 0 ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(rows, getComparator(order, orderBy))).map((row, index) => {
                // const isItemSelected = isSelected(row.product_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                //console.log(index,row.ticker)

                return (
                  <TableRow hover tabIndex={-1} key={row.product_id}>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: row.InPortfolio ? "cornflowerblue" : null, color: row.InPortfolio ? "white" : null, borderStyle: row.InPortfolio ? "hidden" : null }}>
                      &nbsp;{row.ticker}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: row.InPortfolio ? "cornflowerblue" : null, color: row.InPortfolio ? "white" : null, borderStyle: row.InPortfolio ? "hidden" : null }}>
                      {row.company}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ fontSize: "0.5rem", backgroundColor: row.InPortfolio ? "cornflowerblue" : null, color: row.InPortfolio ? "white" : null, borderStyle: row.InPortfolio ? "hidden" : null }} align="center">
                      <Tooltip title={row.domicile_country}>
                        <img src={`https://flagcdn.com/w20/${row.domicile_country_flag?.toLowerCase()}.png`} width="20" alt="flag" />
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: row.InPortfolio ? "cornflowerblue" : null, color: row.InPortfolio ? "white" : null, borderStyle: row.InPortfolio ? "hidden" : null }} align="center">
                      {row.sector}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ pr: 1, fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: row.InPortfolio ? "cornflowerblue" : null, color: row.InPortfolio ? "white" : null, borderStyle: row.InPortfolio ? "hidden" : null }} align="right">
                      {row.marketcap?.toLocaleString("en-US")}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: row.InPortfolio ? "cornflowerblue" : null, color: row.InPortfolio ? "white" : null, borderStyle: row.InPortfolio ? "hidden" : null }} align="center">
                      {row.InPortfolio ? <CheckIcon></CheckIcon> : ""}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: performanceColorCellFromValue(row.pricechg3years), borderStyle: "hidden" }}>
                      {isNaN(row.pricechg3years) ? "" : (Math.round(row.pricechg3years * 10) / 10).toFixed(1)}%
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: performanceColorCellFromValue(row.pricechg1years), borderStyle: "hidden" }}>
                      {isNaN(row.pricechg1years) ? "" : (Math.round(row.pricechg1years * 10) / 10).toFixed(1)}%
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: performanceColorCellFromValue(row.pricechg6months), borderStyle: "hidden" }}>
                      {isNaN(row.pricechg6months) ? "" : (Math.round(row.pricechg6months * 10) / 10).toFixed(1)}%
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: performanceColorCellFromValue(row.pricechg3months), borderStyle: "hidden" }}>
                      {isNaN(row.pricechg3months) ? "" : (Math.round(row.pricechg3months * 10) / 10).toFixed(1)}%
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", backgroundColor: performanceColorCellFromValue(row.pricechg1months), borderStyle: "hidden" }}>
                      {isNaN(row.pricechg1months) ? "" : (Math.round(row.pricechg1months * 10) / 10).toFixed(1)}%
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                      {row.currency}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                      ${row.last_price?.toLocaleString("en-US")}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", whiteSpace: "nowrap", px: 0 }}>
                      {row.lfq_label}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ width: 100, minWidth: 100, maxWidth: 100 }}>
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <BarChart width={100} height={30} data={row.chart_cfirrEx} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <YAxis type="number" domain={[-10, 30]} hide="true" allowDataOverflow={true} />
                            <Bar dataKey="y" color="color">
                              {row.chart_cfirrEx.map((entry, index) => (
                                <Cell key={`chart_cfirr_cell-${index}`} fill={entry.color} />
                              ))}
                            </Bar>
                          </BarChart>
                        </Box>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.cfirrex_ttm12 > 0 ? "green" : "red" }}>
                          {(Math.round(row.cfirrex_ttm12 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.cfirrex_ttm04 > 0 ? "green" : "red" }}>
                          {(Math.round(row.cfirrex_ttm04 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.cfirrex_ttm02 > 0 ? "green" : "red" }}>
                          {(Math.round(row.cfirrex_ttm02 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.cfirrex_ttm00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.cfirrex_ttm00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.cfirrexchg_TTMvPY00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.cfirrexchg_TTMvPY00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ width: 35, minWidth: 35, maxWidth: 35 }}>
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <BarChart width={35} height={30} data={row.chart_revenue} margin={{ top: 0, right: 0, left: 5, bottom: 0 }}>
                            <Bar dataKey="y" color="color">
                              {row.chart_revenue.map((entry, index) => (
                                <Cell key={`chart_revenue_cell-${index}`} fill={entry.color} />
                              ))}
                            </Bar>
                          </BarChart>
                        </Box>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.revenuechg_TTMvPY00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.revenuechg_TTMvPY00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ width: 100, minWidth: 100, maxWidth: 100 }}>
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <BarChart width={100} height={30} data={row.chart_basicEBITDA} margin={{ top: 0, right: 0, left: 5, bottom: 0 }}>
                            <Bar dataKey="y" color="color">
                              {row.chart_basicEBITDA.map((entry, index) => (
                                <Cell key={`chart_revEBITDA_cell-${index}`} fill={entry.color} />
                              ))}
                            </Bar>
                          </BarChart>
                        </Box>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.basicebitdachg_TTMvPY00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.basicebitdachg_TTMvPY00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.pe_LFY00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.pe_LFY00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.evebitda_TTM00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.evebitda_TTM00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ width: 100, minWidth: 100, maxWidth: 100 }}>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <BarChart width={100} height={30} data={row.chart_dividendYield} margin={{ top: 0, right: 0, left: 5, bottom: 0 }}>
                          <YAxis type="number" hide="true" />
                          <Bar dataKey="y" color="color">
                            {row.chart_dividendYield.map((entry, index) => (
                              <Cell key={`chart_dividendYield_cell-${index}`} fill={entry.color} />
                            ))}
                          </Bar>
                        </BarChart>
                      </Box>
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                      {(Math.round(row.dividendyieldchg_TTMvpy00 * 10) / 10)?.toLocaleString("en-US")}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                      {(Math.round(row.dividendyield_TTM00 * 10) / 10)?.toLocaleString("en-US")}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right" sx={{ pr: 1 }}>
                      {payload.sector === "FI" ? (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem" }}>
                          N/A
                        </Typography>
                      ) : (
                        <Typography align="center" sx={{ fontFamily: "ArchivoNarrow-Regular", fontSize: "0.75rem", color: row.dividendpayoutratio_TTM00 > 0 ? "green" : "red" }}>
                          {(Math.round(row.dividendpayoutratio_TTM00 * 10) / 10)?.toLocaleString("en-US")}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[5, 10, 15, 20, 25, { label: "All", value: -1 }]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} showFirstButton={true} showLastButton={true} labelRowsPerPage="Companies per page:" />
      </Paper>
    </>
  );
}
