import React, { useContext, useEffect, useState } from "react";
import { StrategyContext } from "./Context/StrategyContext";
import axios from "axios";

import { Typography, Button, Box, IconButton, FormControl, InputLabel, Select, MenuItem, OutlinedInput, TextField, Snackbar, Alert as MuiAlert } from "@mui/material";

import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { categories, metrics } from "./Constants/constants";
import { TableCell } from "./TanTable/TableCell";
import { EditCell } from "./TanTable/EditCell";

const WEBAPI = process.env.REACT_APP_WEB_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const indicatorSize = 80;

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    header: "Id",
    id: "id",
    cell: (info) => info.getValue(),
    size: 10,
    hide: true,
  }),
  columnHelper.accessor("category", {
    header: "Category",
    id: "category",
    cell: TableCell,
    meta: {
      type: "select",
      options: categories,
    },
    size: 130,
  }),
  columnHelper.accessor((row) => row.metric, {
    header: "Metric",
    id: "metric",
    cell: TableCell,
    meta: {
      type: "select",
      options: metrics,
    },
    size: 200,
  }),
  columnHelper.accessor((row) => row.minimum, {
    header: "Minimum",
    id: "minimum",
    cell: TableCell,
    meta: {
      type: "number",
    },
    size: 60,
  }),
  columnHelper.accessor((row) => row.maximum, {
    header: "Maximum",
    id: "maximum",
    cell: TableCell,
    meta: {
      type: "number",
    },
    size: 60,
  }),
  columnHelper.accessor((row) => row.rank, {
    header: "Rank",
    id: "rank",
    cell: TableCell,
    meta: {
      type: "select",
      options: [
        { value: "DESC", label: "High to Low" },
        { value: "ASC", label: "Low to High" },
      ],
    },
    size: 110,
  }),
  columnHelper.accessor((row) => row.weight, {
    header: "Weight",
    id: "weight",
    cell: TableCell,
    meta: {
      type: "number",
    },
    size: 50,
  }),
  columnHelper.accessor((row) => row.weight_pct, {
    header: "Weight %",
    id: "weight_pct",
    cell: TableCell,
    meta: {
      type: "number",
    },
    size: 60,
  }),
  columnHelper.display({
    header: "Options",
    id: "edit",
    cell: EditCell,
    size: 90,
  }),
];

const QuickStrategyFactors = () => {
  const { id, factors, handleFactorsChange, isPublic } = useContext(StrategyContext);

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [editedRows, setEditedRows] = useState({});

  const [category, setCategory] = useState("");
  const [metric, setMetric] = useState("");
  const [rank, setRank] = useState("DESC");
  const [weight, setWeight] = useState(0);
  const [minimum, setMinimum] = useState("");
  const [maximum, setMaximum] = useState("");

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  useEffect(() => {
    console.log("QuickStrategyFactors id: ", factors);

    // add weight_pct
    const totalWeight = factors.reduce((acc, cur) => acc + cur.weight, 0);
    const newData = factors.map((row) => {
      return {
        ...row,
        weight_pct: totalWeight === 0 ? 0 : ((row.weight / totalWeight) * 100).toFixed(1),
      };
    });

    setData(newData);
    setOriginalData(newData);
  }, [factors]);

  const saveRow = (rowIndex) => {
    const payload = data[rowIndex];
    const rec = metrics.find((x) => x.value === payload.metric);
    payload.tableColumn = rec.tableColumn;
    console.log("saveRow", payload);

    axios
      .patch(`${WEBAPI}/strategy/factors/`, payload)
      .then((res) => {
        handleFactorsChange();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeRow = (rowIndex) => {
    console.log("removeRow", rowIndex);

    axios
      .delete(`${WEBAPI}/strategy/factors/${data[rowIndex].id}`)
      .then((res) => {
        console.log(res.data);
        handleFactorsChange();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        id: false,
      },
    },
    meta: {
      editedRows,
      setEditedRows,
      revertData: (rowIndex, revert) => {
        if (revert) {
          setData((old) => old.map((row, index) => (index === rowIndex ? originalData[rowIndex] : row)));
        } else {
          setOriginalData((old) => old.map((row, index) => (index === rowIndex ? data[rowIndex] : row)));
        }
      },
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      // addRow: () => {
      //   const newRow = {
      //     studentId: Math.floor(Math.random() * 10000),
      //     name: "",
      //     dateOfBirth: "",
      //     major: "vComputerScience",
      //     course: "vOOP",
      //     weight: 10,
      //     weightPct: 0.1,
      //   };
      //   const setFunc = (old) => [...old, newRow];
      //   setData(setFunc);
      //   setOriginalData(setFunc);
      // },
      saveRow: (rowIndex) => {
        saveRow(rowIndex);
      },
      removeRow: (rowIndex) => {
        removeRow(rowIndex);

        const setFilterFunc = (old) => old.filter((_row, index) => index !== rowIndex);
        setData(setFilterFunc);
        setOriginalData(setFilterFunc);
      },
      removeSelectedRows: (selectedRows) => {
        const setFilterFunc = (old) => old.filter((_row, index) => !selectedRows.includes(index));
        setData(setFilterFunc);
        setOriginalData(setFilterFunc);
      },
    },
  });

  // table.getRowModel().rows.map((row) => {
  //   console.log("row", row);
  //     row.getVisibleCells().map((cell) => {
  //       console.log("cell", cell);
  //     })
  // })


  const handleDeleteFactor = (id) => {
    console.log("handleDeleteFactor", id);

    // send delete request to server
    axios
      .delete(`${WEBAPI}/strategy/factors/${id}`)
      .then((res) => {
        console.log(res.data);
        handleFactorsChange();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddFactor = () => {
    // console.log('handleAddFactor')

    console.log("category", category);
    console.log("metric", metric);
    console.log("rank", rank);
    console.log("weight", weight);
    console.log("minimum", minimum);
    console.log("maximum", maximum);

    if (!category) {
      setSnackbarMessage("Please select a Category.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!metric) {
      setSnackbarMessage("Please select a Metric.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (weight === 0) {
      setSnackbarMessage("Weight cannot be zero.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    // validate minimum and maximum. values must be numeric or empty.
    if (minimum !== "" && isNaN(minimum)) {
      setSnackbarMessage("Minimum must be numeric.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (minimum.length > 30) {
      setSnackbarMessage("Minimum has too many digits.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (maximum !== "" && isNaN(maximum)) {
      setSnackbarMessage("Maximum must be numeric.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (maximum.length > 30) {
      setSnackbarMessage("Maximum has too many digits.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const rec = metrics.find((x) => x.value === metric);
    console.log("rec", rec);

    const payload = {
      strategyid: id,
      category: category,
      metric: metric,
      rank: rank,
      weight: weight,
      tableColumn: rec.tableColumn,
      minimum: minimum,
      maximum: maximum,
    };

    console.log("payload", payload);

    axios
      .post(`${WEBAPI}/strategy/factors`, payload)
      .then((res) => {
        console.log(res.data);
        handleFactorsChange();

        setCategory("");
        setMetric("");
        setRank("DESC");
        setWeight(0);
        setMinimum("");
        setMaximum("");
      })
      .catch((err) => {
        console.log(err);

        // setSnackbarMessage('Strategy name must be unique.');
        // setSnackbarSeverity('error');
        // setOpenSnackbar(true);
      });
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setMetric("");
  };

  const handleMetricChange = (event) => {
    setMetric(event.target.value);

    const rec = metrics.find((x) => x.value === event.target.value);
    setMinimum(rec.min);
    setMaximum(rec.max);
  };

  const handleRankChange = (event) => {
    setRank(event.target.value);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box display={"flex"} sx={{ mt: 1, mb: 2 }}>
        <Typography variant="h6">Factors</Typography>
      </Box>

      <Box display={"flex"} alignItems={"flex-start"}>
        <Box sx={{ ml: 0 }}>
          <FormControl sx={{ m: 0, width: 210 }} size="small">
            <InputLabel>Category</InputLabel>
            <Select value={category} onChange={handleCategoryChange} input={<OutlinedInput label="Category" disabled={isPublic} />}>
              {categories.map((cat) => (
                <MenuItem key={cat.value} value={cat.value}>
                  {cat.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <FormControl sx={{ m: 0, width: 350 }} size="small">
            <InputLabel>Metric</InputLabel>
            <Select value={metric || ""} onChange={handleMetricChange} input={<OutlinedInput label="Metric" disabled={isPublic} />}>
              {metrics
                .filter((m) => m.category === category && !data.some((el) => el.metric === m.value))
                .map((met) => (
                  <MenuItem key={met.value} value={met.value}>
                    {met.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <FormControl sx={{ m: 0, width: 150 }} size="small">
            <TextField label="Minimum" size="small" type="number" value={minimum} onChange={(e) => setMinimum(e.target.value.trim())} helperText="Leave blank to use defaults" disabled={isPublic} />
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <FormControl sx={{ m: 0, width: 150 }} size="small">
            <TextField label="Maximum" size="small" type="number" value={maximum} onChange={(e) => setMaximum(e.target.value.trim())} helperText="Leave blank to use defaults" disabled={isPublic} />
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <FormControl sx={{ m: 0, width: 170 }} size="small">
            <InputLabel>Rank</InputLabel>
            <Select value={rank} onChange={handleRankChange} input={<OutlinedInput label="Rank" disabled={isPublic} />}>
              <MenuItem key="RankDESC" value="DESC">
                <IconButton sx={{ color: "#2196f3" }} size="small">
                  <ArrowDownwardIcon />
                </IconButton>
                High to Low
              </MenuItem>

              <MenuItem key="RankASC" value="ASC">
                <IconButton sx={{ color: "#ff9100" }} size="small">
                  <ArrowUpwardIcon />
                </IconButton>
                Low to High
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <FormControl sx={{ m: 0, width: 100 }} size="small">
            <TextField label="Weight" size="small" type="number" value={weight} onChange={(e) => setWeight(e.target.value)} disabled={isPublic} />
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <Button variant="contained" size="small" color="success" onClick={handleAddFactor} sx={{ whiteSpace: "nowrap" }} disabled={isPublic}>
            Add Factor
          </Button>
        </Box>
      </Box>

      <Box display={"flex"} alignItems={"center"} sx={{ mt: 5 }}>
        <table id="factors">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  isPublic && header.id === "edit" ? null :
                  <th key={header.id}>{header.isPlaceholder ? null : <Typography sx={{ width: header.getSize(), ml: 1, fontSize: "12px" }}>{flexRender(header.column.columnDef.header, header.getContext())}</Typography>}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  isPublic && cell.column.id === "edit" ? null :
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>

      {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
    </Box>
  );
};

export default QuickStrategyFactors;
