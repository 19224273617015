import React, { useContext, useEffect, useState } from "react";
import { StrategyContext } from "./Context/StrategyContext";
import axios from "axios";
import { format, isValid, subMonths, subDays, min, set, max } from "date-fns";
import { OutlinedInput, MenuItem, FormControl, InputLabel, Select, TextField, Typography, Box, CircularProgress, Stack, Tooltip } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable, getGroupedRowModel } from "@tanstack/react-table";
// import QuickStrategyPerformanceToolbar from "./QuickStrategyPerformanceToolbar";
import Gradient from "javascript-color-gradient";
import CheckIcon from "@mui/icons-material/Check";
import { performanceColorCellFromValue } from "./utils/PerformanceColourScale";

const getCommonPinningStyles = (column) => {
  // console.log("column", column);

  const isPinned = column.getIsPinned();
  // console.log("isPinned", isPinned);

  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
  // console.log("isLastLeftPinnedColumn", isLastLeftPinnedColumn);

  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");
  // console.log("isFirstRightPinnedColumn", isFirstRightPinnedColumn);

  return {
    boxShadow: isLastLeftPinnedColumn ? "-4px 0 4px -4px gray inset" : isFirstRightPinnedColumn ? "4px 0 4px -4px gray inset" : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 1 : 1,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
};

const indicatorSize = 80;
const WEBAPI = process.env.REACT_APP_WEB_API;
const columnHelper = createColumnHelper();

const strategyStaticColumns = [
  columnHelper.group({
    id: "sticky",
    header: "Sticky Columns",
    align: "left",
    columns: [
      columnHelper.accessor("Ranking", {
        header: "Ranking",
        id: "ranking",
        cell: (info) => <Typography sx={{ textAlign: "right", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 80,
      }),
      columnHelper.accessor("Ticker", {
        header: "Ticker",
        id: "ticker1",
        cell: (info) => <Typography sx={{ marginLeft: "2px", textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 80,
      }),
    ],
  }),

  columnHelper.group({
    id: "general",
    header: "General",
    columns: [
      columnHelper.accessor("Company", {
        header: "Company",
        id: "company",
        cell: (info) => <Typography sx={{ marginLeft: "2px", textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 180,
      }),
      columnHelper.accessor("DomicileCountryFlag", {
        header: "Domiciled",
        id: "domicile_country_flag",
        cell: (props) => (
          <Typography sx={{ textAlign: "center" }}>
            <Tooltip title={props.row.original.DomicileCountry} placement="top">
              <img src={`https://flagcdn.com/w20/${props.cell.getValue()?.toLowerCase()}.png`} width="20" alt="flag" />
            </Tooltip>
          </Typography>
        ),
        size: 60,
      }),
      columnHelper.accessor("Sector", {
        header: "Sector",
        id: "sector",
        cell: (info) => <Typography sx={{ textAlign: "center", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 50,
      }),
      columnHelper.accessor("MarketCap", {
        header: "Market Cap ($M)",
        id: "marketcap",
        cell: (info) => <Typography sx={{ marginRight: "2px", textAlign: "right", fontSize: "12px" }}>{info.getValue()?.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</Typography>,
        size: 50,
      }),
      columnHelper.accessor(`InPortfolio`, {
        header: `In Portfolio`,
        id: `InPortfolio`,
        cell: (info) => <Box sx={{ textAlign: "center" }}>{info.getValue() ? <CheckIcon></CheckIcon> : ""}</Box>,
        size: 50,
        align: "center",
      }),
    ],
  }),
];

const QuickStrategyPerformance2 = () => {
  const { id, market, sectors, marketCapMin, marketCapMax, factors } = useContext(StrategyContext);
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = React.useState([]);
  const [grouping, setGrouping] = React.useState([]);
  const [maxRanking, setMaxRanking] = useState(1);
  const [minReturn, setMinReturn] = useState(0);
  const [maxReturn, setMaxReturn] = useState(0);
  const [rankMidpoint, setRankMidpoint] = useState(0);
  const [portfolioID, setPortfolioID] = useState("");
  const [portfolioList, setPortfolioList] = React.useState([]);
  const [isMidPointChanged, setIsMidPointChanged] = React.useState(false);
  const [columnPinning, setColumnPinning] = React.useState({ left: ["ranking", "ticker1"], right: [] });

  // console.log("strategyid", id);

  const rankingColorCellFromValue = (value) => {
    if (isNaN(value)) return "#aaaaaa";

    if (value < rankMidpoint) {
      let colorIndex = Math.floor(value / (rankMidpoint / 6)) + 1;

      const colorGradient = new Gradient().setColorGradient("#00ee00", "#ff9900").setMidpoint(6).getColor(colorIndex);

      return colorGradient;
    } else {
      let colorIndex = Math.floor((value - rankMidpoint) / ((maxRanking - rankMidpoint) / 6)) + 1;

      const colorGradient = new Gradient().setColorGradient("#ff9900", "#ff0000").setMidpoint(6).getColor(colorIndex);

      return colorGradient;
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      grouping,
      columnPinning,
    },
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onColumnPinningChange: setColumnPinning,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    // debugTable: true,
  });

  const handleDownload = async () => {
    console.log("handleDownload - Start");

    const periodList = [];

    const startDate = new Date();

    for (var x = 0; x < 20; x++) {
      let nextDate = subMonths(startDate, x * 3);
      while (!isValid(nextDate)) {
        subDays(nextDate, 1);
      }

      periodList.push({ value: String(x).padStart(2, "0"), label: format(nextDate, "MMM dd, yyyy") });
    }

    // console.log("periodList", periodList);
    // setPeriods(periodList);

    const exportData = [];
    const promises = [];

    periodList.forEach(async (period) => {
      // console.log(`period: ${period.label}`);

      const payload = {
        market: market,
        sectors: sectors.join(","),
        mcap_min: marketCapMin,
        mcap_max: marketCapMax,
        metrics: factors.map((factor) => factor.metric).join(","),
        ranks: factors.map((factor) => factor.rank).join(","),
        weights: factors.map((factor) => factor.weight).join(","),
        period: period.value,
        tableColumns: factors.map((factor) => factor.tableColumn).join(","),
        minimums: factors.map((factor) => factor.minimum).join(","),
        maximums: factors.map((factor) => factor.maximum).join(","),
      };

      promises.push(
        axios
          .post(`${WEBAPI}/quickstrategy/results`, payload)
          .then((res) => {
            // console.log('res.data', res.data)

            res.data.forEach((x) => {
              let newRecord = {
                ProductID: x.ProductID,
                Period: period.value,
                PeriodDate: new Date(period.label.substring(0, 12)),
                PeriodLabel: period.label,
                Ranking: x.Ranking,
                Ticker: x.Ticker,
                Company: x.Company,
                TotalReturn: x.TotalReturn,
                DomicileCountry: x.DomicileCountry,
                MarketCap: x.MarketCap,
                Sector: x.Sector,
              };

              // delete x.ProductID
              delete x.CompanyID;
              delete x.ExchangeID;
              delete x.Trades;
              delete x.Domiciled;
              delete x.Market;
              delete x.Region;
              delete x.Currency;
              delete x.TradeCountryCode;
              // delete x.DomicileCountryFlag
              delete x.PriceChg;
              delete x.DividendYield;
              delete x.Ranking;

              newRecord = { ...newRecord, ...x };

              exportData.push(newRecord);
            });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    });

    await Promise.all(promises);

    const sortedData = exportData.sort((a, b) => a.Period - b.Period || a.Ranking - b.Ranking);

    // console.log("sortedData", sortedData);

    // Lets format the data for the table
    const formattedData = [];

    let maxRank = 1;
    let minReturn = 0;
    let maxReturn = 0;
    sortedData.forEach((x) => {
      const found = formattedData.find((element) => element.ProductID === x.ProductID);
      // console.log("found", found);

      let rank = parseInt(x.Ranking);
      if (rank > maxRank) maxRank = rank;

      let totalReturn = parseFloat(x.TotalReturn);
      if (totalReturn < minReturn) minReturn = totalReturn;
      if (totalReturn > maxReturn) maxReturn = totalReturn;

      if (!found) {
        let newRecord = {
          ProductID: x.ProductID,
          // Period: x.Period,
          // PeriodDate: format(x.PeriodDate, "MMM dd, yyyy"),
          Ticker: x.Ticker,
          Company: x.Company,
          DomicileCountry: x.DomicileCountry,
          DomicileCountryFlag: x.DomicileCountryFlag,
          MarketCap: x.MarketCap,
          Sector: x.Sector,
          InPortfolio: false,
          CAGR: undefined,
          Ranking: x.Ranking,
        };

        newRecord["Rank_" + x.Period] = rank;
        newRecord["RankLabel_" + x.Period] = format(x.PeriodDate, "dd-MMM-yy");
        newRecord["Return_" + x.Period] = totalReturn;
        newRecord["ReturnLabel_" + x.Period] = format(x.PeriodDate, "dd-MMM-yy");

        formattedData.push(newRecord);
      } else {
        found["Rank_" + x.Period] = rank;
        found["RankLabel_" + x.Period] = format(x.PeriodDate, "dd-MMM-yy");
        found["Return_" + x.Period] = totalReturn;
        found["ReturnLabel_" + x.Period] = format(x.PeriodDate, "dd-MMM-yy");
      }
    });
    setMaxRanking(maxRank);
    setMinReturn(minReturn);
    setMaxReturn(maxReturn);
    setRankMidpoint(Math.floor(maxRank / 2));

    // console.log("minReturn, maxReturn", minReturn, maxReturn);
    // console.log("formattedData", formattedData);

    // find records that are missing a return in the last period
    formattedData.forEach((x) => {
      if (x.Return_00 === undefined) {
        x.Ranking = undefined;
      }
    });

    formattedData.forEach((x) => {
      // x.CAGR = (x.Return_01 / 100 + 1) * (x.Return_02 / 100 + 1) * (x.Return_03 / 100 + 1) * (x.Return_04 / 100 + 1) * (x.Return_05 / 100 + 1) * (x.Return_06 / 100 + 1) * (x.Return_07 / 100 + 1) * (x.Return_08 / 100 + 1) * (x.Return_09 / 100 + 1) * (x.Return_10 / 100 + 1) * (x.Return_11 / 100 + 1) * (x.Return_12 / 100 + 1) * (x.Return_13 / 100 + 1) * (x.Return_14 / 100 + 1) * (x.Return_15 / 100 + 1) * (x.Return_16 / 100 + 1) * (x.Return_17 / 100 + 1) * (x.Return_18 / 100 + 1) * (x.Return_19 / 100 + 1);
      // x.CAGR = (Math.pow(x.CAGR, 1 / 4.75) - 1);

      let gr = 1;

      gr *= isNaN(x.Return_01) ? 1 : x.Return_01 / 100 + 1;
      gr *= isNaN(x.Return_02) ? 1 : x.Return_02 / 100 + 1;
      gr *= isNaN(x.Return_03) ? 1 : x.Return_03 / 100 + 1;
      gr *= isNaN(x.Return_04) ? 1 : x.Return_04 / 100 + 1;
      gr *= isNaN(x.Return_05) ? 1 : x.Return_05 / 100 + 1;
      gr *= isNaN(x.Return_06) ? 1 : x.Return_06 / 100 + 1;
      gr *= isNaN(x.Return_07) ? 1 : x.Return_07 / 100 + 1;
      gr *= isNaN(x.Return_08) ? 1 : x.Return_08 / 100 + 1;
      gr *= isNaN(x.Return_09) ? 1 : x.Return_09 / 100 + 1;
      gr *= isNaN(x.Return_10) ? 1 : x.Return_10 / 100 + 1;
      gr *= isNaN(x.Return_11) ? 1 : x.Return_11 / 100 + 1;
      gr *= isNaN(x.Return_12) ? 1 : x.Return_12 / 100 + 1;
      gr *= isNaN(x.Return_13) ? 1 : x.Return_13 / 100 + 1;
      gr *= isNaN(x.Return_14) ? 1 : x.Return_14 / 100 + 1;
      gr *= isNaN(x.Return_15) ? 1 : x.Return_15 / 100 + 1;
      gr *= isNaN(x.Return_16) ? 1 : x.Return_16 / 100 + 1;
      gr *= isNaN(x.Return_17) ? 1 : x.Return_17 / 100 + 1;
      gr *= isNaN(x.Return_18) ? 1 : x.Return_18 / 100 + 1;
      gr *= isNaN(x.Return_19) ? 1 : x.Return_19 / 100 + 1;

      x.CAGR = Math.pow(gr, 1 / 4.75) - 1;
    });

    // console.log("formattedData", formattedData);

    const newColumns = [...strategyStaticColumns];

    const rankingColumns = [];
    for (let x = 20; x > 0; x--) {
      const newColumn = columnHelper.accessor(`Rank_${String(x - 1).padStart(2, "0")}`, {
        header: `${periodList[x - 1].label}`,
        id: `Rank_${String(x - 1).padStart(2, "0")}`,
        cell: (info) => <Typography sx={{ marginRight: "2px", textAlign: "right", fontSize: "12px" }}>{info.getValue()}</Typography>,
        size: 30,
        align: "right",
      });
      rankingColumns.push(newColumn);
    }
    const rankGroup = {
      header: "Rankings by Period",
      columns: rankingColumns,
    };
    newColumns.push(rankGroup);

    const emptyColumn = columnHelper.accessor(`Ticker`, {
      header: `Ticker`,
      id: `ticker2`,
      cell: (info) => <Typography sx={{ marginLeft: "2px", textAlign: "left", fontSize: "12px" }}>{info.getValue()}</Typography>,
      size: 50,
      align: "right",
    });
    newColumns.push(emptyColumn);

    const performanceColumns = [];
    for (let x = 20; x > 1; x--) {
      const newColumn = columnHelper.accessor(`Return_${String(x - 1).padStart(2, "0")}`, {
        header: `${periodList[x - 1].label}`,
        id: `Return_${String(x - 1).padStart(2, "0")}`,
        cell: (info) => <Typography sx={{ marginRight: "2px", textAlign: "right", fontSize: "12px" }}>{isNaN(info.getValue()) ? "" : (Math.round(info.getValue() * 10) / 10).toFixed(1) + "%"}</Typography>,
        size: 30,
        align: "right",
      });
      performanceColumns.push(newColumn);
    }

    const performanceGroup = {
      header: "Performance Beginning Date (Total 3 Month Return %)",
      columns: performanceColumns,
    };
    newColumns.push(performanceGroup);

    const CAGR = columnHelper.accessor(`CAGR`, {
      header: `CAGR`,
      id: `CAGR`,
      cell: (info) => <Typography sx={{ marginRight: "5px", textAlign: "right", fontSize: "12px" }}>{isNaN(info.getValue()) ? "" : (Math.round(info.getValue() * 10000) / 100).toFixed(1) + "%"}</Typography>,
      size: 40,
      align: "center",
    });
    newColumns.push(CAGR);

    setColumns(newColumns);

    console.log("setRawData", formattedData);
    setRawData(formattedData);
    getUserPortfolioStrategy();
    console.log("handleDownload - Finish");
  };

  // const setNewPortfolioID = (newPortfolioID) => {
  //   console.log("newPortfolioID", newPortfolioID);

  //   const payload = {
  //     strategyid: id,
  //     portfolioid: newPortfolioID,
  //     alertpoint: rankMidpoint,
  //   };

  //   // setPortfolioID(newPortfolioID);
  //   // updateUserPortfolioStrategy(payload);
  //   // highlightPortfolio(newPortfolioID)
  // };

  const highlightPortfolio = () => {
    console.log("highlightPortfolio", portfolioID);
    // console.log("rawData", rawData);

    if (portfolioID === "NONE") {
      const updatedData = rawData.map((x) => {
        x.InPortfolio = false;
        return x;
      });

      setData(updatedData);
    } else {
      axios
        .get(`${WEBAPI}/quickportfolio/results/${portfolioID}`)
        .then((res) => {
          // console.log("portfolio: ", res.data);

          const portfolioData = res.data;

          // iterate through the data and update the InPortfolio flag
          // console.log("rawData: ", rawData);
          const updatedData = rawData.map((x) => {
            const found = portfolioData.find((element) => {
              // console.log("find logic", element.product_id, x.ProductID, element.product_id === x.ProductID);
              return element.product_id === x.ProductID;
            });

            // console.log("found: ", found);
            if (found) {
              x.InPortfolio = true;
            } else {
              x.InPortfolio = false;
            }

            // console.log("x", x);
            return x;
          });

          setData(updatedData);
          // console.log("updatedData", updatedData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateUserPortfolioStrategy = (payload) => {
    axios
      .post(`${WEBAPI}/userportfoliostrategy`, payload)
      .then((res) => {
        // console.log("userportfoliostrategy: ", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const setNewMidPoint = (newMidPoint) => {
  //   // console.log("newMidPoint: ", newMidPoint);

  //   // setLoading(true);
  //   setRankMidpoint(newMidPoint);
  //   // setLoading(false);

  //   const payload = {
  //     strategyid: id,
  //     portfolioid: portfolioID,
  //     alertpoint: newMidPoint,
  //   };

  //   updateUserPortfolioStrategy(payload);
  // };

  const getUserPortfolioStrategy = () => {
    console.log("getUserPortfolioStrategy - Start");

    axios
      .get(`${WEBAPI}/userportfoliostrategy?strategyid=${id}`)
      .then((res) => {
        if (res.data) {
          // console.log("userportfoliostrategy: ", res.data);
          console.log("portfolioid: ", res.data.portfolioid);
          // console.log("alertpoint: ", res.data.alertpoint);

          const pID = res.data.portfolioid === "" ? "NONE" : res.data.portfolioid;

          setPortfolioID(pID);
          setRankMidpoint(res.data.alertpoint);
          // highlightPortfolio(res.data.portfolioid)
        } else {
          console.log("no userportfoliostrategy");
          setPortfolioID("NONE");

          // const mp = maxRanking / 2;
          // console.log("mp", mp);
          // setRankMidpoint(mp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPortfolioList = () => {
    console.log("getPortfolioList - Start");

    axios
      .get(`${WEBAPI}/quickportfolio`)
      .then((res) => {
        let noPortfolio = { id: "NONE", name: "None" };
        res.data.unshift(noPortfolio);
        setPortfolioList(res.data);
        console.log("portfolio list: ", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // setLoading(true);

    getPortfolioList();
    handleDownload();
    // getUserPortfolioStrategy();

    // setLoading(false);
  }, []);

  // useEffect(() => {
  //   console.log("rawData", rawData);
  //   getUserPortfolioStrategy();
  // }, [rawData]);

  useEffect(() => {
    // setLoading(true);
    // console.log("portfolioID useeffect", portfolioID);
    highlightPortfolio();
    // setLoading(false);
  }, [portfolioID]);

  const handlePortfolioChange = (value) => {
    // console.log("handlePortfolioChange", value);
    setPortfolioID(value);

    updateUserPortfolioStrategy({ strategyid: id, portfolioid: value, alertpoint: rankMidpoint });
  };

  const handleMidPointChange = (value) => {
    // console.log("handleMidPointChange", value);
    setRankMidpoint(value);

    updateUserPortfolioStrategy({ strategyid: id, portfolioid: portfolioID, alertpoint: value });
  };

  const handleUpdateRankingMidpoint = () => {
    // console.log("handleUpdateRankingMidpoint");
    // setIsMidPointChanged(false);
    // setNewMidPoint(rankMidpoint);
  };

  return loading ? (
    <Stack alignItems="center" sx={{ height: "100vh" }}>
      <CircularProgress color="secondary" size={indicatorSize} sx={{ position: "absolute", top: "50%", left: "50%", marginTop: `${-indicatorSize / 2}px`, marginLeft: `${-indicatorSize / 2}px` }} />
    </Stack>
  ) : (
    <Box>
      {/* <Box> */}
      {/* <QuickStrategyPerformanceToolbar initialPortfolioID={portfolioID} setNewPortfolioID={setNewPortfolioID} initialPoint={rankMidpoint} setNewMidPoint={setNewMidPoint} /> */}
      {/* </Box> */}

      <Stack direction="row">
        <Box sx={{ ml: 0 }}>
          <FormControl sx={{ m: 0, width: 220 }} size="small">
            <InputLabel>Compare with Portfolio</InputLabel>
            <Select value={portfolioID} onChange={(e) => handlePortfolioChange(e.target.value)} input={<OutlinedInput label="Compare with Portfolio" />}>
              {portfolioList.map((p) => (
                <MenuItem key={p.name} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ ml: 2 }}>
          <FormControl sx={{ m: 0, width: 100 }} size="small">
            <TextField
              label="Alert Point"
              value={rankMidpoint}
              onChange={(e) => handleMidPointChange(e.target.value)}
              min={1}
              max={maxRanking}
              size="small"
              type="number"
              InputProps={{
                inputProps: { min: 1, max: maxRanking },
              }}
            />
          </FormControl>
        </Box>

        {/* <Box sx={{ ml: 2 }}>
          <Button variant="contained" size="small" color="success" disabled={!isMidPointChanged} onClick={handleUpdateRankingMidpoint}>
            Update Alert Point
          </Button>
        </Box> */}
      </Stack>

      <Box>
        <Typography sx={{ mt: 2, fontSize: "14px" }}>{table.getRowModel().rows.length} Companies in Universe</Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
        {/* <TableContainer sx={{ mt: 2, maxHeight: "calc(100vh - 250px)", fontSize: '0.75rem' }}> */}
        <div className="strategy_results_container">
          <table id="xstrategy_results">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const { column } = header;

                    return (
                      <th key={header.id} colSpan={header.colSpan} className="pointer" style={{ ...getCommonPinningStyles(column) }}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <Typography sx={{ width: header.getSize(), ml: 0, fontSize: "12px", height: "100%" }}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{ asc: " 🔼", desc: " 🔽" }[header.column.getIsSorted()] ?? null}
                            </Typography>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} style={{ padding: "0", margin: "0" }}>
                  {row.getVisibleCells().map((cell) => {
                    const { column } = cell;

                    if (cell.column.id === "ranking") {
                      return (
                        <td key={cell.id} style={{ padding: "2", ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "ticker1") {
                      return (
                        <td key={cell.id} style={{ backgroundColor: row.original.InPortfolio ? "cornflowerblue" : null, color: row.original.InPortfolio ? "white" : null, borderStyle: row.original.InPortfolio ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "ticker2") {
                      return (
                        <td key={cell.id} style={{ backgroundColor: row.original.InPortfolio ? "cornflowerblue" : null, color: row.original.InPortfolio ? "white" : null, borderStyle: row.original.InPortfolio ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "company") {
                      return (
                        <td key={cell.id} style={{ backgroundColor: row.original.InPortfolio ? "cornflowerblue" : null, color: row.original.InPortfolio ? "white" : null, borderStyle: row.original.InPortfolio ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "domicile_country_flag") {
                      return (
                        <td key={cell.id} style={{ backgroundColor: row.original.InPortfolio ? "cornflowerblue" : null, color: row.original.InPortfolio ? "white" : null, borderStyle: row.original.InPortfolio ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "sector") {
                      return (
                        <td key={cell.id} style={{ backgroundColor: row.original.InPortfolio ? "cornflowerblue" : null, color: row.original.InPortfolio ? "white" : null, borderStyle: row.original.InPortfolio ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "marketcap") {
                      return (
                        <td key={cell.id} style={{ backgroundColor: row.original.InPortfolio ? "cornflowerblue" : null, color: row.original.InPortfolio ? "white" : null, borderStyle: row.original.InPortfolio ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id === "InPortfolio") {
                      return (
                        <td key={cell.id} style={{ padding: "0", margin: "0", color: cell.getValue() === true ? "white" : null, backgroundColor: cell.getValue() === true ? "cornflowerblue" : null, borderStyle: cell.getValue() === true ? "hidden" : null, ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id.startsWith("Rank_")) {
                      return (
                        <td key={cell.id} style={{ padding: "0", margin: "0", backgroundColor: rankingColorCellFromValue(cell.getValue()), borderStyle: "hidden", ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else if (cell.column.id.startsWith("Return_")) {
                      return (
                        <td key={cell.id} style={{ padding: "0", margin: "0", backgroundColor: performanceColorCellFromValue(cell.getValue()), borderStyle: "hidden", ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else {
                      return (
                        <td key={cell.id} style={{ padding: "0", margin: "0", ...getCommonPinningStyles(column) }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* </TableContainer> */}
      </Box>
    </Box>
  );
};

export default QuickStrategyPerformance2;
