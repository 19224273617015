import React, { useEffect } from 'react'
import axios from 'axios';
import { Paper, Typography } from '@mui/material'

const WEBAPI = process.env.REACT_APP_WEB_API;


const DBQuoteOfTheDay = () => {
    const [quote, setQuote] = React.useState('')
    const [author, setAuthor] = React.useState('')

    useEffect(() => {
        axios.get(`${WEBAPI}/dashboard/qod`)
            .then((res) => {
                setQuote(res.data.Quote);
                setAuthor(res.data.Author);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant="h5" mb={5} >Quote of the Day</Typography>
            <Typography variant="body1" display="inline">{quote} -- </Typography>
            <Typography display="inline" sx={{ fontStyle: 'italic', m: 1 }}>{author}</Typography>
        </Paper>
    )
}

export default DBQuoteOfTheDay
