import React, { useEffect, useContext, useState } from "react";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { StrategyContext } from "../Context/StrategyContext";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Decile, Universe, Top N",
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Stock Market Performance",
      },
    },
    x: {
      title: {
        display: true,
        text: "Quarter",
      },
    },
  },
};

const chartData = {
  labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
  datasets: [
    {
      label: "Decile 1",
      data: [],
      borderColor: "#377529",
      backgroundColor: "#377529",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 2",
      data: [],
      borderColor: "#009900",
      backgroundColor: "#009900",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 3",
      data: [],
      borderColor: "#81B634",
      backgroundColor: "#81B634",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 4",
      data: [],
      borderColor: "#ACB931",
      backgroundColor: "#ACB931",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 5",
      data: [],
      borderColor: "#E6E115",
      backgroundColor: "#E6E115",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 6",
      data: [],
      borderColor: "#FFD966",
      backgroundColor: "#FFD966",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 7",
      data: [],
      borderColor: "#F6B070",
      backgroundColor: "#F6B070",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 8",
      data: [],
      borderColor: "#F56455",
      backgroundColor: "#F56455",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 9",
      data: [],
      borderColor: "#FF0000",
      backgroundColor: "#FF0000",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Decile 10",
      data: [],
      borderColor: "#BE0000",
      backgroundColor: "#BE0000",
      pointStyle: false,
      datalabels: {
        display: false
      }
    },
    {
      label: "Universe",
      data: [],
      borderColor: "#000000",
      backgroundColor: "#000000",
      pointStyle: "rect",
      pointRadius: 4,
      pointHoverRadius: 6,
      datalabels: {
        display: false
      }
    },
    {
      label: "TopN",
      data: [],
      borderColor: "#00B0F0",
      backgroundColor: "#00B0F0",
      pointStyle: "circle",
      pointRadius: 4,
      pointHoverRadius: 6,
      datalabels: {
        display: false
      }
    },
  ],
};

const BackTestResultsChart = () => {
  const { backTestResults } = useContext(StrategyContext);
  const [backTestResultsChartData, setBackTestResultsChartData] = useState(null);

  useEffect(() => {
    console.log("ResultsChart: ", backTestResults);
    if (backTestResults.length === 0) return;

    // console.log(backTestResults[11].Group);
    const topN = backTestResults[11].Group;
    chartData.datasets[11].label = topN;
    options.plugins.title.text = options.plugins.title.text = `Decile, Universe, ${topN}`;

    chartData.datasets[0].data = [backTestResults[0][`C19`]?.toFixed(2), backTestResults[0][`C18`]?.toFixed(2), backTestResults[0][`C17`]?.toFixed(2), backTestResults[0][`C16`]?.toFixed(2), backTestResults[0][`C15`]?.toFixed(2), backTestResults[0][`C14`]?.toFixed(2), backTestResults[0][`C13`]?.toFixed(2), backTestResults[0][`C12`]?.toFixed(2), backTestResults[0][`C11`]?.toFixed(2), backTestResults[0][`C10`]?.toFixed(2), backTestResults[0][`C09`]?.toFixed(2), backTestResults[0][`C08`]?.toFixed(2), backTestResults[0][`C07`]?.toFixed(2), backTestResults[0][`C06`]?.toFixed(2), backTestResults[0][`C05`]?.toFixed(2), backTestResults[0][`C04`]?.toFixed(2), backTestResults[0][`C03`]?.toFixed(2), backTestResults[0][`C02`]?.toFixed(2), backTestResults[0][`C01`]?.toFixed(2), backTestResults[0][`C00`]?.toFixed(2)];
    chartData.datasets[1].data = [backTestResults[1][`C19`]?.toFixed(2), backTestResults[1][`C18`]?.toFixed(2), backTestResults[1][`C17`]?.toFixed(2), backTestResults[1][`C16`]?.toFixed(2), backTestResults[1][`C15`]?.toFixed(2), backTestResults[1][`C14`]?.toFixed(2), backTestResults[1][`C13`]?.toFixed(2), backTestResults[1][`C12`]?.toFixed(2), backTestResults[1][`C11`]?.toFixed(2), backTestResults[1][`C10`]?.toFixed(2), backTestResults[1][`C09`]?.toFixed(2), backTestResults[1][`C08`]?.toFixed(2), backTestResults[1][`C07`]?.toFixed(2), backTestResults[1][`C06`]?.toFixed(2), backTestResults[1][`C05`]?.toFixed(2), backTestResults[1][`C04`]?.toFixed(2), backTestResults[1][`C03`]?.toFixed(2), backTestResults[1][`C02`]?.toFixed(2), backTestResults[1][`C01`]?.toFixed(2), backTestResults[1][`C00`]?.toFixed(2)];
    chartData.datasets[2].data = [backTestResults[2][`C19`]?.toFixed(2), backTestResults[2][`C18`]?.toFixed(2), backTestResults[2][`C17`]?.toFixed(2), backTestResults[2][`C16`]?.toFixed(2), backTestResults[2][`C15`]?.toFixed(2), backTestResults[2][`C14`]?.toFixed(2), backTestResults[2][`C13`]?.toFixed(2), backTestResults[2][`C12`]?.toFixed(2), backTestResults[2][`C11`]?.toFixed(2), backTestResults[2][`C10`]?.toFixed(2), backTestResults[2][`C09`]?.toFixed(2), backTestResults[2][`C08`]?.toFixed(2), backTestResults[2][`C07`]?.toFixed(2), backTestResults[2][`C06`]?.toFixed(2), backTestResults[2][`C05`]?.toFixed(2), backTestResults[2][`C04`]?.toFixed(2), backTestResults[2][`C03`]?.toFixed(2), backTestResults[2][`C02`]?.toFixed(2), backTestResults[2][`C01`]?.toFixed(2), backTestResults[2][`C00`]?.toFixed(2)];
    chartData.datasets[3].data = [backTestResults[3][`C19`]?.toFixed(2), backTestResults[3][`C18`]?.toFixed(2), backTestResults[3][`C17`]?.toFixed(2), backTestResults[3][`C16`]?.toFixed(2), backTestResults[3][`C15`]?.toFixed(2), backTestResults[3][`C14`]?.toFixed(2), backTestResults[3][`C13`]?.toFixed(2), backTestResults[3][`C12`]?.toFixed(2), backTestResults[3][`C11`]?.toFixed(2), backTestResults[3][`C10`]?.toFixed(2), backTestResults[3][`C09`]?.toFixed(2), backTestResults[3][`C08`]?.toFixed(2), backTestResults[3][`C07`]?.toFixed(2), backTestResults[3][`C06`]?.toFixed(2), backTestResults[3][`C05`]?.toFixed(2), backTestResults[3][`C04`]?.toFixed(2), backTestResults[3][`C03`]?.toFixed(2), backTestResults[3][`C02`]?.toFixed(2), backTestResults[3][`C01`]?.toFixed(2), backTestResults[3][`C00`]?.toFixed(2)];
    chartData.datasets[4].data = [backTestResults[4][`C19`]?.toFixed(2), backTestResults[4][`C18`]?.toFixed(2), backTestResults[4][`C17`]?.toFixed(2), backTestResults[4][`C16`]?.toFixed(2), backTestResults[4][`C15`]?.toFixed(2), backTestResults[4][`C14`]?.toFixed(2), backTestResults[4][`C13`]?.toFixed(2), backTestResults[4][`C12`]?.toFixed(2), backTestResults[4][`C11`]?.toFixed(2), backTestResults[4][`C10`]?.toFixed(2), backTestResults[4][`C09`]?.toFixed(2), backTestResults[4][`C08`]?.toFixed(2), backTestResults[4][`C07`]?.toFixed(2), backTestResults[4][`C06`]?.toFixed(2), backTestResults[4][`C05`]?.toFixed(2), backTestResults[4][`C04`]?.toFixed(2), backTestResults[4][`C03`]?.toFixed(2), backTestResults[4][`C02`]?.toFixed(2), backTestResults[4][`C01`]?.toFixed(2), backTestResults[4][`C00`]?.toFixed(2)];
    chartData.datasets[5].data = [backTestResults[5][`C19`]?.toFixed(2), backTestResults[5][`C18`]?.toFixed(2), backTestResults[5][`C17`]?.toFixed(2), backTestResults[5][`C16`]?.toFixed(2), backTestResults[5][`C15`]?.toFixed(2), backTestResults[5][`C14`]?.toFixed(2), backTestResults[5][`C13`]?.toFixed(2), backTestResults[5][`C12`]?.toFixed(2), backTestResults[5][`C11`]?.toFixed(2), backTestResults[5][`C10`]?.toFixed(2), backTestResults[5][`C09`]?.toFixed(2), backTestResults[5][`C08`]?.toFixed(2), backTestResults[5][`C07`]?.toFixed(2), backTestResults[5][`C06`]?.toFixed(2), backTestResults[5][`C05`]?.toFixed(2), backTestResults[5][`C04`]?.toFixed(2), backTestResults[5][`C03`]?.toFixed(2), backTestResults[5][`C02`]?.toFixed(2), backTestResults[5][`C01`]?.toFixed(2), backTestResults[5][`C00`]?.toFixed(2)];
    chartData.datasets[6].data = [backTestResults[6][`C19`]?.toFixed(2), backTestResults[6][`C18`]?.toFixed(2), backTestResults[6][`C17`]?.toFixed(2), backTestResults[6][`C16`]?.toFixed(2), backTestResults[6][`C15`]?.toFixed(2), backTestResults[6][`C14`]?.toFixed(2), backTestResults[6][`C13`]?.toFixed(2), backTestResults[6][`C12`]?.toFixed(2), backTestResults[6][`C11`]?.toFixed(2), backTestResults[6][`C10`]?.toFixed(2), backTestResults[6][`C09`]?.toFixed(2), backTestResults[6][`C08`]?.toFixed(2), backTestResults[6][`C07`]?.toFixed(2), backTestResults[6][`C06`]?.toFixed(2), backTestResults[6][`C05`]?.toFixed(2), backTestResults[6][`C04`]?.toFixed(2), backTestResults[6][`C03`]?.toFixed(2), backTestResults[6][`C02`]?.toFixed(2), backTestResults[6][`C01`]?.toFixed(2), backTestResults[6][`C00`]?.toFixed(2)];
    chartData.datasets[7].data = [backTestResults[7][`C19`]?.toFixed(2), backTestResults[7][`C18`]?.toFixed(2), backTestResults[7][`C17`]?.toFixed(2), backTestResults[7][`C16`]?.toFixed(2), backTestResults[7][`C15`]?.toFixed(2), backTestResults[7][`C14`]?.toFixed(2), backTestResults[7][`C13`]?.toFixed(2), backTestResults[7][`C12`]?.toFixed(2), backTestResults[7][`C11`]?.toFixed(2), backTestResults[7][`C10`]?.toFixed(2), backTestResults[7][`C09`]?.toFixed(2), backTestResults[7][`C08`]?.toFixed(2), backTestResults[7][`C07`]?.toFixed(2), backTestResults[7][`C06`]?.toFixed(2), backTestResults[7][`C05`]?.toFixed(2), backTestResults[7][`C04`]?.toFixed(2), backTestResults[7][`C03`]?.toFixed(2), backTestResults[7][`C02`]?.toFixed(2), backTestResults[7][`C01`]?.toFixed(2), backTestResults[7][`C00`]?.toFixed(2)];
    chartData.datasets[8].data = [backTestResults[8][`C19`]?.toFixed(2), backTestResults[8][`C18`]?.toFixed(2), backTestResults[8][`C17`]?.toFixed(2), backTestResults[8][`C16`]?.toFixed(2), backTestResults[8][`C15`]?.toFixed(2), backTestResults[8][`C14`]?.toFixed(2), backTestResults[8][`C13`]?.toFixed(2), backTestResults[8][`C12`]?.toFixed(2), backTestResults[8][`C11`]?.toFixed(2), backTestResults[8][`C10`]?.toFixed(2), backTestResults[8][`C09`]?.toFixed(2), backTestResults[8][`C08`]?.toFixed(2), backTestResults[8][`C07`]?.toFixed(2), backTestResults[8][`C06`]?.toFixed(2), backTestResults[8][`C05`]?.toFixed(2), backTestResults[8][`C04`]?.toFixed(2), backTestResults[8][`C03`]?.toFixed(2), backTestResults[8][`C02`]?.toFixed(2), backTestResults[8][`C01`]?.toFixed(2), backTestResults[8][`C00`]?.toFixed(2)];
    chartData.datasets[9].data = [backTestResults[9][`C19`]?.toFixed(2), backTestResults[9][`C18`]?.toFixed(2), backTestResults[9][`C17`]?.toFixed(2), backTestResults[9][`C16`]?.toFixed(2), backTestResults[9][`C15`]?.toFixed(2), backTestResults[9][`C14`]?.toFixed(2), backTestResults[9][`C13`]?.toFixed(2), backTestResults[9][`C12`]?.toFixed(2), backTestResults[9][`C11`]?.toFixed(2), backTestResults[9][`C10`]?.toFixed(2), backTestResults[9][`C09`]?.toFixed(2), backTestResults[9][`C08`]?.toFixed(2), backTestResults[9][`C07`]?.toFixed(2), backTestResults[9][`C06`]?.toFixed(2), backTestResults[9][`C05`]?.toFixed(2), backTestResults[9][`C04`]?.toFixed(2), backTestResults[9][`C03`]?.toFixed(2), backTestResults[9][`C02`]?.toFixed(2), backTestResults[9][`C01`]?.toFixed(2), backTestResults[9][`C00`]?.toFixed(2)];
    chartData.datasets[10].data = [backTestResults[10][`C19`]?.toFixed(2), backTestResults[10][`C18`]?.toFixed(2), backTestResults[10][`C17`]?.toFixed(2), backTestResults[10][`C16`]?.toFixed(2), backTestResults[10][`C15`]?.toFixed(2), backTestResults[10][`C14`]?.toFixed(2), backTestResults[10][`C13`]?.toFixed(2), backTestResults[10][`C12`]?.toFixed(2), backTestResults[10][`C11`]?.toFixed(2), backTestResults[10][`C10`]?.toFixed(2), backTestResults[10][`C09`]?.toFixed(2), backTestResults[10][`C08`]?.toFixed(2), backTestResults[10][`C07`]?.toFixed(2), backTestResults[10][`C06`]?.toFixed(2), backTestResults[10][`C05`]?.toFixed(2), backTestResults[10][`C04`]?.toFixed(2), backTestResults[10][`C03`]?.toFixed(2), backTestResults[10][`C02`]?.toFixed(2), backTestResults[10][`C01`]?.toFixed(2), backTestResults[10][`C00`]?.toFixed(2)];
    chartData.datasets[11].data = [backTestResults[11][`C19`]?.toFixed(2), backTestResults[11][`C18`]?.toFixed(2), backTestResults[11][`C17`]?.toFixed(2), backTestResults[11][`C16`]?.toFixed(2), backTestResults[11][`C15`]?.toFixed(2), backTestResults[11][`C14`]?.toFixed(2), backTestResults[11][`C13`]?.toFixed(2), backTestResults[11][`C12`]?.toFixed(2), backTestResults[11][`C11`]?.toFixed(2), backTestResults[11][`C10`]?.toFixed(2), backTestResults[11][`C09`]?.toFixed(2), backTestResults[11][`C08`]?.toFixed(2), backTestResults[11][`C07`]?.toFixed(2), backTestResults[11][`C06`]?.toFixed(2), backTestResults[11][`C05`]?.toFixed(2), backTestResults[11][`C04`]?.toFixed(2), backTestResults[11][`C03`]?.toFixed(2), backTestResults[11][`C02`]?.toFixed(2), backTestResults[11][`C01`]?.toFixed(2), backTestResults[11][`C00`]?.toFixed(2)];

    setBackTestResultsChartData(chartData);
  }, [backTestResults]);

  return backTestResults.length === 0 || backTestResultsChartData == null ? null : <Line options={options} data={backTestResultsChartData} />;
};

export default BackTestResultsChart;
