import React, { useContext } from "react";

import Box from "@mui/material/Box";
import { FormControl, TextField, Typography } from "@mui/material";
import { StrategyContext } from "./Context/StrategyContext";

const QuickStrategyTopN = () => {
  const { topN, handleTopNChange, isPublic } = useContext(StrategyContext);

  const handleTopNChangeEvent = (event) => {
    const value = event.target.value;
    if (value === "" || value < 0 || value > 200) return;

    handleTopNChange(value);
  };

  return (
    <Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="h6" display="inline">
          Add Top Ranked Companies to Back Test
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl sx={{ m: 0, width: 100 }} size="small">
          <TextField label="Top" size="small" type="number" value={topN} onChange={(e) => handleTopNChangeEvent(e)} disabled={isPublic}/>
        </FormControl>
      </Box>
    </Box>
  );
};

export default QuickStrategyTopN;
