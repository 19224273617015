export const marketNamesWithPINX = [
    { label: 'Canada', value: 'CAN' },
    { label: 'USA', value: 'USA' },
    { label: 'PINX', value: 'PNX' },
];

export const marketNamesWithoutPINX = [
    { label: 'Canada', value: 'CAN' },
    { label: 'USA', value: 'USA' },
];

export const regionNames = [
    { label: 'Canada', value: 'CAN' },
    { label: 'USA', value: 'USA' },
    { label: 'Africa and Middle East', value: 'AME' },
    { label: 'Australasia', value: 'AUA' },
    { label: 'Central and South America', value: 'CSA' },
    { label: 'Europe', value: 'EUR' },
    { label: 'Far East', value: 'FAR' },
    { label: 'UK', value: 'UK' },
    { label: 'Offshore', value: 'OFF' },
    { label: 'Other', value: 'OTH' },
];

export const sectorNames = [
    { label: 'Consumer Discretionary - CD', value: 'CD' },
    { label: 'Consumer Staples - CS', value: 'CS' },
    { label: 'Communications - TE', value: 'TE' },
    { label: 'Energy - EN', value: 'EN' },
    { label: 'Financials - FI', value: 'FI' },
    { label: 'Financial Tech - FT', value: 'FT' },
    { label: 'Health Care - HC', value: 'HC' },
    { label: 'Industrials - IN', value: 'IN' },
    { label: 'Information Technology - IT', value: 'IT' },
    { label: 'Materials - MA', value: 'MA' },
    { label: 'Real Estate - RE', value: 'RE' },
    { label: 'Utilities - UT', value: 'UT' }
];

export const sectorNamesExcludeFinancialsAndRealEstate = [
    { label: 'Consumer Discretionary - CD', value: 'CD' },
    { label: 'Consumer Staples - CS', value: 'CS' },
    { label: 'Communications - TE', value: 'TE' },
    { label: 'Energy - EN', value: 'EN' },
    { label: 'Financial Tech - FT', value: 'FT' },
    { label: 'Health Care - HC', value: 'HC' },
    { label: 'Industrials - IN', value: 'IN' },
    { label: 'Information Technology - IT', value: 'IT' },
    { label: 'Materials - MA', value: 'MA' },
    { label: 'Utilities - UT', value: 'UT' }
];

export const sectorNamesWithoutFinancialsAndRealEstate = [
    { label: 'Consumer Discretionary - CD', value: 'CD' },
    { label: 'Consumer Staples - CS', value: 'CS' },
    { label: 'Communications - TE', value: 'TE' },
    { label: 'Energy - EN', value: 'EN' },
    { label: 'Health Care - HC', value: 'HC' },
    { label: 'Industrials - IN', value: 'IN' },
    { label: 'Information Technology - IT', value: 'IT' },
    { label: 'Materials - MA', value: 'MA' },
    { label: 'Utilities - UT', value: 'UT' }
];

export const marketCapValues = [
    { mCapMin: true, mCapMax: false, label: 'Min', value: 0 },
    { mCapMin: true, mCapMax: true, label: '100 Million', value: 100 },
    { mCapMin: true, mCapMax: true, label: '250 Million', value: 250 },
    { mCapMin: true, mCapMax: true, label: '500 Million', value: 500 },
    { mCapMin: true, mCapMax: true, label: '1 Billion', value: 1000 },
    { mCapMin: true, mCapMax: true, label: '2 Billion', value: 2000 },
    { mCapMin: true, mCapMax: true, label: '5 Billion', value: 5000 },
    { mCapMin: true, mCapMax: true, label: '10 Billion', value: 10000 },
    { mCapMin: true, mCapMax: true, label: '20 Billion', value: 20000 },
    { mCapMin: true, mCapMax: true, label: '50 Billion', value: 50000 },
    { mCapMin: true, mCapMax: true, label: '100 Billion', value: 100000 },
    { mCapMin: true, mCapMax: true, label: '200 Billion', value: 200000 },
    { mCapMin: true, mCapMax: true, label: '500 Billion', value: 500000 },
    { mCapMin: true, mCapMax: true, label: '1 Trillion', value: 1000000 },
    { mCapMin: true, mCapMax: true, label: '2 Trillion', value: 2000000 },
    { mCapMin: true, mCapMax: true, label: '5 Trillion', value: 5000000 },
    { mCapMin: false, mCapMax: true, label: 'Max', value: 10000000 }
];

export const MARKET_CAP_MIN = 0;
export const MARKET_CAP_MAX = 10000000;

export const categories = [
    { label: 'Return on Capital', value: 'ROC' },
    { label: 'Dividends', value: 'DIV' },
    { label: 'Balance Sheet', value: 'BS' },
    { label: 'Income Statement', value: 'IS' },
    { label: 'Margins', value: 'MAR' },
    { label: 'Capital', value: 'CAP' },
    { label: 'QV Model', value: 'QVM' },
    { label: 'Traditional Multiples', value: 'TM' },
];

export const metrics = [
    { value: 'CFIRREX_ANQ', category: 'ROC', label: 'CFIRR Ex % (ANQ)', tableColumn: 'QSCFIRREX.ANQ', min: '', max: '' },
    { value: 'CFIRREX_TTM', category: 'ROC', label: 'CFIRR Ex % (TFQ)', tableColumn: 'QSCFIRREX.TTM', min: '', max: '' },
    { value: 'CFIRREXCHG_ANQvPQ', category: 'ROC', label: 'CFIRR Ex Chg % (ANQ vs PQ)', tableColumn: 'QSCFIRREXCHG.ANQvPQ', min: '', max: '' },
    { value: 'CFIRREXCHG_ANQvPY', category: 'ROC', label: 'CFIRR Ex Chg % (ANQ vs SQPY)', tableColumn: 'QSCFIRREXCHG.ANQvPY', min: '', max: '' },
    { value: 'CFIRREXCHG_TTMvPQ', category: 'ROC', label: 'CFIRR Ex Chg % (TFQ vs PQ)', tableColumn: 'QSCFIRREXCHG.TTMvPQ', min: '', max: '' },
    { value: 'CFIRREXCHG_TTMvPY', category: 'ROC', label: 'CFIRR Ex Chg % (TFQ vs SQPY)', tableColumn: 'QSCFIRREXCHG.TTMvPY', min: '', max: '' },
    { value: 'CFIRR_ANQ', category: 'ROC', label: 'CFIRR In % (ANQ)', tableColumn: 'QSCFIRR.ANQ', min: '', max: '' },
    { value: 'CFIRR_TTM', category: 'ROC', label: 'CFIRR In % (TFQ)', tableColumn: 'QSCFIRR.TTM', min: '', max: '' },
    { value: 'CFIRRCHG_ANQvPQ', category: 'ROC', label: 'CFIRR In Chg % (ANQ vs PQ)', tableColumn: 'QSCFIRRCHG.ANQvPQ', min: '', max: '' },
    { value: 'CFIRRCHG_ANQvPY', category: 'ROC', label: 'CFIRR In Chg % (ANQ vs SQPY)', tableColumn: 'QSCFIRRCHG.ANQvPY', min: '', max: '' },
    { value: 'CFIRRCHG_TTMvPQ', category: 'ROC', label: 'CFIRR In Chg % (TFQ vs PQ)', tableColumn: 'QSCFIRRCHG.TTMvPQ', min: '', max: '' },
    { value: 'CFIRRCHG_TTMvPY', category: 'ROC', label: 'CFIRR In Chg % (TFQ vs SQPY)', tableColumn: 'QSCFIRRCHG.TTMvPY', min: '', max: '' },
    { value: 'ROE_ANQ', category: 'ROC', label: 'ROE % (ANQ)', tableColumn: 'QSROE.ANQ', min: '', max: '' },
    { value: 'ROE_TTM', category: 'ROC', label: 'ROE % (TFQ)', tableColumn: 'QSROE.TTM', min: '', max: '' },
    { value: 'ROECHG_ANQvPQ', category: 'ROC', label: 'ROE Chg % (ANQ vs PQ)', tableColumn: 'QSROECHG.ANQvPQ', min: '', max: '' },
    { value: 'ROECHG_ANQvPY', category: 'ROC', label: 'ROE Chg % (ANQ vs SQPY)', tableColumn: 'QSROECHG.ANQvPY', min: '', max: '' },
    { value: 'ROECHG_TTMvPQ', category: 'ROC', label: 'ROE Chg % (TFQ vs PQ)', tableColumn: 'QSROECHG.TTMvPQ', min: '', max: '' },
    { value: 'ROECHG_TTMvPY', category: 'ROC', label: 'ROE Chg % (TFQ vs SQPY)', tableColumn: 'QSROECHG.TTMvPY', min: '', max: '' },
    { value: 'ROA_ANQ', category: 'ROC', label: 'ROA % (ANQ)', tableColumn: 'QSROA.ANQ', min: '', max: '' },
    { value: 'ROA_TTM', category: 'ROC', label: 'ROA % (TFQ)', tableColumn: 'QSROA.TTM', min: '', max: '' },
    { value: 'ROACHG_ANQvPQ', category: 'ROC', label: 'ROA Chg % (ANQ vs PQ)', tableColumn: 'QSROACHG.ANQvPQ', min: '', max: '' },
    { value: 'ROACHG_ANQvPY', category: 'ROC', label: 'ROA Chg % (ANQ vs SQPY)', tableColumn: 'QSROACHG.ANQvPY', min: '', max: '' },
    { value: 'ROACHG_TTMvPQ', category: 'ROC', label: 'ROA Chg % (TFQ vs PQ)', tableColumn: 'QSROACHG.TTMvPQ', min: '', max: '' },
    { value: 'ROACHG_TTMvPY', category: 'ROC', label: 'ROA Chg % (TFQ vs SQPY)', tableColumn: 'QSROACHG.TTMvPY', min: '', max: '' },
    { value: 'ROIC_ANQ', category: 'ROC', label: 'ROIC % (ANQ)', tableColumn: 'QSROIC.ANQ', min: '', max: '' },
    { value: 'ROIC_TTM', category: 'ROC', label: 'ROIC % (TFQ)', tableColumn: 'QSROIC.TTM', min: '', max: '' },
    { value: 'ROICCHG_ANQvPQ', category: 'ROC', label: 'ROIC Chg % (ANQ vs PQ)', tableColumn: 'QSROICCHG.ANQvPQ', min: '', max: '' },
    { value: 'ROICCHG_ANQvPY', category: 'ROC', label: 'ROIC Chg % (ANQ vs SQPY)', tableColumn: 'QSROICCHG.ANQvPY', min: '', max: '' },
    { value: 'ROICCHG_TTMvPQ', category: 'ROC', label: 'ROIC Chg % (TFQ vs PQ)', tableColumn: 'QSROICCHG.TTMvPQ', min: '', max: '' },
    { value: 'ROICCHG_TTMvPY', category: 'ROC', label: 'ROIC Chg % (TFQ vs SQPY)', tableColumn: 'QSROICCHG.TTMvPY', min: '', max: '' },
    { value: 'DIVIDENDPAYOUTRATIO_ANQ', category: 'DIV', label: 'Div Pay Ratio % (ANQ)', tableColumn: 'QSDIVIDENDPAYOUTRATIO.ANQ', min: '5', max: '' },
    { value: 'DIVIDENDPAYOUTRATIO_TTM', category: 'DIV', label: 'Div Pay Ratio % (TFQ)', tableColumn: 'QSDIVIDENDPAYOUTRATIO.TTM', min: '5', max: '' },
    { value: 'DIVIDENDYIELD_ANQ', category: 'DIV', label: 'Div Yield % (ANQ)', tableColumn: 'QSDIVIDENDYIELD.ANQ', min: '1.2', max: '10' },
    { value: 'DIVIDENDYIELD_TTM', category: 'DIV', label: 'Div Yield % (TFQ)', tableColumn: 'QSDIVIDENDYIELD.TTM', min: '1.2', max: '10' },
    { value: 'DIVIDENDYIELDCHG_ANQvPQ', category: 'DIV', label: 'Div Growth % (ANQ vs PQ)', tableColumn: 'QSDIVIDENDYIELDCHG.ANQvPQ', min: '', max: '' },
    { value: 'DIVIDENDYIELDCHG_ANQvPY', category: 'DIV', label: 'Div Growth % (ANQ vs SQPY)', tableColumn: 'QSDIVIDENDYIELDCHG.ANQvPY', min: '', max: '' },
    { value: 'DIVIDENDYIELDCHG_TTMvPQ', category: 'DIV', label: 'Div Growth % (TFQ vs PQ)', tableColumn: 'QSDIVIDENDYIELDCHG.TTMvPQ', min: '', max: '' },
    { value: 'DIVIDENDYIELDCHG_TTMvPY', category: 'DIV', label: 'Div Growth % (TFQ vs SQPY)', tableColumn: 'QSDIVIDENDYIELDCHG.TTMvPY', min: '', max: '' },
    { value: 'CURRENTRATIO_ANQ', category: 'BS', label: 'Current Ratio (LFQ)', tableColumn: 'QSCURRENTRATIO.ANQ', min: '', max: '' },
    { value: 'QUICKRATIO_ANQ', category: 'BS', label: 'Quick Ratio (LFQ)', tableColumn: 'QSQUICKRATIO.ANQ', min: '', max: '' },
    { value: 'DEBTTOBOOK_ANQ', category: 'BS', label: 'Debt to Book Equity (LFQ)', tableColumn: 'QSDEBTTOBOOK.ANQ', min: '', max: '' },
    { value: 'DEBTTOMARKET_ANQ', category: 'BS', label: 'Debt to Market Equity (LFQ)', tableColumn: 'QSDEBTTOMARKET.ANQ', min: '', max: '' },
    { value: 'INTERESTCOVERAGE_ANQ', category: 'BS', label: 'Interest Coverage (ANQ)', tableColumn: 'QSINTERESTCOVERAGE.ANQ', min: '', max: '' },
    { value: 'INTERESTCOVERAGE_TTM', category: 'BS', label: 'Interest Coverage (TFQ)', tableColumn: 'QSINTERESTCOVERAGE.TTM', min: '', max: '' },
    { value: 'REVENUE_ANQ', category: 'IS', label: 'Revenue $M (ANQ)', tableColumn: 'QSREVENUE.ANQ', min: '', max: '' },
    { value: 'REVENUE_TTM', category: 'IS', label: 'Revenue $M (TFQ)', tableColumn: 'QSREVENUE.TTM', min: '', max: '' },
    { value: 'REVENUECHG_ANQvPQ', category: 'IS', label: 'Rev Grw % (ANQ vs PQ)', tableColumn: 'QSREVENUECHG.ANQvPQ', min: '', max: '' },
    { value: 'REVENUECHG_ANQvPY', category: 'IS', label: 'Rev Grw % (ANQ vs SQPY)', tableColumn: 'QSREVENUECHG.ANQvPY', min: '', max: '' },
    { value: 'REVENUECHG_TTMvPQ', category: 'IS', label: 'Rev Grw % (TFQ vs PQ)', tableColumn: 'QSREVENUECHG.TTMvPQ', min: '', max: '' },
    { value: 'REVENUECHG_TTMvPY', category: 'IS', label: 'Rev Grw % (TFQ vs SQPY)', tableColumn: 'QSREVENUECHG.TTMvPY', min: '', max: '' },
    { value: 'BASICCF_ANQ', category: 'IS', label: 'Basic CF / Share $ (ANQ)', tableColumn: 'QSBASICCF.ANQ', min: '', max: '' },
    { value: 'BASICCF_TTM', category: 'IS', label: 'Basic CF / Share $ (TFQ)', tableColumn: 'QSBASICCF.TTM', min: '', max: '' },
    { value: 'BASICCFCHG_ANQvPQ', category: 'IS', label: 'Basic CF / Shr Grw % (ANQ vs PQ)', tableColumn: 'QSBASICCFCHG.ANQvPQ', min: '', max: '' },
    { value: 'BASICCFCHG_TTMvPQ', category: 'IS', label: 'Basic CF / Shr Grw % (TFQ vs PQ)', tableColumn: 'QSBASICCFCHG.TTMvPQ', min: '', max: '' },
    { value: 'BASICCFCHG_ANQvPY', category: 'IS', label: 'Basic CF / Shr Grw % (ANQ vs SQPY)', tableColumn: 'QSBASICCFCHG.ANQvPY', min: '', max: '' },
    { value: 'BASICCFCHG_TTMvPY', category: 'IS', label: 'Basic CF / Shr Grw % (TFQ vs SQPY)', tableColumn: 'QSBASICCFCHG.TTMvPY', min: '', max: '' },
    { value: 'BASICEBITDA_ANQ', category: 'IS', label: 'Basic EBITDA / Share $ (ANQ)', tableColumn: 'QSBASICEBITDA.ANQ', min: '', max: '' },
    { value: 'BASICEBITDA_TTM', category: 'IS', label: 'Basic EBITDA / Share $ (TFQ)', tableColumn: 'QSBASICEBITDA.TTM', min: '', max: '' },
    { value: 'BASICEBITDACHG_ANQvPQ', category: 'IS', label: 'Basic EBITDA / Shr Grw % (ANQ vs PQ)', tableColumn: 'QSBASICEBITDACHG.ANQvPQ', min: '', max: '' },
    { value: 'BASICEBITDACHG_TTMvPQ', category: 'IS', label: 'Basic EBITDA / Shr Grw % (TFQ vs PQ)', tableColumn: 'QSBASICEBITDACHG.TTMvPQ', min: '', max: '' },
    { value: 'BASICEBITDACHG_ANQvPY', category: 'IS', label: 'Basic EBITDA / Shr Grw % (ANQ vs SQPY)', tableColumn: 'QSBASICEBITDACHG.ANQvPY', min: '', max: '' },
    { value: 'BASICEBITDACHG_TTMvPY', category: 'IS', label: 'Basic EBITDA / Shr Grw % (TFQ vs SQPY)', tableColumn: 'QSBASICEBITDACHG.TTMvPY', min: '', max: '' },
    { value: 'BASICEPS_ANQ', category: 'IS', label: 'Basic EPS / Share $ (ANQ)', tableColumn: 'QSBASICEPS.ANQ', min: '', max: '' },
    { value: 'BASICEPS_TTM', category: 'IS', label: 'Basic EPS / Share $ (TFQ)', tableColumn: 'QSBASICEPS.TTM', min: '', max: '' },
    { value: 'BASICEPSCHG_ANQvPQ', category: 'IS', label: 'Basic EPS / Shr Grw % (ANQ vs PQ)', tableColumn: 'QSBASICEPSCHG.ANQvPQ', min: '', max: '' },
    { value: 'BASICEPSCHG_TTMvPQ', category: 'IS', label: 'Basic EPS / Shr Grw % (TFQ vs PQ)', tableColumn: 'QSBASICEPSCHG.TTMvPQ', min: '', max: '' },
    { value: 'BASICEPSCHG_ANQvPY', category: 'IS', label: 'Basic EPS / Shr Grw % (ANQ vs SQPY)', tableColumn: 'QSBASICEPSCHG.ANQvPY', min: '', max: '' },
    { value: 'BASICEPSCHG_TTMvPY', category: 'IS', label: 'Basic EPS / Shr Grw % (TFQ vs SQPY)', tableColumn: 'QSBASICEPSCHG.TTMvPY', min: '', max: '' },
    { value: 'DILUTEDCF_ANQ', category: 'IS', label: 'Dil CF / Share $ (ANQ)', tableColumn: 'QSDILUTEDCF.ANQ', min: '', max: '' },
    { value: 'DILUTEDCF_TTM', category: 'IS', label: 'Dil CF / Share $ (TFQ)', tableColumn: 'QSDILUTEDCF.TTM', min: '', max: '' },
    { value: 'DILUTEDCFCHG_ANQvPQ', category: 'IS', label: 'Dil CF / Shr Grw % (ANQ vs PQ)', tableColumn: 'QSDILUTEDCFCHG.ANQvPQ', min: '', max: '' },
    { value: 'DILUTEDCFCHG_TTMvPQ', category: 'IS', label: 'Dil CF / Shr Grw % (TFQ vs PQ)', tableColumn: 'QSDILUTEDCFCHG.TTMvPQ', min: '', max: '' },
    { value: 'DILUTEDCFCHG_ANQvPY', category: 'IS', label: 'Dil CF / Shr Grw % (ANQ vs SQPY)', tableColumn: 'QSDILUTEDCFCHG.ANQvPY', min: '', max: '' },
    { value: 'DILUTEDCFCHG_TTMvPY', category: 'IS', label: 'Dil CF / Shr Grw % (TFQ vs SQPY)', tableColumn: 'QSDILUTEDCFCHG.TTMvPY', min: '', max: '' },
    { value: 'DILUTEDEBITDA_ANQ', category: 'IS', label: 'Dil EBITDA / Share $ (ANQ)', tableColumn: 'QSDILUTEDEBITDA.ANQ', min: '', max: '' },
    { value: 'DILUTEDEBITDA_TTM', category: 'IS', label: 'Dil EBITDA / Share $ (TFQ)', tableColumn: 'QSDILUTEDEBITDA.TTM', min: '', max: '' },
    { value: 'DILUTEDEBITDACHG_ANQvPQ', category: 'IS', label: 'Dil EBITDA / Shr Grw % (ANQ vs PQ)', tableColumn: 'QSDILUTEDEBITDACHG.ANQvPQ', min: '', max: '' },
    { value: 'DILUTEDEBITDACHG_TTMvPQ', category: 'IS', label: 'Dil EBITDA / Shr Grw % (TFQ vs PQ)', tableColumn: 'QSDILUTEDEBITDACHG.TTMvPQ', min: '', max: '' },
    { value: 'DILUTEDEBITDACHG_ANQvPY', category: 'IS', label: 'Dil EBITDA / Shr Grw % (ANQ vs SQPY)', tableColumn: 'QSDILUTEDEBITDACHG.ANQvPY', min: '', max: '' },
    { value: 'DILUTEDEBITDACHG_TTMvPY', category: 'IS', label: 'Dil EBITDA / Shr Grw % (TFQ vs SQPY)', tableColumn: 'QSDILUTEDEBITDACHG.TTMvPY', min: '', max: '' },
    { value: 'DILUTEDEPS_ANQ', category: 'IS', label: 'Dil EPS / Share $ (ANQ)', tableColumn: 'QSDILUTEDEPS.ANQ', min: '', max: '' },
    { value: 'DILUTEDEPS_TTM', category: 'IS', label: 'Dil EPS / Share $ (TFQ)', tableColumn: 'QSDILUTEDEPS.TTM', min: '', max: '' },
    { value: 'DILUTEDEPSCHG_ANQvPQ', category: 'IS', label: 'Dil EPS / Shr Grw % (ANQ vs PQ)', tableColumn: 'QSDILUTEDEPSCHG.ANQvPQ', min: '', max: '' },
    { value: 'DILUTEDEPSCHG_TTMvPQ', category: 'IS', label: 'Dil EPS / Shr Grw % (TFQ vs PQ)', tableColumn: 'QSDILUTEDEPSCHG.TTMvPQ', min: '', max: '' },
    { value: 'DILUTEDEPSCHG_ANQvPY', category: 'IS', label: 'Dil EPS / Shr Grw % (ANQ vs SQPY)', tableColumn: 'QSDILUTEDEPSCHG.ANQvPY', min: '', max: '' },
    { value: 'DILUTEDEPSCHG_TTMvPY', category: 'IS', label: 'Dil EPS / Shr Grw % (TFQ vs SQPY)', tableColumn: 'QSDILUTEDEPSCHG.TTMvPY', min: '', max: '' },
    { value: 'REVENUECF_ANQ', category: 'MAR', label: 'CF / Revenue % (LFQ)', tableColumn: 'QSREVENUECF.ANQ', min: '', max: '' },
    { value: 'REVENUECF_TTM', category: 'MAR', label: 'CF / Revenue % (TFQ)', tableColumn: 'QSREVENUECF.TTM', min: '', max: '' },
    { value: 'REVENUEEBITDA_ANQ', category: 'MAR', label: 'EBITDA / Revenue % (LFQ)', tableColumn: 'QSREVENUEEBITDA.ANQ', min: '', max: '' },
    { value: 'REVENUEEBITDA_TTM', category: 'MAR', label: 'EBITDA / Revenue % (TFQ)', tableColumn: 'QSREVENUEEBITDA.TTM', min: '', max: '' },
    { value: 'REVENUENC_ANQ', category: 'MAR', label: 'Net Income / Revenue % (LFQ)', tableColumn: 'QSREVENUENC.ANQ', min: '', max: '' },
    { value: 'REVENUENC_TTM', category: 'MAR', label: 'Net Income / Revenue % (TFQ)', tableColumn: 'QSREVENUENC.TTM', min: '', max: '' },
    { value: 'REVENUENNC_ANQ', category: 'MAR', label: 'Norm Net Income / Rev % (LFQ-0)', tableColumn: 'QSREVENUENNC.ANQ', min: '', max: '' },
    { value: 'REVENUENNC_TTM', category: 'MAR', label: 'Norm Net Income / Rev % (TFQ-0)', tableColumn: 'QSREVENUENNC.TTM', min: '', max: '' },
    { value: 'ASSETLIFE_ANQ', category: 'CAP', label: 'Asset Life (LFQ)', tableColumn: 'QSASSETLIFE.ANQ', min: '', max: '' },
    { value: 'IAGFA_ANQ', category: 'CAP', label: 'Tot Infl Adj Gross Cap $M (LFQ)', tableColumn: 'QSINFLADJGROSS.ANQ', min: '', max: '' },
    { value: 'IAGFACHG_ANQvPQ', category: 'CAP', label: 'Tot Infl Adj Gross Cap Grw % (LFQ vs PQ)', tableColumn: 'QSINFLADJGROSSCHG.ANQvPQ', min: '', max: '' },
    { value: 'IAGFACHG_ANQvPY', category: 'CAP', label: 'Tot Infl Adj Gross Cap Grw % (LFQ vs SQPY)', tableColumn: 'QSINFLADJGROSSCHG.ANQvPY', min: '', max: '' },
    { value: 'IANFA_ANQ', category: 'CAP', label: 'Tot Infl Adj Net Cap $M (LFQ)', tableColumn: 'QSINFLADJGROSS.ANQ', min: '', max: '' },
    { value: 'IANFACHG_ANQvPQ', category: 'CAP', label: 'Tot Infl Adj Net Cap Grw % (LFQ vs PQ)', tableColumn: 'QSINFLADJGROSSCHG.ANQvPQ', min: '', max: '' },
    { value: 'IANFACHG_ANQvPY', category: 'CAP', label: 'Tot Infl Adj Net Cap Grw % (LFQ vs SQPY)', tableColumn: 'QSINFLADJGROSSCHG.ANQvPY', min: '', max: '' },
    { value: 'TOTALASSETS_ANQ', category: 'CAP', label: 'Total Assets $M (LFQ)', tableColumn: 'QSTOTALASSETS.ANQ', min: '', max: '' },
    { value: 'TOTALASSETSCHG_ANQvPQ', category: 'CAP', label: 'Total Assets Grw % (LFQ vs PQ)', tableColumn: 'QSTOTALASSETSCHG.ANQvPQ', min: '', max: '' },
    { value: 'TOTALASSETSCHG_ANQvPY', category: 'CAP', label: 'Total Assets Grw % (LFQ vs SQPY)', tableColumn: 'QSTOTALASSETSCHG.ANQvPY', min: '', max: '' },
    { value: 'ROCVMEVA_ANQ', category: 'QVM', label: 'Value from Exp VA % (LFQ)', tableColumn: 'QSROCEX.ANQ', min: '', max: '' },
    { value: 'ROCVMNFC_ANQ', category: 'QVM', label: 'Value from Net FC % (LFQ)', tableColumn: 'QSROCINF.ANQ', min: '', max: '' },
    { value: 'ROCVMWC_ANQ', category: 'QVM', label: 'Value from WC % (FQ-0)', tableColumn: 'QSROC.ANQ', min: '', max: '' },
    { value: 'EVEBITDA_ANQ', category: 'TM', label: 'EV / EBITDA (ANQ)', tableColumn: 'QSEVEBITDA.ANQ', min: '', max: '' },
    { value: 'EVEBITDA_TTM', category: 'TM', label: 'EV / EBITDA (TFQ)', tableColumn: 'QSEVEBITDA.TTM', min: '', max: '' },
    { value: 'EVREVENUE_ANQ', category: 'TM', label: 'EV / Revenue (ANQ)', tableColumn: 'QSEVREVENUE.ANQ', min: '', max: '' },
    { value: 'EVREVENUE_TTM', category: 'TM', label: 'EV / Revenue (TFQ)', tableColumn: 'QSEVREVENUE.TTM', min: '', max: '' },
    { value: 'PBOOK_ANQ', category: 'TM', label: 'P / Book (LFQ)', tableColumn: 'QSPBOOK.ANQ', min: '', max: '' },
    { value: 'PE_ANQ', category: 'TM', label: 'P/E (ANQ)', tableColumn: 'QSPE.ANQ', min: '', max: '' },
    { value: 'PE_TTM', category: 'TM', label: 'P/E (TFQ)', tableColumn: 'QSPE.TTM', min: '', max: '' }
    
];

