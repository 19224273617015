import {
    Tooltip,
    IconButton,
    Alert as MuiAlert,
    Box,
    Stack,

} from '@mui/material';

import {
    Delete as DeleteIcon,
    ModeEdit as ModeEditIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
} from '@mui/icons-material'


export const EditCell = ({ row, table }) => {
    const meta = table.options.meta;

    const editRow = () => {
        meta?.setEditedRows((old) => ({
            ...old,
            [row.id]: !old[row.id],
        }));
    }

    const cancelEdit = () => {
        meta?.revertData(row.index, true);
        meta?.setEditedRows((old) => ({
            ...old,
            [row.id]: !old[row.id],
        }));
    }

    const saveRow = () => {
        meta?.saveRow(row.index);
        meta?.setEditedRows((old) => ({
            ...old,
            [row.id]: !old[row.id],
        }));
    }

    const removeRow = () => {
        meta?.removeRow(row.index);
    }

    return (
        <div className="edit-cell-container">
            {meta?.editedRows[row.id] ? (
                <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}  >
                    <Tooltip title="Cancel Edit">
                        <span>
                            <IconButton color="error" onClick={cancelEdit}>
                                <CancelIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Save Edit">
                        <span>
                            <IconButton color="success" onClick={saveRow}>
                                <CheckCircleIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </span>
                    </Tooltip>

                </Stack>
            ) : (
                <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}  >
                    <Tooltip title="Delete Factor">
                        <span>
                            <IconButton color="error" onClick={removeRow}>
                                <DeleteIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Edit Factor">
                        <span>
                            <IconButton color="warning" onClick={editRow}>
                                <ModeEditIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            )}
        </div>
    );
};
