import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import toast, { Toaster } from "react-hot-toast";

import { Box, Paper, Typography, Tooltip, IconButton, MenuItem, Alert as MuiAlert, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Stack, ListItemIcon, Link, TablePagination, Toolbar, TableSortLabel, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar } from "@mui/material";

import { Menu as MenuIcon, VisibilityOff as VisibilityOffIcon, ModeEdit as ModeEditIcon, FileCopy as FileCopyIcon } from "@mui/icons-material";

const WEBAPI = process.env.REACT_APP_WEB_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (orderBy === "marketcap_min" || orderBy === "marketcap_max") {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    align: "left",
    disablePadding: false,
    label: "Strategy",
  },
  {
    id: "market",
    align: "left",
    disablePadding: false,
    label: "Market",
  },
  {
    id: "marketcap_min",
    align: "right",
    disablePadding: false,
    label: "Market Cap Min ($M)",
  },
  {
    id: "marketcap_max",
    align: "right",
    disablePadding: false,
    label: "Market Cap Max ($M)",
  },
  {
    id: "sectors",
    align: "center",
    disablePadding: false,
    label: "Sectors",
  },
  // {
  //     id: 'options',
  //     align: 'center',
  //     disablePadding: false,
  //     label: 'Options',
  // },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="options" align="center" padding={"normal"}>
          Options
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleNewStrategy } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Stack direction="row" sx={{ my: 2, width: "100%" }} justifyContent="space-between" alignItems="center">
        {/* <Typography variant="h6" id="tableTitle" component="div">
          My Strategies
        </Typography>

        <Button onClick={handleNewStrategy} variant="contained" color="success" startIcon={<NoteAddIcon />}>
          New Strategy
        </Button> */}
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleNewStrategy: PropTypes.func.isRequired,
};

const QuickStrategiesFSA = ({ data, getStrategies, admin }) => {
  const navigate = useNavigate();
  // const [data, setData] = useState([]);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [rowId, setRowId] = React.useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dense, setDense] = React.useState(true);
  const [openDlg, setOpenDlg] = React.useState(false);
  const [action, setAction] = useState("");
  const [actionTitle, setActionTitle] = useState("");
  const [strategyName, setStrategyName] = useState("");

  useEffect(() => {
    console.log(admin);
    const strategyRowsPerPage = localStorage.getItem("QSTRATEGY_FSARowsPerPage");
    if (strategyRowsPerPage === null) {
      setRowsPerPage(10);
    } else {
      setRowsPerPage(parseInt(strategyRowsPerPage, 10));
    }

    // getStrategies();
  }, []);

  // const getStrategies = () => {
  //   axios
  //     .get(`${WEBAPI}/quickstrategy`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setData(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleOpenOptionsMenu = (event) => {
    setAnchorElOptions(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorElOptions(null);
  };

  const handleNewStrategy = () => {
    setAction("New");
    setActionTitle("New Strategy");
    setStrategyName("");
    setOpenDlg(true);
  };

  const handleRename = (e) => {
    console.log("rename", rowId);
    setAnchorElOptions(null);
    e.stopPropagation();

    // use the rowId to get the strategy name
    const oldStrategyName = data.filter((item) => item.id === rowId)[0].name;
    setStrategyName(oldStrategyName);

    setAction("Rename");
    setActionTitle("Rename Strategy");
    setStrategyName(oldStrategyName);
    setOpenDlg(true);
  };

  const handleMakeCopy = (e) => {
    console.log("make copy", rowId);
    setAnchorElOptions(null);
    e.stopPropagation();

    // use the rowId to get the strategy name
    const oldStrategyName = data.filter((item) => item.id === rowId)[0].name + " (copy)";
    setStrategyName(oldStrategyName);

    setAction("Copy");
    setActionTitle("Make a copy of Strategy");
    setStrategyName(oldStrategyName);
    setOpenDlg(true);
  };

  const handleMakePrivate = (e) => {
    console.log("make private", rowId);
    setAnchorElOptions(null);
    e.stopPropagation();

    axios
      .patch(`${WEBAPI}/quickstrategy`, { id: rowId, isPublic: false })
      .then((res) => {
        console.log(res.data);
        getStrategies();
        toast.success("Strategy is now private");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! something went wrong!");
      });
  };

  const handleDelete = (e) => {
    console.log("delete", rowId);
    setAnchorElOptions(null);
    e.stopPropagation();

    // use the rowId to get the strategy name
    const oldStrategyName = data.filter((item) => item.id === rowId)[0].name;
    setStrategyName(oldStrategyName);

    setAction("Delete");
    setActionTitle("Delete Strategy");
    setStrategyName(oldStrategyName);
    setOpenDlg(true);
  };

  const handleOpenInNewTab = (e) => {
    console.log("open in new tab", rowId);
    setAnchorElOptions(null);
    e.stopPropagation();
    window.open(`/quickstrategy/${rowId}`, "_blank", "noreferrer");
  };

  const handleRowClick = (e, id) => {
    e.preventDefault();
    setRowId(id);
  };

  const handleNavigate = (e, id) => {
    e.preventDefault();
    navigate(`/quickstrategy/${id}`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("QSTRATEGY_FSARowsPerPage", event.target.value);
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(() => stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage]);

  const handleClickOpenDialog = () => {
    setOpenDlg(true);
  };

  const handleCloseDialog = () => {
    setOpenDlg(false);
  };

  const handleSaveDialog = () => {
    if (action === "New") {
      axios
        .post(`${WEBAPI}/quickstrategy`, { name: strategyName })
        .then((res) => {
          if (res.status === 201) {
            getStrategies();
            // toast.success("Strategy created")
            navigate(`/quickstrategy/${res.data.id}`);
          } else if (res.status === 200) {
            // console.log(res)
            toast.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (action === "Rename") {
      axios
        .put(`${WEBAPI}/quickstrategy`, { id: rowId, name: strategyName })
        .then((res) => {
          console.log(res.data);
          getStrategies();
          toast.success("Strategy renamed");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Oops! something went wrong!");
        });
    }

    if (action === "Delete") {
      axios
        .delete(`${WEBAPI}/quickstrategy/${rowId}`)
        .then((res) => {
          console.log(res.data);
          getStrategies();
          toast.success("Strategy deleted");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Oops! something went wrong!");
        });
    }

    if (action === "Copy") {
      // console.log("copy", rowId, strategyName);
      axios
        .post(`${WEBAPI}/quickstrategy/fsacopy`, { id: rowId, name: strategyName })
        .then((res) => {
          console.log(res.data);

          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            toast.success("Strategy created");
            getStrategies();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Oops! something went wrong!");
        });
    }

    setOpenDlg(false);
  };

  return (
    <Box sx={{ mx: 2, my: 0 }}>
      <Toaster position="top-center" />

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} handleNewStrategy={handleNewStrategy} /> */}
          <TableContainer>
            <Table sx={{}} aria-label="simple table" size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {(rowsPerPage > 0 ? stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(data, getComparator(order, orderBy))).map((row, index) => {
                  const isItemSelected = isSelected(data.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.id} onClick={(e) => handleRowClick(e, row.id)} sx={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        <Link onClick={(e) => handleNavigate(e, row.id)} href="#" sx={{}}>
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">{row.market}</TableCell>
                      <TableCell align="right">{row.marketcap_min.toLocaleString()}</TableCell>
                      <TableCell align="right">{row.marketcap_max.toLocaleString()}</TableCell>
                      <TableCell align="center">{row.sectors.split(",").join(",  ")}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ textAlign: "center" }}>
                          <IconButton onClick={handleOpenOptionsMenu}>
                            <MenuIcon />
                          </IconButton>
                          <Menu
                            sx={{ mt: "45px" }}
                            id="options-menu"
                            anchorEl={anchorElOptions}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            open={Boolean(anchorElOptions)}
                            onClose={handleCloseOptionsMenu}
                          >
                            {/* <MenuItem key="rename" onClick={(e) => handleRename(e)}>
                              <ListItemIcon>
                                <TextFieldsIcon />
                              </ListItemIcon>
                              <Typography textAlign="center">Rename</Typography>
                            </MenuItem> */}

                            <MenuItem key="makecopy" onClick={(e) => handleMakeCopy(e)}>
                              <ListItemIcon>
                                <FileCopyIcon />
                              </ListItemIcon>
                              <Typography textAlign="center">Make a copy</Typography>
                            </MenuItem>

                            {/* <MenuItem key="delete" onClick={(e) => handleDelete(e)}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <Typography textAlign="center">Delete</Typography>
                            </MenuItem> */}

                            <MenuItem key="open" onClick={(e) => handleOpenInNewTab(e)}>
                              <ListItemIcon>
                                <ModeEditIcon />
                              </ListItemIcon>
                              <Typography textAlign="center">Open in a new tab</Typography>
                            </MenuItem>

                            {admin && (
                              <MenuItem key="saveas" onClick={(e) => handleMakePrivate(e)}>
                                <ListItemIcon>
                                  <VisibilityOffIcon />
                                </ListItemIcon>
                                <Typography textAlign="center">Make Private</Typography>
                              </MenuItem>
                            )}
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination rowsPerPageOptions={[5, 10, 15, 20, 25, { label: "All", value: -1 }]} component="div" count={data.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} showFirstButton={true} showLastButton={true} labelRowsPerPage="Strategies per page:" />
        </Paper>
      </Box>

      <Dialog open={openDlg} onClose={handleCloseDialog}>
        <DialogTitle>{actionTitle}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <TextField autoFocus margin="dense" id="name" label="Strategy Name" fullWidth variant="standard" value={strategyName} onChange={(e) => setStrategyName(e.target.value)} disabled={action === "Delete" ? true : false} />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button color="success" onClick={handleSaveDialog}>
            {action === "Delete" ? "Ok" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuickStrategiesFSA;
