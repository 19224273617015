import React, { useContext, useEffect, useState } from "react";
import { StrategyContext } from "./Context/StrategyContext";
import axios from "axios";
import { format, isValid, subMonths, subDays } from "date-fns";

import {
  Typography,
  Box,
  // Alert as MuiAlert,
  CircularProgress,
  Stack,
  // Tooltip,
} from "@mui/material";

import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

// import { metrics } from "./Constants/constants";

const indicatorSize = 80;
const WEBAPI = process.env.REACT_APP_WEB_API;
const columnHelper = createColumnHelper();

const backtestStaticColumns = [
  columnHelper.accessor("Group", {
    header: "Group",
    id: "Group",
    cell: (info) => <Typography sx={{ textAlign: "left", fontSize: '12px' }}>{info.getValue()}</Typography>,
    size: 50,
  }),
];

const QuickStrategyBackTest = () => {
  const { market, sectors, marketCapMin, marketCapMax, topN, factors, handleBackTestResultsChange } = useContext(StrategyContext);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = React.useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    const periodsListTmp = [];
    const startDate = new Date();
    let periodValue = 0;

    for (let x = 0; x < 19; x++) {
      let nextDate = subMonths(startDate, x * 3);
      while (!isValid(nextDate)) {
        subDays(nextDate, 1);
      }

      let helperLabel;
      const year = Math.floor(x / 4);
      const month = (x % 4) * 3;

      if (year === 0 && month === 0) {
        helperLabel = "Today";
      } else if (year === 0) {
        helperLabel = `${month}m`;
      } else if (year === 0 && month === 0) {
        helperLabel = `${month}m`;
      } else if (month === 0) {
        helperLabel = `${year}y`;
      } else {
        helperLabel = `${year}y ${month}m`;
      }

      periodsListTmp.push({
        value: String(periodValue).padStart(2, "0"),
        label: format(nextDate, "MMM dd, yyyy") + ` (${helperLabel})`,
      });

      periodValue++;
    }

    const newColumns = [...backtestStaticColumns];
    for (let x = 18; x >= 0; x--) {
      const header = periodsListTmp[x]["label"];
      const id = `M${periodsListTmp[x]["value"]}`;

      const newColumn = columnHelper.accessor(id, {
        header: header,
        id: id,
        cell: (info) => (
          <Typography
            sx={{
              textAlign: "right",
              color: info.getValue() > 0 ? "green" : "red",
              fontSize: '12px'
            }}
          >
            {info.getValue()?.toFixed(1)}%
          </Typography>
        ),
        size: 50,
        align: "right",
      });

      newColumns.push(newColumn);
    }

    newColumns.push(
      columnHelper.accessor("StdDev", {
        header: "Std Dev",
        id: "StdDev",
        cell: (info) => (
          <Typography
            sx={{
              textAlign: "right",
              color: info.getValue() > 0 ? "green" : "red",
              fontSize: '12px'
            }}
          >
            {info.getValue()?.toFixed(1)}%
          </Typography>
        ),
        size: 50,
      })
    );
    newColumns.push(
      columnHelper.accessor("Median", {
        header: "Median",
        id: "Median",
        cell: (info) => (
          <Typography
            sx={{
              textAlign: "right",
              color: info.getValue() > 0 ? "green" : "red",
              fontSize: '12px'
            }}
          >
            {info.getValue()?.toFixed(1)}%
          </Typography>
        ),
        size: 50,
      })
    );
    newColumns.push(
      columnHelper.accessor("High", {
        header: "High",
        id: "High",
        cell: (info) => (
          <Typography
            sx={{
              textAlign: "right",
              color: info.getValue() > 0 ? "green" : "red",
              fontSize: '12px'
            }}
          >
            {info.getValue()?.toFixed(1)}%
          </Typography>
        ),
        size: 50,
      })
    );
    newColumns.push(
      columnHelper.accessor("Low", {
        header: "Low",
        id: "Low",
        cell: (info) => (
          <Typography
            sx={{
              textAlign: "right",
              color: info.getValue() > 0 ? "green" : "red",
              fontSize: '12px'
            }}
          >
            {info.getValue()?.toFixed(1)}%
          </Typography>
        ),
        size: 50,
      })
    );

    console.log(periodsListTmp);
    console.log(newColumns);

    setColumns(newColumns);

    const payload = {
      market: market,
      sectors: sectors.join(","),
      mcap_min: marketCapMin,
      mcap_max: marketCapMax,
      metrics: factors.map((factor) => factor.metric).join(","),
      ranks: factors.map((factor) => factor.rank).join(","),
      weights: factors.map((factor) => factor.weight).join(","),
      tableColumns: factors.map((factor) => factor.tableColumn).join(","),
      minimums: factors.map((factor) => factor.minimum).join(","),
      maximums: factors.map((factor) => factor.maximum).join(","),
      topN: topN,
    };

    console.log(payload);

    axios
      .post(`${WEBAPI}/quickstrategy/backtest`, payload)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        handleBackTestResultsChange(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [factors, market, marketCapMax, marketCapMin, sectors, topN]);

  return loading ? (
    <Stack alignItems="center" sx={{ height: "100vh" }}>
      <CircularProgress
        color="secondary"
        size={indicatorSize}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: `${-indicatorSize / 2}px`,
          marginLeft: `${-indicatorSize / 2}px`,
        }}
      />
    </Stack>
  ) : (
    <Box>
      <Box display={"flex"} alignItems={"center"} sx={{ mt: 0 }}>
        {/* <TableContainer sx={{ mt: 2, maxHeight: "calc(100vh - 250px)", fontSize: '0.75rem' }}> */}
        <div className="strategy_backtest_container">
          <table id="strategy_results">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="pointer">
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <Typography sx={{ width: header.getSize(), ml: 1, fontSize: '12px' }}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{ asc: " 🔼", desc: " 🔽" }[header.column.getIsSorted()] ?? null}
                          </Typography>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* </TableContainer> */}
      </Box>
    </Box>
  );
};

export default QuickStrategyBackTest;
