import React from "react";
import axios from "axios";
import { Paper, Box, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button, Checkbox, ListItemText, Tooltip, IconButton, Typography, TextField, Stack, Menu, ListItemIcon } from "@mui/material";

import { Menu as MenuIcon, Delete as DeleteIcon, ModeEdit as ModeEditIcon, NoteAdd as NoteAddIcon, SaveAs as SaveAsIcon, Save as SaveIcon, FileCopy as FileCopyIcon, TextFields as TextFieldsIcon, FilterList as FilterListIcon } from "@mui/icons-material";

import { marketNamesWithPINX as marketNames, regionNames, sectorNames, marketCapValues } from "./Constants/constants";

const WEBAPI = process.env.REACT_APP_WEB_API;

function QuickSectorSearch({ handleGetSearchResults, rowCount, handleSaveAsPortfolio, handleSaveToExistingPortfolio }) {
  const [market, setMarket] = React.useState("CAN");
  const [regions, setRegions] = React.useState([]);
  const [sector, setSector] = React.useState("CD");
  const [marketCapMin, setMarketCapMin] = React.useState(0);
  const [marketCapMax, setMarketCapMax] = React.useState(10000000);
  const [resultsButtonColour, setResultsButtonColour] = React.useState("success");
  const [regionDisplay, setRegionDisplay] = React.useState("");
  const [toolbarIsVisible, setToolbarIsVisible] = React.useState(true);
  const [action, setAction] = React.useState("");
  const [newPortfolioName, setNewPortfolioName] = React.useState("");
  const [portfolioID, setPortfolioID] = React.useState("");
  const [portfolioList, setPortfolioList] = React.useState([]);
  const [portfolioListWithNONE, setPortfolioListWithNONE] = React.useState([]);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [refreshEnabled, setRefreshEnabled] = React.useState(false);

  const getSearchResults = () => {
    const payload = {
      portfolio: portfolioID,
      market: market,
      regions: regions,
      sector: sector,
      marketCapMin: marketCapMin,
      marketCapMax: marketCapMax,
    };

    // console.log(payload)

    handleGetSearchResults(payload);
  };

  const handlePortfolioChange = (event) => {
    const newPortfolioID = event.target.value;
    console.log(newPortfolioID);
    setPortfolioID(newPortfolioID);
    localStorage.setItem("QS_Portfolio", newPortfolioID);
    setRefreshEnabled(true);
  };

  const handleMarketChange = (event) => {
    const newMarket = event.target.value;
    console.log(newMarket);
    setMarket(newMarket);

    if (newMarket === "PNX") {
      setRegionDisplay("block");
    } else {
      setRegionDisplay("none");
    }
    setRegions([]);
    localStorage.setItem("QS_Regions", []);

    localStorage.setItem("QS_Market", newMarket);

    setRefreshEnabled(true);
  };

  const handleRegionChange = (event) => {
    const newRegions = event.target.value;
    console.log(newRegions);

    setRegions(newRegions);
    localStorage.setItem("QS_Regions", newRegions);

    setRefreshEnabled(true);
  };

  const handleSectorChange = (event) => {
    const newSector = event.target.value;
    console.log(newSector);

    setSector(newSector);
    localStorage.setItem("QS_Sector", newSector);

    setRefreshEnabled(true);
  };

  const handleMarketCapMinChange = (event) => {
    const newMarketCapMin = event.target.value;
    console.log(newMarketCapMin);

    setMarketCapMin(newMarketCapMin);
    localStorage.setItem("QS_MarketCapMin", newMarketCapMin);

    setRefreshEnabled(true);
  };

  const handleMarketCapMaxChange = (event) => {
    const newMarketCapMax = event.target.value;
    console.log(newMarketCapMax);

    setMarketCapMax(newMarketCapMax);
    localStorage.setItem("QS_MarketCapMax", newMarketCapMax);

    setRefreshEnabled(true);
  };

  const getMCapMinValues = () => {
    const mCapMinValues = marketCapValues.filter((mCap) => mCap.mCapMin === true && mCap.value < marketCapMax);
    return mCapMinValues;
  };

  const getMCapMaxValues = () => {
    const mCapMaxValues = marketCapValues.filter((mCap) => mCap.mCapMax === true && mCap.value > marketCapMin);
    return mCapMaxValues;
  };

  const handleSaveAsNew = () => {
    setAnchorElOptions(null);

    setAction("New");
    setNewPortfolioName("");
    setToolbarIsVisible(false);
  };

  const handleSaveToExisting = () => {
    setAnchorElOptions(null);

    setAction("Add to Existing");
    setToolbarIsVisible(false);
  };

  const onCancel = () => {
    console.log("onCancel");
    setToolbarIsVisible(true);
  };

  const onSavePortfolio = () => {
    // console.log('onSavePortfolio', newPortfolioName)

    setToolbarIsVisible(true);

    if (action === "New") {
      handleSaveAsPortfolio(newPortfolioName);
    } else if (action === "Add to Existing") {
      handleSaveToExistingPortfolio(portfolioID);
    }
  };

  const getPortfolioList = async () => {
    // setLoading(true)

    axios
      .get(`${WEBAPI}/quickportfolio`)
      .then((res) => {
        console.log(res.data)
        setPortfolioList(res.data);

        // let noPortfolio = { id: "NONE", name: "None" };
        // res.data.unshift(noPortfolio);
        const copyWithNone = [ { id: "NONE", name: "None" }, ...res.data ];
        setPortfolioListWithNONE(copyWithNone);
        console.log(copyWithNone);

        const localPortfolio = localStorage.getItem("QS_Portfolio");
        if (localPortfolio) {
          setPortfolioID(localPortfolio);
        } else if (res.data.length > 0 && portfolioID === "") {
          setPortfolioID(res.data[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false)
      });
  };

  React.useEffect(() => {
    const localMarket = localStorage.getItem("QS_Market");
    const localRegions = localStorage.getItem("QS_Regions");
    const localSector = localStorage.getItem("QS_Sector");
    const localMarketCapMin = localStorage.getItem("QS_MarketCapMin");
    const localMarketCapMax = localStorage.getItem("QS_MarketCapMax");

    // setLoading(true)

    getPortfolioList();

    if (localMarket) {
      setMarket(localMarket);
    }

    if (localMarket === "PNX") {
      setRegionDisplay("block");
    } else {
      setRegionDisplay("none");
    }

    if (localRegions) {
      setRegions(localRegions.split(","));
    }

    if (localSector) {
      setSector(localSector);
    }

    if (localMarketCapMin) {
      setMarketCapMin(localMarketCapMin);
    }

    if (localMarketCapMax) {
      setMarketCapMax(localMarketCapMax);
    }

    // setLoading(false)
  }, []);

  const handleOpenOptionsMenu = (event) => {
    setAnchorElOptions(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorElOptions(null);
  };

  return toolbarIsVisible ? (
    <Paper sx={{ mx: 1, mt: 1, p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box display={"flex"} alignItems={"center"}>
          <Box sx={{ ml: 0 }}>
            <FormControl sx={{ m: 0, width: 220 }} size="small">
              <InputLabel>Compare with Portfolio</InputLabel>
              <Select value={portfolioID} onChange={handlePortfolioChange} input={<OutlinedInput label="Compare with Portfolio" />}>
                {portfolioListWithNONE.map((p) => (
                  <MenuItem key={p.name} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ ml: 2 }}>
            <FormControl sx={{ m: 0, width: 110 }} size="small">
              <InputLabel>Market</InputLabel>
              <Select value={market} onChange={handleMarketChange} input={<OutlinedInput label="Market" />}>
                {marketNames.map((market) => (
                  <MenuItem key={market.value} value={market.value}>
                    {market.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ ml: 2, display: regionDisplay }}>
            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
              <InputLabel>Regions</InputLabel>
              <Select multiple value={regions} onChange={handleRegionChange} input={<OutlinedInput label="Regions" />} renderValue={(selected) => selected.join(", ")}>
                {regionNames.map((region) => (
                  <MenuItem key={region.value} value={region.value}>
                    <Checkbox checked={regions.indexOf(region.value) > -1} />
                    <ListItemText primary={region.label + " - " + region.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ ml: 2 }}>
            <FormControl sx={{ m: 0, width: 270 }} size="small">
              <InputLabel size="small">Sector</InputLabel>
              <Select value={sector} onChange={handleSectorChange} input={<OutlinedInput label="Sector" />}>
                {sectorNames.map((sector) => (
                  <MenuItem key={sector.value} value={sector.value}>
                    {sector.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ ml: 2 }}>
            <FormControl sx={{ m: 0, width: 150 }} size="small">
              <InputLabel>Market Cap Min</InputLabel>
              <Select value={marketCapMin} onChange={handleMarketCapMinChange} input={<OutlinedInput label="MarketCap Min" />}>
                {getMCapMinValues().map((marketCap) => (
                  <MenuItem key={marketCap.value} value={marketCap.value}>
                    {marketCap.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ ml: 2 }}>
            <FormControl sx={{ m: 0, width: 150 }} size="small">
              <InputLabel>Market Cap Max</InputLabel>
              <Select value={marketCapMax} onChange={handleMarketCapMaxChange} input={<OutlinedInput label="MarketCap Max" />}>
                {getMCapMaxValues().map((marketCap) => (
                  <MenuItem key={marketCap.value} value={marketCap.value}>
                    {marketCap.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ ml: 4 }}>
            <Button variant="contained" size="small" color={resultsButtonColour} onClick={getSearchResults} disabled={!refreshEnabled}>
              Refresh
            </Button>
          </Box>
        </Box>
        <Box>
          <IconButton onClick={handleOpenOptionsMenu}>
            <MenuIcon />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="options-menu"
            anchorEl={anchorElOptions}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElOptions)}
            onClose={handleCloseOptionsMenu}
          >
            <MenuItem key="saveNew" onClick={() => handleSaveAsNew()}>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <Typography textAlign="center">Save as New Portfolio</Typography>
            </MenuItem>
            <MenuItem key="saveTo" onClick={() => handleSaveToExisting()}>
              <ListItemIcon>
                <SaveAsIcon />
              </ListItemIcon>
              <Typography textAlign="center">Add to Existing Portfolio</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
    </Paper>
  ) : (
    <Paper sx={{ mx: 1, mt: 1, p: 2 }}>
      <Box display={"flex"}>
        <Box display={"flex"} sx={{ ml: 2, alignItems: "center" }}>
          <Typography sx={{ mr: 2 }} variant="subtitle1" component="h2">
            {action}
          </Typography>

          {action === "New" ? (
            <Box>
              <FormControl sx={{ minWidth: 300, ml: 1 }}>
                <TextField autoFocus margin="dense" id="name" label="Portfolio" variant="outlined" size="small" sx={{ width: 300 }} value={newPortfolioName} onChange={(e) => setNewPortfolioName(e.target.value)} disabled={action === "Delete" ? true : false} />
              </FormControl>
            </Box>
          ) : (
            <Box>
              <FormControl sx={{ minWidth: 300, ml: 1 }} size="small">
                <InputLabel>Portfolio</InputLabel>
                <Select label="Portfolio" value={portfolioID} sx={{ width: 300 }} onChange={handlePortfolioChange}>
                  {portfolioList.map((portfolio) => (
                    <MenuItem key={portfolio.id} value={portfolio.id} selected={portfolio.id == portfolioID}>
                      {portfolio.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Button sx={{ ml: 1 }} color="error" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" disabled={action === "New" ? newPortfolioName.length === 0 : null} onClick={onSavePortfolio}>
            Save
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

export default QuickSectorSearch;
