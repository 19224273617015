import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../logo.svg";
import { SvgIcon } from "@mui/material";
import { useAtom } from "jotai";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { emailAtom, firstNameAtom, lastNameAtom, authenticatedAtom, userIDAtom, tokenAtom } from "./Atoms";
import jwt from "jwt-decode";
import InfoIcon from "@mui/icons-material/Info";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);

  const [userID, setUserID] = useAtom(userIDAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(authenticatedAtom);

  useEffect(() => {
    const token = localStorage.getItem("token");
    // console.log("Appbar");
    if (token) {
      const decodedToken = jwt(token);

      setIsAuthenticated(true);
      setUserID(decodedToken.userid);
      setFirstName(decodedToken.fname);
      setLastName(decodedToken.lname);
      setEmail(decodedToken.email);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateToDashboard = () => {
    navigate("/");
    setAnchorElNav(null);
  };
  const navigateToQuickPORTFOLIOS = () => {
    navigate("/quickportfolios");
    setAnchorElNav(null);
  };
  const navigateToQuickSECTOR = () => {
    navigate("/quicksector");
    setAnchorElNav(null);
  };
  const navigateToQuickSTRATEGIES = () => {
    navigate("/quickstrategies");
    setAnchorElNav(null);
  };
  const navigateToManagedPortfolios = () => {
    navigate("/managedportfolios");
    setAnchorElNav(null);
  };

  const navigateToDownloads = () => {
    navigate("/quickvaluation");
    setAnchorElNav(null);
  };

  const navigateToCompanies = () => {
    navigate("/companies");
    setAnchorElNav(null);
    setAnchorElAdmin(null);
  };
  const navigateToUsers = () => {
    navigate("/users");
    setAnchorElNav(null);
    setAnchorElAdmin(null);
  };
  const navigateToUploads = () => {
    navigate("/uploads");
    setAnchorElNav(null);
    setAnchorElAdmin(null);
  };

  const handleAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };
  const handleAdminClose = () => {
    setAnchorElAdmin(null);
  };

  const handleLogin = () => {
    navigate("/login");
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);

    localStorage.removeItem("token");

    setIsAuthenticated(false);
    setUserID("");
    setFirstName("");
    setLastName("");
    setEmail("");

    navigate("/");
  };

  const handlePasswordChange = () => {
    navigate("/passwordchange");
    setAnchorElUser(null);
  };

  const handleOpenHelp = () => {
    // console.log('help')
    window.open("/help", "_blank", "noreferrer");
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} className="App-logo" alt="logo" />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              // letterSpacing: '.3rem',
              color: "inherit",
              textDecoration: "none",
            }}
          >
            FSA
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={navigateToDashboard}>
                <Typography textAlign="center">Dashboard</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToQuickPORTFOLIOS}>
                <Typography textAlign="center">QuickPORTFOLIO</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToQuickSECTOR}>
                <Typography textAlign="center">QuickSECTOR</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToQuickSTRATEGIES}>
                <Typography textAlign="center">QuickSTRATEGY</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToManagedPortfolios}>
                <Typography textAlign="center">Managed Portfolios</Typography>
              </MenuItem>
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            FSA
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button key="Dashboard" onClick={navigateToDashboard} sx={{ my: 2, color: location.pathname === "/" ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
              Dashboard
            </Button>

            {isAuthenticated ? (
              <Button key="QuickPORTFOLIO" onClick={navigateToQuickPORTFOLIOS} sx={{ my: 2, color: location.pathname.startsWith("/quickportfolio") || location.pathname.startsWith("/quickportfolios") ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                QuickPORTFOLIO
              </Button>
            ) : null}
            {isAuthenticated ? (
              <Button key="QuickSECTOR" onClick={navigateToQuickSECTOR} sx={{ my: 2, color: location.pathname === "/quicksector" ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                QuickSECTOR
              </Button>
            ) : null}
            {isAuthenticated ? (
              <Button key="QuickSTRATEGY" onClick={navigateToQuickSTRATEGIES} sx={{ my: 2, color: location.pathname.startsWith("/quickstrategy") || location.pathname === "/quickstrategies" ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                QuickSTRATEGY
              </Button>
            ) : null}
            {isAuthenticated ? (
              <Button key="QuickVALUATION" onClick={navigateToDownloads} sx={{ my: 2, color: location.pathname.startsWith("/quickvaluation") || location.pathname === "/quickvaluation" ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                QuickVALUATION
              </Button>
            ) : null}
            {isAuthenticated ? (
              <Button key="ManagedPortfolios" onClick={navigateToManagedPortfolios} sx={{ my: 2, color: location.pathname === "/managedportfolios" ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                Managed Portfolios
              </Button>
            ) : null}

            {email && (email === "mkacur@fsavaluation.com" || email === "ken@afxsoftware.com") ? (
              <Box sx={{ flexGrow: 1 }}>
                <Button key="Admin" onClick={handleAdminMenu} sx={{ my: 2, color: location.pathname === "/users" || location.pathname === "/companies" ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                  Admin
                </Button>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElAdmin}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElAdmin)}
                  onClose={handleAdminClose}
                >
                  <MenuItem onClick={navigateToUsers}>Users</MenuItem>
                  <MenuItem onClick={navigateToCompanies}>Companies</MenuItem>
                  <MenuItem onClick={navigateToUploads}>Uploads</MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }}></Box>
            )}

            {isAuthenticated ? (
              <>
                <Box sx={{ flexGrow: 0, mt: 0.75 }}>
                  <Tooltip title="Profile">
                    <IconButton onClick={handleOpenUserMenu}>
                      <Avatar sx={{ color: (theme) => theme.palette.primary.main, backgroundColor: "white", fontSize: "1.2rem", fontWeight: "bold" }}>{firstName?.length > 0 && lastName?.length > 0 ? firstName[0] + lastName[0] : <PersonOutlineOutlinedIcon />}</Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem key="logout" onClick={handleLogout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                    <MenuItem key="changePassword" onClick={handlePasswordChange}>
                      <Typography textAlign="center">Change Password</Typography>
                    </MenuItem>
                  </Menu>
                </Box>

                <Box sx={{ flexGrow: 0, mt: 0.75 }}>
                  <Tooltip title="Legend">
                    <IconButton onClick={handleOpenHelp}>
                      <Avatar sx={{ color: "#1976d2", backgroundColor: "white", fontSize: "1.2rem", fontWeight: "bold" }}>
                        <InfoIcon sx={{ color: "#1976d2" }} />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            ) : (
              <Button key="Login" onClick={handleLogin} sx={{ my: 2, color: location.pathname.startsWith("/login") ? (theme) => theme.palette.secondary.main : "white", display: "block", textTransform: "none" }}>
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
