import { useState, useEffect, ChangeEvent } from "react";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';

// import "./table.css";
import { categories, metrics } from '../Constants/constants'


export const TableCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);


  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  const onSelectChange = (e) => {
    console.log('e.target.value', e.target.value)
    setValue(e.target.value);
    tableMeta?.updateData(row.index, column.id, e.target.value);
  };

  if (tableMeta?.editedRows[row.id] && column.id === "category") {
    return (
      <Box >
        <Select sx={{ ml: 0, width: '100%' }}
          size='small'
          value={initialValue}
          onChange={onSelectChange}
        >
          {columnMeta?.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  if (tableMeta?.editedRows[row.id] && column.id === "metric") {
    const category = row.getValue('category')
    // console.log('category:', category)
    const filteredMetrics = metrics.filter(metric => metric.category === category)
    // console.log('filteredMetrics', filteredMetrics)

    // check if metric is in filteredMetrics
    let metric = row.getValue('metric')

    let metricValue = initialValue;
    if (!filteredMetrics.some(m => m.value === metric)) {
      const metric = filteredMetrics[0].value

      if (initialValue != metric) {
        metricValue = metric
        tableMeta?.updateData(row.index, column.id, metricValue);
      }

    }

    return (
      <Box >
        <Select sx={{ ml: 0, width: '100%' }}
          size='small'
          value={metricValue}
          onChange={onSelectChange}
        >
          {filteredMetrics.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  if (tableMeta?.editedRows[row.id] && column.id === "rank") {
    return (
      <Box >
        <Select sx={{ ml: 0, width: '100%' }}
          size='small'
          value={initialValue}
          onChange={onSelectChange}
        >
          {columnMeta?.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value === 'ASC' ?
                <IconButton sx={{ color: '#ff9100' }} size='small'>
                  <ArrowUpwardIcon fontSize="small"/>
                </IconButton>
                :
                <IconButton sx={{ color: '#2196f3' }} size='small'>
                  <ArrowDownwardIcon fontSize="small"/>
                </IconButton>
              }
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  if (tableMeta?.editedRows[row.id] && column.id === "minimum") {
    return (
      <Box >
        <FormControl sx={{ m: 0, width: '100%' }} size='small'>
          <OutlinedInput
            value={value}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        </FormControl>
      </Box>
    )
  }

  if (tableMeta?.editedRows[row.id] && column.id === "maximum") {
    return (
      <Box >
        <FormControl sx={{ m: 0, width: '100%' }} size='small'>
          <OutlinedInput
            value={value}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        </FormControl>
      </Box>
    )
  }

  if (tableMeta?.editedRows[row.id] && column.id === "weight") {
    return (
      <Box >
        <FormControl sx={{ m: 0, width: '100%' }} size='small'>
          <OutlinedInput
            value={value}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        </FormControl>
      </Box>
    )
  }



  if (column.id === 'category') {
    return (
      <Box sx={{ px: 1, fontSize: '12px'}}>
        {columnMeta?.options?.filter((option) => option.value === value)[0].label}
      </Box>
    )
  }
  else if (column.id === 'metric') {
    return (
      <Box sx={{ px: 1, fontSize: '12px' }}>
        {columnMeta?.options?.filter((option) => option.value === value)[0].label}
      </Box>
    )
  }
  else if (column.id === 'rank') {
    return (
      <Box sx={{ px: 1, fontSize: '12px' }}>
        {value === 'ASC' ?
          <IconButton sx={{ color: '#ff9100' }} size='small'>
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
          :
          <IconButton sx={{ color: '#2196f3' }} size='small'>
            <ArrowDownwardIcon fontSize="small"/>
          </IconButton>
        }

        {columnMeta?.options?.filter((option) => option.value === value)[0].label}
      </Box>
    )
  }
  else if (column.id === 'minimum') {
    return (
      <Stack sx={{ px: 1, fontSize: '12px' }} direction="row" justifyContent="flex-end">
        {value}
      </Stack>
    )
  }
  else if (column.id === 'maximum') {
    return (
      <Stack sx={{ px: 1, fontSize: '12px' }} direction="row" justifyContent="flex-end">
        {value}
      </Stack>
    )
  }
  else if (column.id === 'weight') {
    return (
      <Stack sx={{ px: 1, fontSize: '12px' }} direction="row" justifyContent="flex-end">
        {value}
      </Stack>
    )
  }
  else if (column.id === 'weight_pct') {
    return (
      <Stack sx={{ px: 1, fontSize: '12px' }} direction="row" justifyContent="flex-end">
        {value}%
      </Stack>
    )
  }


  return <span>{value}</span>;
};
