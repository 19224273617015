import React, { useEffect, useState } from "react";
import QuickStrategiesUser from "./QuickStrategiesUser";
import QuickStrategiesFSA from "./QuickStrategiesFSA";
import axios from "axios";
// import { useAtom } from "jotai";
// import { emailAtom } from "./Atoms";
import jwt from "jwt-decode";

import { Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

const WEBAPI = process.env.REACT_APP_WEB_API;

const QuickStrategies = () => {
  const [FSAExpanded, setFSAExpanded] = React.useState(false);
  const [USERExpanded, setUSERExpanded] = React.useState(false);

  const [fsaData, setFSAData] = useState([]);
  const [userData, setUserData] = useState([]);
  // const [email] = useAtom(emailAtom);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("Appbar");
    if (token) {
      const decodedToken = jwt(token);

      // if (decodedToken.email === "ken@afxsoftware.com" || decodedToken.email === "matt@fsavaluation.com") {
      if (decodedToken.email === "mkacur@fsavaluation.com") {
        setAdmin(true);
      }
    }

    const fsaExpanded = localStorage.getItem("QSTRATEGY_FSAExpanded");
    if (fsaExpanded === null) {
      setFSAExpanded(true);
    } else {
      setFSAExpanded(parseInt(fsaExpanded, 10) === 1);
    }

    const userExpanded = localStorage.getItem("QSTRATEGY_USERExpanded");
    if (userExpanded === null) {
      setUSERExpanded(true);
    } else {
      setUSERExpanded(parseInt(userExpanded, 10) === 1);
    }

    getStrategies();
  }, []);

  const getStrategies = () => {
    axios
      .get(`${WEBAPI}/quickstrategy`)
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${WEBAPI}/quickstrategy/fsa`)
      .then((res) => {
        console.log(res.data);
        setFSAData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFSAExpansionChange = () => {
    const isExpanded = FSAExpanded;
    setFSAExpanded(!isExpanded);
    localStorage.setItem("QSTRATEGY_FSAExpanded", isExpanded ? 0 : 1);
  };

  const handleUSERExpansionChange = () => {
    setUSERExpanded((current) => !current);
    localStorage.setItem("QSTRATEGY_USERExpanded", USERExpanded ? 0 : 1);
  };

  return (
    <Box sx={{ mx: 2, my: 2 }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 20 }}>
          <Accordion expanded={FSAExpanded} onChange={handleFSAExpansionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>FSA Strategies</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <QuickStrategiesFSA data={fsaData} getStrategies={getStrategies} admin={admin} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={USERExpanded} onChange={handleUSERExpansionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography>My Strategies</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <QuickStrategiesUser data={userData} getStrategies={getStrategies} admin={admin} />
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    </Box>
  );
};

export default QuickStrategies;
