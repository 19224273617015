import React, { useEffect } from 'react'
import axios from 'axios';
import { Paper, Typography } from '@mui/material'

const WEBAPI = process.env.REACT_APP_WEB_API;


const DBSectorPerformance = () => {

    // useEffect(() => {
    //     axios.get(`${WEBAPI}/dashboard/qod`)
    //         .then((res) => {
    //             setQuote(res.data.Quote);
    //             setAuthor(res.data.Author);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }, [])

    return (
        <Paper sx={{ p: 2, height: 200 }}>
            <Typography variant="h5" mb={5} >Sector Performance</Typography>
            <Typography variant="body1" display="inline">Coming Soon</Typography>

        </Paper>
    )
}

export default DBSectorPerformance
