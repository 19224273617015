import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from "@mui/material/Checkbox";


const WEBAPI = process.env.REACT_APP_WEB_API;
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const indicatorSize = 80;

const validationSchema = yup.object({
    companyid: yup
        .string('Select Company')
        .required('Company is required'),
    fname: yup
        .string('Enter the First Name')
        .min(1, 'First Name should be of minimum 1 character')
        .required('First Name is required'),
    lname: yup
        .string('Enter the Last Name')
        .min(1, 'Last Name should be of minimum 1 character')
        .required('Last Name is required'),

    email: yup.string("Enter Email").required("Email is required"),
    mobile: yup.string("Enter Mobile Number"),

});


export default function User() {
    const { id } = useParams('')
    const [companies, setCompanies] = useState([])
    const [companyId, setCompanyId] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [active, setActive] = useState(true)

    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    useEffect(() => {
        console.log('id', id)

        axios.get(`${WEBAPI}/company`)
            .then((res) => {
                console.log(res.data)
                setCompanies(res.data)
            })
            .catch((err) => {
                console.log(err);
            })

        if (id !== undefined) {
            axios.get(`${WEBAPI}/user/${id}`)
                .then((res) => {
                    console.log(res.data)
                    setCompanyId(res.data.companyid)
                    setFirstName(res.data.fname)
                    setLastName(res.data.lname)
                    setMobile(res.data.mobile)
                    setEmail(res.data.email)
                    setActive(res.data.active)

                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, []);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyid: companyId,
            fname: firstName,
            lname: lastName,
            email: email,
            mobile: mobile,
            active: active,

        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            console.log('Submitting Form', values)

            if (id === undefined) {
                axios.post(`${WEBAPI}/user`, { companyid: values.companyid, fname: values.fname, lname: values.lname, email: values.email, mobile: values.mobile, active: values.active })
                    .then((res) => {
                        setSubmitting(false)
                        navigate('/users')
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitting(false)
                        setSnackbarMessage('Company, First Name, and Last Name must be unique.');
                        setSnackbarSeverity('error');
                        setOpenSnackbar(true);
                    })
            } else {
                axios.put(`${WEBAPI}/user`, { id: id, companyid: values.companyid, fname: values.fname, lname: values.lname, email: values.email, mobile: values.mobile, active: values.active })
                    .then((res) => {
                        setSubmitting(false)
                        navigate('/users')
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitting(false)
                    })
            }
        },
    });

    return (
        <Container maxWidth="sm" sx={{ mt: 5 }}>
            <Paper >
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
                </Snackbar>

                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} padding={4}>
                        <Grid item xs={12} md={4}>
                            <Typography component="h1" align="left" variant="h5" noWrap>
                                User
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <FormControl
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                size={"small"}
                                required
                                error={formik.touched.companyid && Boolean(formik.errors.companyid)}
                            >
                                <InputLabel>Company</InputLabel>
                                <Select
                                    value={formik.values.companyid}
                                    onChange={formik.handleChange}
                                    id="companyid"
                                    label="Company"
                                    name="companyid"
                                >
                                    {companies?.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText style={{ display: (formik.touched.companyid && Boolean(formik.errors.companyid) ? 'block' : 'none') }} >{formik.touched.companyid && formik.errors.companyid}</FormHelperText>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                value={formik.values.fname}
                                onChange={formik.handleChange}
                                error={formik.touched.fname && Boolean(formik.errors.fname)}
                                helperText={formik.touched.fname && formik.errors.fname}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="fname"
                                label="First Name"
                                name="fname"
                                autoComplete=""
                            // autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                value={formik.values.lname}
                                onChange={formik.handleChange}
                                error={formik.touched.lname && Boolean(formik.errors.lname)}
                                helperText={formik.touched.lname && formik.errors.lname}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="lname"
                                label="Last Name"
                                name="lname"
                                autoComplete=""
                            // autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete=""
                            />
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={formik.touched.mobile && formik.errors.mobile}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="mobile"
                                label="Mobile #"
                                name="mobile"
                                autoComplete=""
                            />
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.active}
                                        onChange={formik.handleChange}
                                        name="active"
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                        </Grid>




                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={formik.isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>



                    </Grid>
                </form>
            </Paper >
        </Container >

    )
}