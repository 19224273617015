import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import jwt from 'jwt-decode'
import FormHelperText from "@mui/material/FormHelperText";
import { useAtom } from 'jotai';
import { emailAtom, firstNameAtom, lastNameAtom, authenticatedAtom, userIDAtom } from '../Atoms';

const WEBAPI = process.env.REACT_APP_WEB_API;


const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters")
    .required("Password is required"),
});

export default function LoginPassword() {
  const navigate = useNavigate();
  const [invalid, setInvalid] = useState(false)

  const [userID, setUserID] = useAtom(userIDAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(authenticatedAtom);


  useEffect(() => {
    if (email === "") {
      navigate("/login");
    }
  })


  const formik = useFormik({
    initialValues: {
      password: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // setFormSubmitting(true)

      console.log(email);
      console.log(values.password);

      axios.post(`${WEBAPI}/auth/login`, { email: email, password: values.password })
        .then((res) => {
          console.log('Response', res)

          if (res.data.status === "INVALID_PWD") {
            setInvalid(true)
          } else {
            console.log('Successfully logged in.', res.data);

            localStorage.setItem("token", res.data.token);
            // setToken(res.data.token)
            const decodedToken = jwt(res.data.token)

            setIsAuthenticated(true)
            setUserID(decodedToken.userid)
            setFirstName(decodedToken.fname)
            setLastName(decodedToken.lname)
            setEmail(decodedToken.email)

            navigate("/");
          }
        })
        .catch((err) => {
          console.log('Error: ', err);
        })

      setSubmitting(false)
    },
  });

  const handlePasswordChange = (e) => {
    setInvalid(false)
    formik.setFieldValue("password", e.target.value, false);
  }

  const forgotPasswordHandler = () => {
    // call api to create a pin and send email with pin
    axios.post(`${WEBAPI}/auth/forgotpassword`, { email: email })
      .then((res) => {
        console.log(res)
        navigate(`/loginpasswordcode/`)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper sx={{ pb: 5 }}>

        <form noValidate onSubmit={formik.handleSubmit} autoComplete='false' >
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} padding={4}>

            <Grid item xs={4} md={4} align="center">
              <Avatar sx={{ backgroundColor: (theme) => theme.palette.secondary.main }} >
                <LockOutlinedIcon />
              </Avatar>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography component="h1" align="left" variant="h5" noWrap>
                Password
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.password}
                onChange={handlePasswordChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
              <FormHelperText style={{ display: (invalid) ? 'block' : 'none', color: 'red' }} >Your password is incorrect.</FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
              >
                Log In
              </Button>

            </Grid>
          </Grid>

        </form>

        <Grid container>
          <Grid item xs sx={{ ml: 4 }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => forgotPasswordHandler()}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>


      </Paper >
    </Container >
  );
}
