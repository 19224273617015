import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableFooter from '@mui/material/TableFooter';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const WEBAPI = process.env.REACT_APP_WEB_API;
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const indicatorSize = 80;

const validationSchema = yup.object({
    name: yup
        .string('Enter the Company name')
        .min(3, 'Company name should be of minimum 3 characters length')
        .required('Company name is required'),
});


export default function Company() {
    const { id } = useParams('')
    const [name, setName] = useState('')
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    useEffect(() => {
        console.log('id', id)

        if (id !== undefined) {
            axios.get(`${WEBAPI}/company/${id}`)
            .then((res) => {
                console.log(res.data)
                setName(res.data.name)
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }, []);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            console.log('Submitting Form', values)

            if (id === undefined) {
                axios.post(`${WEBAPI}/company`, { name: values.name })
                    .then((res) => {
                        setSubmitting(false)
                        navigate('/companies')
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitting(false)
                        setSnackbarMessage('Company name must be unique.');
                        setSnackbarSeverity('error');
                        setOpenSnackbar(true);
                    })
            } else {
                axios.put(`${WEBAPI}/company`, { id: id, name: values.name })
                    .then((res) => {
                        setSubmitting(false)
                        navigate('/companies')
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitting(false)
                    })
            }
        },
    });

    return (
        <Container maxWidth="sm" sx={{ mt: 5 }}>
            <Paper >
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
                </Snackbar>

                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} padding={4}>
                        <Grid item xs={12} md={4}>
                            <Typography component="h1" align="left" variant="h5" noWrap>
                                Company
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Company"
                                name="name"
                                autoComplete=""
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={formik.isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper >
        </Container >

    )
}